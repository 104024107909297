import styled, { css } from 'styled-components';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';
import { Body2Font, CaptionFont } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';
import { TextEllipsis } from '../../../themes/text';

const borderColorStyle = css`
  ${hexToRgbA(THEME.COLORS.PRIMARY, THEME.OPACITY.DIVIDER)};
`;

const activeBorderColorStyle = css`
  ${THEME.COLORS.ACCENT};
`;

export const Container = styled.div<{ isActive?: boolean; disabled?: boolean }>`
  border: 1px solid
    ${({ isActive }) => (isActive ? activeBorderColorStyle : borderColorStyle)};
  padding: 0 ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? THEME.OPACITY.MED : undefined)};
  display: flex;
  min-height: 40px;
  align-items: center;
  width: 100%;
  gap: ${THEME.SPACING.TINY};
  background-color: ${THEME.COLORS.PURE_WHITE};
`;

export const ContentContainer = styled.div`
  width: calc(100% - 26px);
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.MICRO};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  align-items: center;
`;

export const Label = styled.div`
  ${CaptionFont};
`;

export const LabelIconWrapper = styled.div`
  & svg {
    height: 12px;
  }
`;

const EllipsisContainer = css<{ maxWidthPx?: number }>`
  ${TextEllipsis};
`;

export const Input = styled.input`
  ${TextEllipsis};
  ${Body2Font};
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;

  ::placeholder {
    color: ${THEME.COLORS.TRUE_BLACK};
    opacity: ${THEME.OPACITY.LOW};
    line-height: normal;
  }
`;

export const Value = styled.div`
  ${EllipsisContainer};
  ${Body2Font};
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: bold;
`;

export const Placeholder = styled.div`
  ${EllipsisContainer};
  ${Body2Font};
  color: ${THEME.COLORS.TRUE_BLACK};
  opacity: ${THEME.OPACITY.LOW};
  line-height: normal;
`;

export const ArrowIconContainer = styled.div`
  background-color: ${THEME.COLORS.ACCENT};
  border-radius: ${THEME.RADIUS.CIRCLE};
  width: 18px;
  height: 18px;
  margin-left: auto;
  flex-shrink: 0;
`;

export const ArrowIcon = styled(ASSETS.ARROW_DOWN)`
  fill: white;
`;
