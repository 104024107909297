import styled, { keyframes } from 'styled-components';
import { rotate } from '../../../animations';

const animateStroke = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
`;

export const SpinnerSvg = styled.svg<{ $animationDuration: number }>`
  animation: ${rotate} linear infinite;
  animation-duration: ${({ $animationDuration }) => `${$animationDuration}ms`}
`;

export const SpinnerCircle = styled.circle`
  fill: none;
  stroke-width: 8px;
  animation: ${animateStroke} 12s linear infinite;
  stroke-linecap: round;
  stroke: currentColor;
`;
