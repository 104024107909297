import { ApiError } from '../../../../../consts/api';
import { RecruiterAgentInfoResponse } from '../dto/response/recruiter-agent-info.response';
import { recruiterAgentQuery } from '../recruiter-agent.toolkit-api';

export const getRecruiterAgentInfoQueryHook = (positionId: string, skip = false): AppSelectorType<RecruiterAgentInfoResponse, ApiError> => {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    error,
    refetch,
  } = recruiterAgentQuery.useGetRecruiterAgentInfoQuery(
    positionId,
    {
      skip: skip || !positionId,
      pollingInterval: 10000,
      refetchOnFocus: true,
    },
  );

  return {
    data: data || {} as RecruiterAgentInfoResponse,
    error: error as ApiError,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
