import { PropsWithChildren } from 'react';
import { PageHeader } from '../../../../components/Common/headers/page-header/page-header.component';
import { Wrapper } from './onboarding-steps-layout.styled';

export const OnboardingStepsLayout = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Wrapper>
      <PageHeader logoSize="small" isSticky />
      {children}
    </Wrapper>
  );
};
