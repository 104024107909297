import { UserRoleName } from '../enums/user-role-name.enum';

export const STRING_KEYS = {
  ACCEPT: 'ACCEPT',
  ACTIVE: 'ACTIVE',
  ACTIVATED: 'ACTIVATED',
  NOT_ACTIVATED: 'NOT_ACTIVATED',
  SELECT_ALL: 'SELECT_ALL',
  PLEASE_ENTER_INPUT_VALUE: 'PLEASE_ENTER_INPUT_VALUE',
  PLEASE_ENTER_VALID_VALUE: 'PLEASE_ENTER_VALID_VALUE',
  EMAIL: 'EMAIL',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  PASSWORD: 'PASSWORD',
  LOGO_ALT: 'LOGO_ALT',
  CONTINUE: 'CONTINUE',
  CONFIRM: 'CONFIRM',
  ENGAGED: 'ENGAGED',
  RECOMMENDED: 'RECOMMENDED',
  DO: 'DO',
  DONT: 'DONT',
  MATCH_ENGAGEMENT_NOT_ALLOWED_ERROR: 'MATCH_ENGAGEMENT_NOT_ALLOWED_ERROR',
  CANDIDATES_REVIEW: 'CANDIDATES_REVIEW',
  TO_REVIEW: 'TO_REVIEW',
  TO_REACHOUT: 'TO_REACHOUT',
  PRO: 'PRO',
  SCALE: 'SCALE',
  SENT: 'SENT',
  SEEN: 'SEEN',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  SUPPORT: 'SUPPORT',
  MAIN_MENU: {
    ITEMS: {
      PEOPLE: 'MAIN_MENU_ITEMS_PEOPLE',
    },
  },
  LOGOUT: 'LOGOUT',
  UNKNOWN_PLAN: 'UNKNOWN_PLAN',
  COMING_SOON: 'COMING_SOON',
  DECLINED: 'DECLINED',
  FACEBOOK: 'FACEBOOK',
  GITHUB: 'GITHUB',
  LINKEDIN: 'LINKEDIN',
  MEDIUM: 'MEDIUM',
  TWITTER: 'TWITTER',
  SKILLS: 'SKILLS',
  EDUCATION: 'EDUCATION',
  EXPERIENCE: 'EXPERIENCE',
  ENGAGE: 'ENGAGE',
  DECLINE: 'DECLINE',
  ACTION_UNDO: 'ACTION_UNDO',
  REACH_OUT: 'REACH_OUT',
  CLOSE: 'CLOSE',
  ACTION: 'ACTION',
  LATER: 'LATER',
  SKIP: 'SKIP',
  COPIED_SUCCESSFULLY: 'COPIED_SUCCESSFULLY',
  COPIED: 'COPIED',
  COPY: 'COPY',
  PRESENT: 'PRESENT',
  UNKNOWN: 'UNKNOWN',
  GO_BACK_TO_HOMEPAGE: 'GO_BACK_TO_HOMEPAGE',
  GO_BACK_TO_AUTH: 'GO_BACK_TO_AUTH',
  BACK_TO_LOGIN: 'BACK_TO_LOGIN',
  BACK_TO_LOGIN_DESCRIPTION: 'BACK_TO_LOGIN_DESCRIPTION',
  CANT_FIND_PAGE: 'CANT_FIND_PAGE',
  PAGE_MOVED_RENAMED_DELETED: 'PAGE_MOVED_RENAMED_DELETED',
  NOVICE: 'NOVICE',
  BEGINNER: 'BEGINNER',
  COMPETENT: 'COMPETENT',
  PROFICIENT: 'PROFICIENT',
  PROMOTION: 'PROMOTION',
  EXPERT: 'EXPERT',
  TODAY: 'TODAY',
  TOMORROW: 'TOMORROW',
  RESET_MY_PASSWORD: 'RESET_MY_PASSWORD',
  ACTION_FAILED: 'ACTION_FAILED',
  WHOOPS: 'WHOOPS',
  OOPSIE: 'OOPSIE',
  INVALID_LINK: 'INVALID_LINK',
  OTHER: 'OTHER',
  SEE_LESS: 'SEE_LESS',
  SEE_MORE: 'SEE_MORE',
  SEE_X_MORE: 'SEE_X_MORE',
  AND_X_MORE: 'AND_X_MORE',
  // UNTIL HERE EVERYTHING IN USE
  MOBILE_MESSAGE_NOT_US: 'MOBILE_MESSAGE_NOT_US',
  MOBILE_MESSAGE_NOT_SUPPORTED: 'MOBILE_MESSAGE_NOT_SUPPORTED',
  MOBILE_MESSAGE_SUGGESTION: 'MOBILE_MESSAGE_SUGGESTION',
  NOT_INTERESTED: 'NOT_INTERESTED',
  SOCIAL_PROFILES: 'SOCIAL_PROFILES',
  LIKELIHOOD: 'LIKELIHOOD',
  SOMEWHAT_LIKELY: 'SOMEWHAT_LIKELY',
  VERY_LIKELY: 'VERY_LIKELY',
  LIKELY_TO_MOVE: 'LIKELY_TO_MOVE',
  UNLIKELY: 'UNLIKELY',
  YEARS_EXPERIENCE_TITLE: 'YEARS_EXPERIENCE_TITLE',
  YEARS_EXPERIENCE_SUBTITLE: 'YEARS_EXPERIENCE_SUBTITLE',
  START_ENGAGEMENT: 'START_ENGAGEMENT',
  ENGAGE_DIALOG_SUBTITLE: 'ENGAGE_DIALOG_SUBTITLE',
  ENGAGE_DIALOG_TITLE: 'ENGAGE_DIALOG_TITLE',
  NEW_MATCH_TALENT_BIO_SENTENCE_1_1:
  'NEW_MATCH_TALENT_BIO_SENTENCE_1_1',
  NEW_MATCH_TALENT_BIO_SENTENCE_2_1:
  'NEW_MATCH_TALENT_BIO_SENTENCE_2_1',
  NEW_MATCH_TALENT_BIO_SENTENCE_2_2:
  'NEW_MATCH_TALENT_BIO_SENTENCE_2_2',
  NEW_MATCH_TALENT_BIO_SENTENCE_2_3:
  'NEW_MATCH_TALENT_BIO_SENTENCE_2_3',
  NEW_MATCH_TALENT_BIO_SENTENCE_2_4:
  'NEW_MATCH_TALENT_BIO_SENTENCE_2_4',
  X_YEARS: 'X_YEARS',
  X_YEARS_Y_MONTHS: 'X_YEARS_Y_MONTHS',
  ONE_YEAR: 'ONE_YEAR',
  X_MONTHS: 'X_MONTHS',
  ONE_MONTH: 'ONE_MONTH',
  YEARS: 'YEARS',
  REFERENCE: 'REFERENCE',
  WORKED_WITH: 'WORKED_WITH',
  LET_YOU_KNOW: 'LET_YOU_KNOW',
  WITHIN: 'WITHIN',
  IN: 'IN',
  PEOPLE: 'PEOPLE',
  AND_MORE: 'AND_MORE',
  AT: 'AT',
  AT_COMPANY: 'AT_COMPANY',
  COULD_NOT_REACH_TALENT: 'COULD_NOT_REACH_TALENT',
  ITS_YOUR_TURN: 'ITS_YOUR_TURN',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  TALENT_NETWORK_BADGE_HIGH_SCORE_DESCRIPTION: 'TALENT_NETWORK_BADGE_HIGH_SCORE_DESCRIPTION',
  TALENT_NETWORK_BADGE_MED_LOW_SCORE_DESCRIPTION: 'TALENT_NETWORK_BADGE_MED_LOW_SCORE_DESCRIPTION',
  BILINGUAL: 'BILINGUAL',
  MULTILINGUAL: 'MULTILINGUAL',
  TRILINGUAL: 'TRILINGUAL',
  BILINGUAL_DESCRIPTION: 'BILINGUAL_DESCRIPTION',
  TRILINGUAL_DESCRIPTION: 'TRILINGUAL_DESCRIPTION',
  MULTILINGUAL_DESCRIPTION: 'MULTILINGUAL_DESCRIPTION',
  EXPERIENCE_BADGE_TITLE: 'EXPERIENCE_BADGE_TITLE',
  WORKED_AT: 'WORKED_AT',
  WORKED_AT_MULTI: 'WORKED_AT_MULTI',
  CAREER_UPGRADE: 'CAREER_UPGRADE',
  CAREER_UPGRADE_DESCRIPTION: 'CAREER_UPGRADE_DESCRIPTION',
  ACADEMIC_EXCELLENCE: 'ACADEMIC_EXCELLENCE',
  ACADEMIC_EXCELLENCE_DESCRIPTION: 'ACADEMIC_EXCELLENCE_DESCRIPTION',
  ACTIVELY_LOOKING: 'ACTIVELY_LOOKING',
  ACTIVELY_LOOKING_DESCRIPTION: 'ACTIVELY_LOOKING_DESCRIPTION',
  ACTIVELY_LOOKING_LAYOFF_DESCRIPTION: 'ACTIVELY_LOOKING_LAYOFF_DESCRIPTION',
  CRUNCHBASE_RANKING: 'CRUNCHBASE_RANKING',
  CRUNCHBASE_RANKING_DESCRIPTION: 'CRUNCHBASE_RANKING_DESCRIPTION',
  SELECT_FROM_LIST: 'SELECT_FROM_LIST',
  A: 'A',
  AN: 'AN',
  TENURE_PERIOD: 'TENURE_PERIOD',
  REACHED_AVERAGE: 'REACHED_AVERAGE',
  CURRENT_JOB_EXPERIENCE_YEARS: 'CURRENT_JOB_EXPERIENCE_YEARS',
  CURRENT_JOB_EXPERIENCE_MONTHS: 'CURRENT_JOB_EXPERIENCE_MONTHS',
  CURRENT_JOB_EXPERIENCE_YEAR: 'CURRENT_JOB_EXPERIENCE_YEAR',
  CURRENT_JOB_EXPERIENCE_MONTH: 'CURRENT_JOB_EXPERIENCE_MONTH',
  TRIAL_END_BANNER: {
    TITLE: 'TRIAL_ENDED_BANNER_TITLE',
    DESCRIPTION: 'TRIAL_ENDED_BANNER_DESCRIPTION',
    BUTTON: 'TRIAL_ENDED_BANNER_BUTTON',
    ABOUT_TO_END: {
      TITLE: 'TRIAL_ABOUT_TO_END_BANNER_TITLE',
      DESCRIPTION: 'TRIAL_ABOUT_TO_END_BANNER_DESCRIPTION',
    },
    KEEP_USING_TOGGLED: 'TRIAL_ENDED_BANNER_KEEP_USING_TOGGLED',
    SCHEDULE_MEETING: 'TRIAL_ENDED_BANNER_SCHEDULE_MEETING',
  },
  CURRENT_PRODUCT_PLAN: 'CURRENT_PRODUCT_PLAN',
  DAYS_LEFT_ONE: 'DAYS_LEFT_ONE',
  DAYS_LEFT_PLURAL: 'DAYS_LEFT_PLURAL',
  PAID: 'PRODUCT_PLAN_PAID',
  GET_CONTACT_INFO: 'GET_CONTACT_INFO',
  REQUEST_ADDITIONAL_INFO: 'REQUEST_ADDITIONAL_INFO',
  REQUEST_CONTACT_INFO: 'REQUEST_CONTACT_INFO',
  NOTIFY_YOU: 'NOTIFY_YOU',
  WE_CANT_FIND_CONTACT_INFO: 'WE_CANT_FIND_CONTACT_INFO',
  SEARCH_CONTACT_INFO_FOR_YOU: 'SEARCH_CONTACT_INFO_FOR_YOU',
  GOT_REQUEST: 'GOT_REQUEST',
  ENGAGING_WITH: 'ENGAGING_WITH',
  CURRENTLY_ENGAGING: 'CURRENTLY_ENGAGING',
  TALENT_IS_NOT_INTERESTED: 'TALENT_IS_NOT_INTERESTED',
  TAP_FOR_DETAILS: 'TAP_FOR_DETAILS',
  TAP_FOR_DETAILS_AND_CONTACT_INFO: 'TAP_FOR_DETAILS_AND_CONTACT_INFO',
  TALENT_IS_WAITING_TO_HEAR_FROM_YOU: 'TALENT_IS_WAITING_TO_HEAR_FROM_YOU',
  ENGAGEMENT_REQUEST_BY: 'ENGAGEMENT_REQUEST_BY',
  MATCH_ENGAGEMENT_STATUS_NOT_INTERESTED: {
    NOT_LOOKING_FOR_NEW_OPPORTUNITIES: 'NOT_LOOKING_FOR_NEW_OPPORTUNITIES',
    OPEN_TO_OTHER_OPPORTUNITIES: 'OPEN_TO_OTHER_OPPORTUNITIES',
    OTHER_REASON: 'OTHER_REASON',
  },
  REQUESTED_ADDITIONAL_DETAILS: 'REQUESTED_ADDITIONAL_DETAILS',
  ENGAGEMENT_IN_PROGRESS: 'ENGAGEMENT_IN_PROGRESS',
  UPDATE_YOU_SOON: 'UPDATE_YOU_SOON',
  DECLINED_DESCRIPTION: 'DECLINED_DESCRIPTION',
  ENGAGED_DESCRIPTION: 'ENGAGED_DESCRIPTION',
  DEGREES: {
    ['MASTER' as string]: 'DEGREES_MASTER',
    ['BACHELOR' as string]: 'DEGREES_BACHELOR',
    ['PHD' as string]: 'DEGREES_PHD',
    ['ASSOCIATE' as string]: 'DEGREES_ASSOCIATE',
  },
  ONBOARDING: {
    PERSONAL_EMAIL_ERROR: 'PERSONAL_EMAIL_ERROR',
    SELECT_FROM_THE_LIST: 'SELECT_FROM_THE_LIST',
    JOB_REQUIREMENTS_SELECTION_ERROR: 'JOB_REQUIREMENTS_SELECTION_ERROR',
    JOB_TITLE_PLACEHOLDER: 'JOB_TITLE_PLACEHOLDER',
    ALREADY_HAVE_AN_ACCOUNT: 'ALREADY_HAVE_AN_ACCOUNT',
    LOGIN: 'LOGIN',
    USER_EXIST: 'USER_EXIST',
    LINKEDIN_WRONG_FORMAT: 'LINKEDIN_WRONG_FORMAT',
    TRANSITION_WELCOME: 'TRANSITION_WELCOME',
    WELCOME_SCREEN_STARTING_TODAY: 'WELCOME_SCREEN_STARTING_TODAY',
    WELCOME_SCREEN_HELLO: 'WELCOME_SCREEN_HELLO',
    COMPANY_SELECTION_ERROR: 'COMPANY_SELECTION_ERROR',
    LOCATION_SELECTION_ERROR: 'LOCATION_SELECTION_ERROR',
    DONE_SCREEN_MATCHING_PROGRESS: 'DONE_SCREEN_MATCHING_PROGRESS',
    ONBOARDING_FILE_TYPE_ERROR: 'ONBOARDING_FILE_TYPE_ERROR',
    ONBOARDING_FILE_TOO_LARGE_ERROR: 'ONBOARDING_FILE_TOO_LARGE_ERROR',
    ONBOARDING_JOBTITLE_SENTENCE: 'ONBOARDING_JOBTITLE_SENTENCE',
    ONBOARDING_I_AM: 'ONBOARDING_I_AM',
    ONBOARDING_MY_NAME_IS: 'ONBOARDING_MY_NAME_IS',
    ONBOARDING_LOCATION_PLACEHOLDER: 'ONBOARDING_LOCATION_PLACEHOLDER',
    ONBOARDING_EXPERIENCE_TOTAL_YEARS_ENTRY_LEVEL: 'ONBOARDING_EXPERIENCE_TOTAL_YEARS_ENTRY_LEVEL',
    ONBOARDING_EXPERIENCE_TOTAL_YEARS: 'ONBOARDING_EXPERIENCE_TOTAL_YEARS',
    EXPERIENCE_PAGE: {
      TITLE: 'ONBOARDING_EXPERIENCE_PAGE_TITLE',
      TOOLTIP: 'ONBOARDING_EXPERIENCE_PAGE_TITLE_TOOLTIP',
      RELEVANT_LABEL: 'ONBOARDING_EXPERIENCE_PAGE_RELEVANT_LABEL',
      OVERALL_LABEL: 'ONBOARDING_EXPERIENCE_PAGE_OVERALL_LABEL',
    },
    ONBOARDING_EDUCATION_PAGE_TITLE: 'ONBOARDING_EDUCATION_PAGE_TITLE',
    ONBOARDING_UPLOAD_JOB_DESCRIPTION_DRAG_DROP: 'ONBOARDING_UPLOAD_JOB_DESCRIPTION_DRAG_DROP',
    ONBOARDING_UPLOAD_JOB_DESCRIPTION_PDF: 'ONBOARDING_UPLOAD_JOB_DESCRIPTION_PDF',
    ONBOARDING_SETUP_PROFILE_TITLE: 'ONBOARDING_SETUP_PROFILE_TITLE',
    ONBOARDING_SETUP_PROFILE_SUBTITLE: 'ONBOARDING_SETUP_PROFILE_SUBTITLE',
    ONBOARDING_VERIFICATION_TITLE: 'ONBOARDING_VERIFICATION_TITLE',
    ONBOARDING_VERIFICATION_DESCRIPTION: 'ONBOARDING_VERIFICATION_DESCRIPTION',
    ONBOARDING_VERIFICATION_CODE_EMAILED: 'ONBOARDING_VERIFICATION_CODE_EMAILED',
    ONBOARDING_VERIFICATION_CODE_VERIFICATION_FAILED:
    'ONBOARDING_VERIFICATION_CODE_VERIFICATION_FAILED',
    ONBOARDING_VERIFICATION_CODE_NOT_RECEIVED: 'ONBOARDING_VERIFICATION_CODE_NOT_RECEIVED',
    ONBOARDING_VERIFICATION_CODE_SENT_AGAIN: 'ONBOARDING_VERIFICATION_CODE_SENT_AGAIN',
    ONBOARDING_VERIFICATION_CODE_NO_LUCK: 'ONBOARDING_VERIFICATION_CODE_NO_LUCK',
    ONBOARDING_VERIFICATION_CODE_ASK_HUMAN_HELP: 'ONBOARDING_VERIFICATION_CODE_ASK_HUMAN_HELP',
    ONBOARDING_EMAIL_VERIFICATION_TITLE: 'ONBOARDING_EMAIL_VERIFICATION_TITLE',
    ONBOARDING_EMAIL_VERIFICATION_SUBTITLE: 'ONBOARDING_EMAIL_VERIFICATION_SUBTITLE',
    ONBOARDING_EMAIL_VERIFICATION_DESCRIPTION: 'ONBOARDING_EMAIL_VERIFICATION_DESCRIPTION',
    ONBOARDING_EMAIL_VERIFICATION_ENTER_CODE: 'ONBOARDING_EMAIL_VERIFICATION_ENTER_CODE',
    ONBOARDING_CONTINUE_BUTTON: 'ONBOARDING_CONTINUE_BUTTON',
    ONBOARDING_UPLOAD_JOB_PUT_IN_CLOUD:
    'ONBOARDING_UPLOAD_JOB_PUT_IN_CLOUD',
    ONBOARDING_REMOVE_FILE: 'ONBOARDING_REMOVE_FILE',
    ONBOARDING_SIGN_UP_TITLE: 'ONBOARDING_SIGN_UP_TITLE',
    ONBOARDING_SIGN_UP_SUBTITLE: 'ONBOARDING_SIGN_UP_SUBTITLE',
    ONBOARDING_SIGN_UP_CONTINUE_WITH_GOOGLE: 'ONBOARDING_SIGN_UP_CONTINUE_WITH_GOOGLE',
    ONBOARDING_SIGN_UP_CONTINUE_WITH_MICROSOFT: 'ONBOARDING_SIGN_UP_CONTINUE_WITH_MICROSOFT',
    ONBOARDING_SIGN_UP_DESCRIPTION: 'ONBOARDING_SIGN_UP_DESCRIPTION',
    ONBOARDING_SIGN_UP_WORK_EMAIL_PLACEHOLDER: 'ONBOARDING_SIGN_UP_WORK_EMAIL_PLACEHOLDER',
    ONBOARDING_SIGN_UP_LETS_GO_BUTTON: 'ONBOARDING_SIGN_UP_LETS_GO_BUTTON',
    ONBOARDING_SIGN_UP_TERMS_OF_USE: 'ONBOARDING_SIGN_UP_TERMS_OF_USE',
    ONBOARDING_SIGN_UP_CHOOSE_YOUR_PASSWORD: 'ONBOARDING_SIGN_UP_CHOOSE_YOUR_PASSWORD',
    ONBOARDING_SIGN_UP_TERMS_OF_USE_TITLE: 'ONBOARDING_SIGN_UP_TERMS_OF_USE_TITLE',
    ONBOARDING_SIGN_UP_TERMS_OF_USE_SUBTITLE: 'ONBOARDING_SIGN_UP_TERMS_OF_USE_SUBTITLE',
    ONBOARDING_SIGN_UP_ENTER_WORK_EMAIL: 'ONBOARDING_SIGN_UP_ENTER_WORK_EMAIL',
    ONBOARDING_SIGN_UP_ENTER_VALID_EMAIL: 'ONBOARDING_SIGN_UP_ENTER_VALID_EMAIL',
    ONBOARDING_SIGN_UP_ENTER_VALID_PASSWORD: 'ONBOARDING_SIGN_UP_ENTER_VALID_PASSWORD',
    CONTAINER_POSITION_INFO: 'ONBOARDING_CONTAINER_POSITION_INFO',
    MULTI_SENTENCE_INPUT_AUTOCOMPLETE_SKIP_MESSAGE: 'ONBOARDING_MULTI_SENTENCE_INPUT_AUTOCOMPLETE_SKIP_MESSAGE',
    EDUCATION_STEP: {
      DEGREE_TITLE: 'ONBOARDING_EDUCATION_STEP_DEGREE_TITLE',
      FIELDS_OF_STUDY_TITLE: 'ONBOARDING_EDUCATION_STEP_FIELDS_OF_STUDY_TITLE',
      FIELDS_OF_STUDY_INPUT_PLACEHOLDER: 'ONBOARDING_EDUCATION_STEP_FIELDS_OF_STUDY_INPUT_PLACEHOLDER',
    },
    BACKGROUND_STEP: {
      TITLE: 'ONBOARDING_BACKGROUND_STEP_TITLE',
      SENTENCE_INTRO: 'ONBOARDING_BACKGROUND_STEP_SENTENCE_INTRO',
      COMPANY_BACKGROUND_SECTION: {
        INPUT_PLACEHOLDER: 'ONBOARDING_BACKGROUND_STEP_COMPANY_BACKGROUND_SECTION_INPUT_PLACEHOLDER',
        SECONDARY_INPUT_PLACEHOLDER: 'ONBOARDING_BACKGROUND_STEP_COMPANY_BACKGROUND_SECTION_SECONDARY_INPUT_PLACEHOLDER',
        SENTENCE_TEXT: 'ONBOARDING_BACKGROUND_STEP_COMPANY_BACKGROUND_SECTION_SENTENCE_TEXT',
        BLANK_INPUT_TEXT: 'ONBOARDING_BACKGROUND_STEP_COMPANY_BACKGROUND_SECTION_BLANK_INPUT_TEXT',
      },
      SKILLS_SECTION: {
        INPUT_PLACEHOLDER: 'ONBOARDING_BACKGROUND_STEP_SKILLS_SECTION_INPUT_PLACEHOLDER',
        SECONDARY_INPUT_PLACEHOLDER: 'ONBOARDING_BACKGROUND_STEP_SKILLS_SECTION_SECONDARY_INPUT_PLACEHOLDER',
        SENTENCE_TEXT: 'ONBOARDING_BACKGROUND_STEP_SKILLS_SECTION_SENTENCE_TEXT',
        BLANK_INPUT_TEXT: 'ONBOARDING_BACKGROUND_STEP_SKILLS_SECTION_BLANK_INPUT_TEXT',
      },
    },
    PROGRESS_BAR_STEPS: {
      BASIC_INFO: 'ONBOARDING_PROGRESS_BAR_STEPS_BASIC_INFO',
      EXPERIENCE: 'ONBOARDING_PROGRESS_BAR_STEPS_EXPERIENCE',
      BACKGROUND: 'ONBOARDING_PROGRESS_BAR_STEPS_BACKGROUND',
      EDUCATION: 'ONBOARDING_PROGRESS_BAR_STEPS_EDUCATION',
      JOB_DESCRIPTION: 'ONBOARDING_PROGRESS_BAR_JOB_DESCRIPTION',
    },
    ONBOARDING_CUSTOM_EDUCATION_PLACEHOLDER: 'ONBOARDING_CUSTOM_EDUCATION_PLACEHOLDER',
  },
  OR: 'OR',
  EXPORT_MATCHES: {
    ACTION: 'EXPORT_MATCHES_ACTION',
    SUCCESS_MESSAGE: 'EXPORT_MATCHES_SUCCESS_MESSAGE',
    SIDE_PANEL_BUTTON: 'EXPORT_MATCHES_SIDE_PANEL_BUTTON',
  },
  CLOSE_POSITION: {
    ACTION: 'CLOSE_POSITION_ACTION',
    TITLE: 'CLOSE_POSITION_TITLE',
    POSITION_CANCELED: 'CLOSE_POSITION_CANCELED',
    POSITION_ALREADY_FULFILLED: 'CLOSE_POSITION_ALREADY_FULFILLED',
    OTHER: 'CLOSE_POSITION_OTHER',
    BUTTON: 'CLOSE_POSITION_BUTTON',
    SOMETHING_ELSE_PLACEHOLDER: 'CLOSE_POSITION_SOMETHING_ELSE_PLACEHOLDER',
  },
  PASSWORD_LENGTH_VALIDATION_ERROR: 'PASSWORD_LENGTH_VALIDATION_ERROR',
  AT_LEAST_EIGHT_CHARACTERS: 'AT_LEAST_EIGHT_CHARACTERS',
  AT_LEAST_ONE_UPPER_OR_LOWER_LETTER: 'AT_LEAST_ONE_UPPER_OR_LOWER_LETTER',
  AT_LEAST_ONE_UPPER_AND_LOWER_LETTER: 'AT_LEAST_ONE_UPPER_AND_LOWER_LETTER',
  CONTAINS_NUMBER_OR_LETTER: 'CONTAINS_NUMBER_OR_LETTER',
  PERFECT_PASSWORD: 'PERFECT_PASSWORD',
  PASSWORD_SHOULD: 'PASSWORD_SHOULD',
  REQUEST_FAILED_TRY_AGAIN: 'REQUEST_FAILED_TRY_AGAIN',
  LOADING: 'LOADING',
  ALERT: 'ALERT',
  EXIT: 'EXIT',
  TRY_AGAIN: 'TRY_AGAIN',
  GLOBAL_ERROR_MESSAGE: 'GLOBAL_ERROR_MESSAGE',
  INVITE_YOUR_TEAMMATES: {
    INVITE_A_TEAMMATE_TITLE: 'INVITE_A_TEAMMATE_TITLE',
    INVITE_A_TEAMMATE_SUBTITLE: 'INVITE_A_TEAMMATE_SUBTITLE',
    INVITE_A_TEAMMATE_DESCRIPTION: 'INVITE_A_TEAMMATE_DESCRIPTION',
    INVITE_YOUR_TEAMMATES: 'INVITE_YOUR_TEAMMATES',
    MODAL_DESCRIPTION: 'INVITE_YOUR_TEAMMATES_MODAL_DESCRIPTION',
    INVITE_BUTTON: 'INVITE_YOUR_TEAMMATES_INVITE_BUTTON',
    PLACEHOLDER: 'INVITE_YOUR_TEAMMATES_PLACEHOLDER',
    EMAIL_NOT_VALID: 'INVITE_YOUR_TEAMMATES_EMAIL_NOT_VALID',
    EMAIL_EXISTS: 'INVITE_YOUR_TEAMMATES_EMAIL_EXISTS',
    MY_TEAMMATES_TITLE: 'INVITE_YOUR_TEAMMATES_MY_TEAMMATES_TITLE',
    INVITE_PENDING: 'INVITE_YOUR_TEAMMATES_INVITE_PENDING',
    SIGN_UP_TITLE: 'INVITE_YOUR_TEAMMATES_SIGN_UP_TITLE',
    SIGN_UP_SUBTITLE: 'INVITE_YOUR_TEAMMATES_SIGN_UP_SUBTITLE',
    CURRENT_ROLE: 'INVITE_YOUR_TEAMMATES_CURRENT_ROLE',
    START_USING_PERFECT: 'INVITE_YOUR_TEAMMATES_START_USING_PERFECT',
    NAME_ERROR: 'INVITE_YOUR_TEAMMATES_FIRST_NAME_ERROR',
    ACCOUNT_NAME_ERROR: 'INVITE_YOUR_TEAMMATES_ACCOUNT_NAME_ERROR',
    ROLE_NAME: {
      [UserRoleName.Admin]: 'INVITE_YOUR_TEAMMATES_ROLE_NAME_ADMIN',
      [UserRoleName.Creator]: 'INVITE_YOUR_TEAMMATES_ROLE_NAME_CREATOR',
      [UserRoleName.Collaborator]: 'INVITE_YOUR_TEAMMATES_ROLE_NAME_COLLABORATOR',
    },
    ROLES_MENU: {
      HEADER: {
        TITLE: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_HEADER_TITLE',
        DESCRIPTION: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_HEADER_DESCRIPTION',
      },
      ADMIN: {
        TITLE: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_ADMIN_TITLE',
        DESCRIPTION: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_ADMIN_DESCRIPTION',
      },
      CREATOR: {
        TITLE: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_CREATOR_TITLE',
        DESCRIPTION: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_CREATOR_DESCRIPTION',
      },
      COLLABORATOR: {
        TITLE: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_COLLABORATOR_TITLE',
        DESCRIPTION: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_COLLABORATOR_DESCRIPTION',
      },
    },
    ACTIONS_MENU: {
      REMOVE: {
        TITLE: 'INVITE_YOUR_TEAMMATES_ACTIONS_MENU_REMOVE_TITLE',
      },
      RESEND: {
        TITLE: 'INVITE_YOUR_TEAMMATES_ACTIONS_MENU_RESEND_TITLE',
      },
    },
    ROLES_MENU_BUTTON: {
      PLACEHOLDER: {
        ACTIVE: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_BUTTON_PLACEHOLDER_ACTIVE',
        REGULAR: 'INVITE_YOUR_TEAMMATES_ROLES_MENU_BUTTON_PLACEHOLDER_REGULAR',
      },
    },
  },

  WAITING_PAGE_TITLE: 'WAITING_PAGE_TITLE',
  WAITING_PAGE_DESCRIPTIONS: 'WAITING_PAGE_DESCRIPTIONS',
  WAITING_PAGE_SUBTITLE: 'WAITING_PAGE_SUBTITLE',
  ATS_INITIALIZATION_DIALOG: {
    TITLE: 'ATS_INITIALIZATION_DIALOG_TITLE',
    DESCRIPTION: 'ATS_INITIALIZATION_DIALOG_DESCRIPTION',
    CHOOSE_ATS: 'ATS_INITIALIZATION_DIALOG_CHOOSE_ATS',
    CONTINUE_WITH: 'ATS_INITIALIZATION_DIALOG_CONTINUE_WITH',
    TWO_WAY_ATS_INFO_TEXT: 'ATS_INITIALIZATION_DIALOG_TWO_WAY_ATS_INFO_TEXT',
    ENTER_API_KEY_SCREEN: {
      TITLE: 'ATS_INITIALIZATION_DIALOG_ENTER_API_KEY_SCREEN_TITLE',
      DESCRIPTION: 'ATS_INITIALIZATION_DIALOG_ENTER_API_KEY_SCREEN_DESCRIPTION',
      INPUT_TITLE: 'ATS_INITIALIZATION_DIALOG_ENTER_API_KEY_SCREEN_INPUT_TITLE',
      INPUT_PLACEHOLDER: 'ATS_INITIALIZATION_DIALOG_ENTER_API_KEY_SCREEN_INPUT_PLACEHOLDER',
      INVALID_API_KEY_ERROR_MESSAGE: 'ATS_INITIALIZATION_DIALOG_ENTER_API_KEY_SCREEN_INVALID_API_KEY_ERROR_MESSAGE',
    },
    CUSTOM_INTEGRATION_NAMES: {
      ['COMEET' as string]: 'ATS_INITIALIZATION_DIALOG_CUSTOM_INTEGRATION_NAMES_COMEET',
      ['GREENHOUSE' as string]: 'ATS_INITIALIZATION_DIALOG_CUSTOM_INTEGRATION_NAMES_GREENHOUSE',
      ['ASHBY' as string]: 'ATS_INITIALIZATION_DIALOG_CUSTOM_INTEGRATION_NAMES_ASHBY',
    },
  },
  YOUR_ATS: 'YOUR_ATS',
  ADDING_TALENT_TO_ATS: 'ADDING_TALENT_TO_ATS',
  ATS_AUTO_SYNC_TITLE: 'ATS_AUTO_SYNC_TITLE',
  ATS_AUTO_INFO_TEXT: 'ATS_AUTO_INFO_TEXT',
  ATS_ADD_TALENT_BUTTON_TEXT: 'ATS_ADD_TALENT_BUTTON_TEXT',
  ATS_TALENT_ADDED: 'ATS_TALENT_ADDED',
  ATS_TALENT_SYNCED_BY: 'ATS_TALENT_SYNCED_BY',
  ATS_ADD_TALENT_ERROR_MESSAGE: 'ATS_ADD_TALENT_ERROR_MESSAGE',
  ATS_LINK_POSITION_ERROR_MESSAGE: 'ATS_LINK_POSITION_ERROR_MESSAGE',
  ATS_LINK_POSITION_JOB_AUTOCOMPLETE_PLACEHOLDER: 'ATS_LINK_POSITION_JOB_AUTOCOMPLETE_PLACEHOLDER',
  ATS_LINK_POSITION_HELP_TEXT: 'ATS_LINK_POSITION_HELP_TEXT',
  ATS_LINK_POSITION_MENU_ITEM: 'ATS_LINK_POSITION_MENU_ITEM',
  ATS_MANAGE_POSITION_LINK_MENU_ITEM: 'ATS_MANAGE_POSITION_LINK_MENU_ITEM',
  ATS_LINK_POSITION_MODAL_TITLE: 'ATS_LINK_POSITION_MODAL_TITLE',
  ATS_LINK_POSITION_MODAL_DESCRIPTION: 'ATS_LINK_POSITION_MODAL_DESCRIPTION',
  OPEN_MATCH_IN_ATS_BUTTON_TEXT: 'OPEN_MATCH_IN_ATS_BUTTON_TEXT',
  SAVE_AND_SYNC: 'SAVE_AND_SYNC',
  SAVE: 'SAVE',
  ACTIVATE: 'ACTIVATE',
  PRO_FEATURE_TITLE: 'PRO_FEATURE_TITLE',
  PRO_FEATURE_NOTE_1_TITLE: 'PRO_FEATURE_NOTE_1_TITLE',
  PRO_FEATURE_NOTE_1_DESCRIPTION: 'PRO_FEATURE_NOTE_1_DESCRIPTION',
  PRO_FEATURE_NOTE_2_TITLE: 'PRO_FEATURE_NOTE_2_TITLE',
  PRO_FEATURE_NOTE_2_DESCRIPTION: 'PRO_FEATURE_NOTE_2_DESCRIPTION',
  PRO_FEATURE_NOTE_3_TITLE: 'PRO_FEATURE_NOTE_3_TITLE',
  PRO_FEATURE_NOTE_3_DESCRIPTION: 'PRO_FEATURE_NOTE_3_DESCRIPTION',
  START_ENGAGING_UPGRADE: 'START_ENGAGING_UPGRADE',
  UPGRADE: 'UPGRADE',
  LEARN_MORE: 'LEARN_MORE',
  START_ENGAGEMENT_SUB_DESCRIPTION: 'START_ENGAGEMENT_SUB_DESCRIPTION',
  REMOTE: 'REMOTE',
  SPECIFIC_COUNTRY_REMOTE: 'SPECIFIC_COUNTRY_REMOTE',
  US_UK_COUNTRY_REMOTE: 'US_UK_COUNTRY_REMOTE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  ACCOUNT_ACTIVATION: {
    TITLE: 'ACCOUNT_ACTIVATION_TITLE',
    SUBTITLE: 'ACCOUNT_ACTIVATION_SUBTITLE',
  },
  CONTINUE_WITH_GOOGLE_ACCOUNT: 'CONTINUE_WITH_GOOGLE_ACCOUNT',
  CONTINUE_WITH_GOOGLE: 'CONTINUE_WITH_GOOGLE',
  CONTINUE_WITH_MICROSOFT: 'CONTINUE_WITH_MICROSOFT',
  START_EXPERIENCE_WITH_EMAIL: 'START_EXPERIENCE_WITH_EMAIL',
  CHOOSE_YOUR_PASSWORD: 'CHOOSE_YOUR_PASSWORD',
  CONFIRM_YOUR_PASSWORD: 'CONFIRM_YOUR_PASSWORD',
  SIGN_UP_TERMS_OF_USE: 'SIGN_UP_TERMS_OF_USE',
  SIGN_UP_TERMS_OF_USE_TITLE: 'SIGN_UP_TERMS_OF_USE_TITLE',
  SIGN_UP_TERMS_OF_USE_SUBTITLE: 'SIGN_UP_TERMS_OF_USE_SUBTITLE',
  RECRUITER_TITLE: 'RECRUITER_TITLE',
  MANAGER_TITLE: 'MANAGER_TITLE',
  PRICE_PLAN: {
    ['SCALE' as string]: 'PLAN_SCALE',
    ['PRO' as string]: 'PLAN_PRO',
    ['BASIC' as string]: 'PLAN_BASIC',
    ['TRIAL' as string]: 'PLAN_TRIAL',
    ['LITE' as string]: 'PLAN_LITE',
  },
  STATUS_TENURE_COMPARISON: {
    ['APPROACHING_TENURE' as string]: 'APPROACH',
    ['EXCEEDING_TENURE' as string]: 'EXCEEDED',
  },
  STATUS_TENURE_COMPARISON_TITLE_APPROACHING: 'STATUS_TENURE_COMPARISON_TITLE_APPROACHING',
  STATUS_TENURE_COMPARISON_TITLE_EXCEEDING: 'STATUS_TENURE_COMPARISON_TITLE_EXCEEDING',
  PERSONAL_TENURE: 'PERSONAL_TENURE',
  TIME_AT_COMPANY: 'TIME_AT_COMPANY',
  AVG_TENURE_TIME: 'AVG_TENURE_TIME',
  PERSONAL_TENURE_COMPARISON: 'PERSONAL_TENURE_COMPARISON',
  LAYOFF_TALENT_ALERT_DESCRIPTION: 'LAYOFF_TALENT_ALERT_DESCRIPTION',
  LAYOFF_TALENT_ALERT_DESCRIPTION_PERCENTAGE: 'LAYOFF_TALENT_ALERT_DESCRIPTION_PERCENTAGE',
  LEARN_MORE_TITLE: 'LEARN_WHY_TITLE',
  LEARN_MORE_DESCRIPTIONS: 'LEARN_WHY_DESCRIPTIONS',
  DO_NOT_HAVE_IT: 'DO_NOT_HAVE_IT',
  JUST_NOW: 'JUST_NOW',
  FROM_DATE: 'FROM_DATE',
  PRESS_ENTER: 'PRESS_ENTER',
  TYPE_AND_SELECT: 'TYPE_AND_SELECT',
  SELECT_OR_PRESS_ENTER: 'SELECT_OR_PRESS_ENTER',
  PERFECT: 'PERFECT',
  SIGNUP: 'SIGNUP',
  DONT_HAVE_ACCOUNT: 'DONT_HAVE_ACCOUNT',
  WELCOME_BACK_TO: 'WELCOME_BACK_TO',
  WORK_EMAIL: 'WORK_EMAIL',
  YOUR_PASSWORD: 'YOUR_PASSWORD',
  YOUR_EMAIL: 'YOUR_EMAIL',
  CONNECTED: 'CONNECTED',
  CONNECT: 'CONNECT',
  LETS_GO: 'LETS_GO',
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LETS_ENGAGE_TITLE: 'LETS_ENGAGE_TITLE',
  NO_CONTACTS_MESSAGE: 'NO_CONTACTS_MESSAGE',
  SUGGESTED_MESSAGE: 'SUGGESTED_MESSAGE',
  SUBJECT: 'SUBJECT',
  MESSAGE: 'MESSAGE',
  COPY_MESSAGE: 'COPY_MESSAGE',
  OPEN_IN: 'OPEN_IN',
  REQUEST_ADDITIONAL_GENERIC_ERROR: 'REQUEST_ADDITIONAL_GENERIC_ERROR',
  FORGOT_PASSWORD_PAGE_DESCRIPTION: 'FORGOT_PASSWORD_PAGE_DESCRIPTION',
  RESET_PASSWORD_PAGE_DESCRIPTION: 'RESET_PASSWORD_PAGE_DESCRIPTION',
  PASSWORD_UPDATED_MESSAGE: 'PASSWORD_UPDATED_MESSAGE',
  FORGOT_PASSWORD_EMAIL_SENT_MESSAGE: 'FORGOT_PASSWORD_EMAIL_SENT_MESSAGE',
  CHECK_YOUR_EMAIL: 'CHECK_YOUR_EMAIL',
  FORGOT_YOUR: 'FORGOT_YOUR',
  CHOOSE_YOUR_NEW: 'CHOOSE_YOUR_NEW',
  SIGNUP_ERRORS: {
    UNDEFINED: 'SIGNUP_ERRORS_UNDEFINED',
    GOOGLE_LOGIN_FAILED: 'SIGNUP_ERRORS_GOOGLE_LOGIN_FAILED',
    WORK_EMAIL: 'SIGNUP_ERRORS_WORK_EMAIL',
  },
  SEND_VIA_ATS: 'SEND_VIA_ATS',
  SEND_VIA_PERFECT: 'SEND_VIA_PERFECT',
  ATS_NOTE_1_DESCRIPTION: 'ATS_NOTE_1_DESCRIPTION',
  ATS_NOTE_2_DESCRIPTION: 'ATS_NOTE_2_DESCRIPTION',
  ATS_NOTE_3_DESCRIPTION: 'ATS_NOTE_3_DESCRIPTION',
  ATS_MODAL_TITLE: 'ATS_MODAL_TITLE',
  PERFECT_MODAL_TITLE: 'PERFECT_MODAL_TITLE',
  REQUEST_MORE_MATCHES: {
    REQUEST_MORE_MATCHES_TITLE: 'REQUEST_MORE_MATCHES_TITLE',
    REQUEST_MORE_MATCHES_DESCRIPTION: 'REQUEST_MORE_MATCHES_DESCRIPTION',
    REQUESTED_MATCHES_TITLE: 'REQUESTED_MATCHES_TITLE',
    REQUESTED_MATCHES_DESCRIPTION: 'REQUESTED_MATCHES_DESCRIPTION',
    GET_MORE_MATCHES: 'GET_MORE_MATCHES',
  },
  ADDITIONAL_FEEDBACK_MODAL: {
    TITLE: 'ADDITIONAL_FEEDBACK_MODAL_TITLE',
    SUBTITLE: 'ADDITIONAL_FEEDBACK_MODAL_SUBTITLE',
    INPUT_PLACEHOLDER: 'ADDITIONAL_FEEDBACK_MODAL_INPUT_PLACEHOLDER',
    INPUT_ERROR: 'ADDITIONAL_FEEDBACK_MODAL_INPUT_ERROR',
  },
  FEEDBACK_NEEDED_MODAL: {
    TITLE: 'FEEDBACK_NEEDED_MODAL_TITLE',
    SUBTITLE: 'FEEDBACK_NEEDED_MODAL_SUBTITLE',
    BOTTOM_TITLE: 'FEEDBACK_NEEDED_MODAL_BOTTOM_TITLE',
    IMAGE_ALT: 'FEEDBACK_NEEDED_MODAL_IMAGE_ALT',
  },
  INSIGHTS_COMPONENTS: {
    FIRST_BULLET_EXPLANATION_TITLE: 'FIRST_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_TITLE',
    FIRST_BULLET_EXPLANATION_DESCRIPTION: 'FIRST_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_DESCRIPTION',
    SECOND_BULLET_EXPLANATION_TITLE: 'SECOND_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_TITLE',
    SECOND_BULLET_EXPLANATION_DESCRIPTION: 'SECOND_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_DESCRIPTION',
    THIRD_BULLET_EXPLANATION_TITLE: 'THIRD_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_TITLE',
    THIRD_BULLET_EXPLANATION_DESCRIPTION: 'THIRD_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_DESCRIPTION',
    FOURTH_BULLET_EXPLANATION_TITLE: 'FOURTH_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_TITLE',
    FOURTH_BULLET_EXPLANATION_DESCRIPTION: 'FOURTH_INSIGHTS_COMPONENTS_BULLET_EXPLANATION_DESCRIPTION',
    LAST_BULLET_LABEL: 'INSIGHTS_COMPONENTS_LAST_BULLET_LABEL',
  },
  INSIGHT_WIDGETS: {
    BACK_TO_MATCHES_BUTTON_TEXT: 'BACK_TO_MATCHES_BUTTON_TEXT',
    POSITION_INSIGHTS_PAGE_TITLE: 'POSITION_INSIGHTS_PAGE_TITLE',
    POSITION_OVERVIEW_CARD_TITLE: 'POSITION_OVERVIEW_CARD_TITLE',
    COMPANY_INSIGHTS_CARD_TITLE: 'COMPANY_INSIGHTS_CARD_TITLE',
    INDUSTRY_INSIGHTS_CARD_TITLE: 'INDUSTRY_INSIGHTS_CARD_TITLE',
    EXPERIENCE_INSIGHT_CARD_TITLE: 'EXPERIENCE_INSIGHT_CARD_TITLE',
    ROLE_INSIGHT_CARD_TITLE: 'ROLE_INSIGHT_CARD_TITLE',
    SIMILAR_TITLES_CARD_TITLE: 'SIMILAR_TITLES_CARD_TITLE',
    EDUCATION_CARD_TITLE: 'EDUCATION_CARD_TITLE',
    PREVIOUS_COMPANIES_CARD_TEXT: 'PREVIOUS_COMPANIES_CARD_TEXT',
    EMPLOYER_BY_INDUSTRY_CARD_TEXT: 'EMPLOYER_BY_INDUSTRY_CARD_TEXT',
    EDUCATION_SCHOOLS_CARD_TEXT: 'EDUCATION_SCHOOLS_CARD_TEXT',
    INDUSTRIES_INSIGHT_CARD_TEXT: 'INDUSTRIES_INSIGHT_CARD_TEXT',
    MEDIAN_EXPERIENCE_CARD_TEXT: 'MEDIAN_EXPERIENCE_CARD_TEXT',
    FUNNEL_OVERVIEW_CARD_TEXT: 'FUNNEL_OVERVIEW_CARD_TEXT',
    MEDIAN_EXPERIENCE_THIS_ROLE_LABEL: 'MEDIAN_EXPERIENCE_THIS_ROLE_LABEL',
    MEDIAN_EXPERIENCE_OTHER_ROLES_LABEL: 'MEDIAN_EXPERIENCE_OTHER_ROLES_LABEL',
    FIELD_OF_STUDY_CARD_TEXT: 'FIELD_OF_STUDY_CARD_TEXT',
    EDUCATION_DEGREE_CARD_TEXT: 'EDUCATION_DEGREE_CARD_TEXT',
    SIMILAR_TITLES_CARD_TEXT: 'SIMILAR_TITLES_CARD_TEXT',
    ROLE_JOURNEY_CARD_TEXT: 'ROLE_JOURNEY_CARD_TEXT',
    FUNNEL_OVERVIEW_FIRST_BAR_TEXT: 'FUNNEL_OVERVIEW_FIRST_BAR_TEXT',
    FUNNEL_OVERVIEW_SECOND_BAR_TEXT: 'FUNNEL_OVERVIEW_SECOND_BAR_TEXT',
    FUNNEL_OVERVIEW_THIRD_BAR_TEXT: 'FUNNEL_OVERVIEW_THIRD_BAR_TEXT',
    FUNNEL_OVERVIEW_FOURTH_BAR_TEXT: 'FUNNEL_OVERVIEW_FOURTH_BAR_TEXT',
    INSIGHTS_DISCLAIMER: 'INSIGHTS_DISCLAIMER',
  },
  CREATE_POSITION: {
    CREATE_POSITION_OPEN_BUTTON_TOOLTIP: 'CREATE_POSITION_OPEN_BUTTON_TOOLTIP',
    CREATE_POSITION_OPEN_BUTTON: 'CREATE_POSITION_OPEN_BUTTON',
    CREATE_POSITION_LETS_BEGIN_TITLE: 'CREATE_POSITION_LETS_BEGIN_TITLE',
    CREATE_POSITION_CONFIRM_MESSAGE: 'CREATE_POSITION_CONFIRM_MESSAGE',
    ERRORS: {
      TITLE_EXPIRED: 'CREATE_POSITION_ERROR_TITLE_EXPIRED',
      TITLE_QUOTA: 'CREATE_POSITION_ERROR_TITLE_QUOTA',
      TITLE: 'CREATE_POSITION_ERROR_TITLE',
      ACTION_TITLE: 'CREATE_POSITION_ERROR_ACTION_TITLE',
      DESCRIPTION: 'CREATE_POSITION_ERROR_DESCRIPTION',
    },
  },
  UPGRADE_PLAN_DIALOG: {
    TRIAL_ENDED: {
      SUBTITLE: 'UPGRADE_PLAN_DIALOG_TRIAL_ENDED_SUBTITLE',
      TITLE: 'UPGRADE_PLAN_DIALOG_TRIAL_ENDED_TITLE',
      DESCRIPTION: 'UPGRADE_PLAN_DIALOG_TRIAL_ENDED_DESCRIPTION',
    },
    MATCH_DAILY_LIMIT: {
      SUBTITLE: 'UPGRADE_PLAN_DIALOG_MATCH_DAILY_LIMIT_SUBTITLE',
      TITLE: 'UPGRADE_PLAN_DIALOG_MATCH_DAILY_LIMIT_TITLE',
      DESCRIPTION: 'UPGRADE_PLAN_DIALOG_MATCH_DAILY_LIMIT_DESCRIPTION',
    },
    EXCEED_POSITION_LIMIT: {
      SUBTITLE: 'UPGRADE_PLAN_DIALOG_EXCEED_POSITION_LIMIT_SUBTITLE',
    },
    NEED_MORE_MATCHES: {
      SUBTITLE: 'UPGRADE_PLAN_DIALOG_NEED_MORE_MATCHES_SUBTITLE',
    },
    DEFAULT: {
      SUBTITLE: 'UPGRADE_PLAN_DIALOG_DEFAULT_SUBTITLE',
      TITLE: 'UPGRADE_PLAN_DIALOG_DEFAULT_TITLE',
      DESCRIPTION: 'UPGRADE_PLAN_DIALOG_DEFAULT_DESCRIPTION',
    },
  },
  PRO_DOWNGRADED_DIALOG: {
    FOOTER_TITLE: 'PRO_DOWNGRADED_DIALOG_FOOTER_TITLE',
    FOOTER_SUB_TITLE: 'PRO_DOWNGRADED_DIALOG_FOOTER_SUB_TITLE',
    HEADER_TITLE: 'PRO_DOWNGRADED_DIALOG_HEADER_TITLE',
    HEADER_SUB_TITLE: 'PRO_DOWNGRADED_DIALOG_HEADER_SUB_TITLE',
    BUTTON_TITLE: 'PRO_DOWNGRADED_DIALOG_BUTTON_TITLE',
    SUGGESTIONS: {
      UNLIMITED_POSITIONS: 'PRO_DOWNGRADED_DIALOG_SUGGESTIONS_UNLIMITED_POSITIONS',
      UNLIMITED_DAILY_MATCHES: 'PRO_DOWNGRADED_DIALOG_SUGGESTIONS_UNLIMITED_DAILY_MATCHES',
      ACCESS_ALL_FEATURES: 'PRO_DOWNGRADED_DIALOG_ACCESS_ALL_FEATURES',
    },
  },
  DAILY_LIMIT: {
    TITLE: 'DAILY_LIMIT_TITLE',
    NEXT_MATCHES_BATCH_TIME: 'DAILY_LIMIT_NEXT_MATCHES_BATCH_TIME',
    PRO_USER: {
      TITLE: 'DAILY_LIMIT_PRO_USER_TITLE',
      DESCRIPTION: 'DAILY_LIMIT_PRO_USER_DESCRIPTION',
      PRIMARY_BUTTON: 'DAILY_LIMIT_PRO_USER_PRIMARY_BUTTON',
      SECONDARY_BUTTON: 'DAILY_LIMIT_PRO_USER_SECONDARY_BUTTON',
    },
    INSIGHTS: {
      TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_TITLE',
      ENGAGED_MATCHES_TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_ENGAGED_MATCHES_TITLE',
      ENGAGED_MATCHES_ACTION_TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_ENGAGED_MATCHES_ACTION_TITLE',
      ACCEPTANCE_RATE_MATCHES_TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_ACCEPTANCE_RATE_MATCHES_TITLE',
      ACCEPTANCE_RATE_ACTION_TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_ACCEPTANCE_RATE_ACTION_TITLE',
      SCREENING_RATE_TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_SCREENING_RATE_TITLE',
      SCREENING_RATE_DESCRIPTION: 'DAILY_LIMIT_FREE_USER_INSIGHTS_SCREENING_RATE_DESCRIPTION',
      TIME_SAVED_TITLE: 'DAILY_LIMIT_FREE_USER_INSIGHTS_TIME_SAVED_TITLE',
      TIME_SAVED_DESCRIPTION: 'DAILY_LIMIT_FREE_USER_INSIGHTS_TIME_SAVED_DESCRIPTION',
    },
    FREE_USER: {
      TITLE: 'DAILY_LIMIT_FREE_USER_TITLE',
      DESCRIPTION: 'DAILY_LIMIT_FREE_USER_DESCRIPTION',
      PRIMARY_BUTTON: 'DAILY_LIMIT_FREE_USER_PRIMARY_BUTTON',
      SECONDARY_BUTTON: 'DAILY_LIMIT_FREE_USER_SECONDARY_BUTTON',
      UPGRADE_TO_PRO_BUTTON: 'DAILY_LIMIT_FREE_USER_UPGRADE_TO_PRO_BUTTON',
    },
    NEED_MORE_FLEXIBILITY: 'DAILY_LIMIT_NEED_MORE_FLEXIBILITY',
    CONSIDER_UPGRADING: 'DAILY_LIMIT_CONSIDER_UPGRADING',
  },
  ENGAGE_PANEL: {
    TITLE: 'ENGAGE_PANEL_TITLE',
    ABOUT_TITLE: 'ENGAGE_PANEL_ABOUT_TITLE',
    CONTACT_DETAILS_TITLE: 'ENGAGE_PANEL_CONTACT_DETAILS_TITLE',
    CONTACT_DETAILS_GO_TO_MATCH_BUTTON: 'ENGAGE_PANEL_CONTACT_DETAILS_GO_TO_MATCH_BUTTON',
    SOCIAL_INFO_TITLE: 'ENGAGE_PANEL_SOCIAL_INFO_TITLE',
    MISSING_CONTACTS: {
      BOLD_TEXT: 'ENGAGE_PANEL_MISSING_CONTACTS_BOLD_TITLE',
      TEXT: 'ENGAGE_PANEL_MISSING_CONTACTS_TITLE',
    },
    UPLOAD_ATS_BUTTON: {
      UPLOAD: 'UPLOAD_ATS_BUTTON_UPLOAD',
      UPLOADING: 'UPLOAD_ATS_BUTTON_UPLOADING',
      UPLOADED: 'UPLOAD_ATS_BUTTON_UPLOADED',
    },
    SEE_MORE_BUTTON: {
      SHOW: 'SEE_MORE_BUTTON_SHOW',
      HIDE: 'SEE_MORE_BUTTON_HIDE',
    },
    SUGGESTED_MESSAGE: {
      TITLE: 'SUGGESTED_MESSAGE_TITLE',
    },
    TALENT_NETWORK: {
      TITLE: 'TALENT_NETWORK_TITLE',
    },
  },
  PAYMENT_SUCCESS_DIALOG: {
    TITLE: 'PAYMENT_SUCCESS_DIALOG_TITLE',
    DESCRIPTION: 'PAYMENT_SUCCESS_DIALOG_DESCRIPTION',
    BUTTON_TITLE: 'PAYMENT_SUCCESS_DIALOG_BUTTON_TITLE',
  },
  BUY_POSITION_DIALOG: {
    TITLE: 'BUY_POSITION_DIALOG_TITLE',
    DESCRIPTION: 'BUY_POSITION_DIALOG_DESCRIPTION',
    PRIMARY_BUTTON_TITLE: 'BUY_POSITION_DIALOG_PRIMARY_BUTTON_TITLE',
    SECONDARY_BUTTON_TITLE: 'BUY_POSITION_DIALOG_SECONDARY_BUTTON_TITLE',
  },
  ACTIVE_POSITION_PURCHASE_DIALOG: {
    TITLE: 'ACTIVE_POSITION_PURCHASE_DIALOG_TITLE',
    DESCRIPTION: 'ACTIVE_POSITION_PURCHASE_DIALOG_DESCRIPTION',
    PRIMARY_BUTTON_TITLE: 'ACTIVE_POSITION_PURCHASE_DIALOG_PRIMARY_BUTTON_TITLE',
    SECONDARY_BUTTON_TITLE: 'ACTIVE_POSITION_PURCHASE_DIALOG_SECONDARY_BUTTON_TITLE',
  },
  UPGRADE_TO_SCALE_DIALOG: {
    TITLE: 'UPGRADE_TO_SCALE_DIALOG_TITLE',
    DESCRIPTION: 'UPGRADE_TO_SCALE_DIALOG_DESCRIPTION',
    PRIMARY_BUTTON_TITLE: 'UPGRADE_TO_SCALE_DIALOG_PRIMARY_BUTTON_TITLE',
    SECONDARY_BUTTON_TITLE: 'UPGRADE_TO_SCALE_DIALOG_SECONDARY_BUTTON_TITLE',
  },
  MATCHES_WERE_ALMOST_GONE_DIALOG: {
    CLOSE_TITLE: 'MATCHES_WERE_ALMOST_GONE_DIALOG_CLOSE_TITLE',
    HEADER_EMOJI: 'MATCHES_WERE_ALMOST_GONE_DIALOG_HEADER_EMOJI',
    TITLE: 'MATCHES_WERE_ALMOST_GONE_DIALOG_TITLE',
    DESCRIPTION: 'MATCHES_WERE_ALMOST_GONE_DIALOG_DESCRIPTION',
  },
  POSITION_INTRODUCTION_PAGE: {
    TITLE: 'POSITION_INTRODUCTION_PAGE_TITLE',
    TOP_TITLE: 'POSITION_INTRODUCTION_PAGE_TOP_TITLE',
    FOOTER_TITLE: 'POSITION_INTRODUCTION_PAGE_FOOTER_TITLE',
    BUTTON_TEXT: 'POSITION_INTRODUCTION_PAGE_BUTTON_TEXT',
    DESCRIPTION: 'POSITION_INTRODUCTION_PAGE_DESCRIPTION',
  },
  POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE: {
    TITLE: 'POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE_TITLE',
    SUBTITLE: 'POSITION_INTRODUCTION_SCHEDULE_MEETING_PAGE_SUBTITLE',
  },
  MATCH_TUNE: {
    MATCHES_READY: 'MATCHES_READY',
    TITLE: 'MATCH_TUNE_TITLE',
    SUBTITLE: 'MATCH_TUNE_SUBTITLE',
    TITLE_MANUAL: 'MATCH_TUNE_TITLE_MANUAL',
    SUBTITLE_MANUAL: 'MATCH_TUNE_SUBTITLE_MANUAL',
    CONFIRM_BUTTON: 'MATCH_TUNE_CONFIRM_BUTTON',
    SKIP_BUTTON: 'MATCH_TUNE_SKIP_BUTTON',
    PREFERENCES_BUTTON_TEXT: 'MATCH_TUNE_PREFERENCES_BUTTON_TEXT',
    BYEBIAS_BUTTON_TEXT: 'MATCH_TUNE_BYEBIAS_BUTTON_TEXT',
    BYEBIAS_POPUP_TITLE: 'MATCH_TUNE_BYEBIAS_POPUP_TITLE',
    BYEBIAS_POPUP_DESCRIPTION: 'MATCH_TUNE_BYEBIAS_POPUP_DESCRIPTION',
    BYEBIAS_POPUP_LEARN_MORE: 'MATCH_TUNE_BYEBIAS_POPUP_LEARN_MORE',
    BYEBIAS_POPUP_ACTIVATED: 'MATCH_TUNE_BYEBIAS_POPUP_ACTIVATED',
    BYEBIAS_POPUP_DISABLED: 'MATCH_TUNE_BYEBIAS_POPUP_DISABLED',
    BYEBIAS_TOOLTIP_TITLE: 'MATCH_TUNE_BYEBIAS_TOOLTIP_TITLE',
    BYEBIAS_TOOLTIP_DESCRIPTION: 'MATCH_TUNE_BYEBIAS_TOOLTIP_DESCRIPTION',
    STEPS_CARDS: {
      LOCATIONS: 'MATCH_TUNE_STEPS_CARDS_LOCATIONS',
      LEVELS_OF_EXPERIENCE: 'MATCH_TUNE_STEPS_CARDS_LEVELS_OF_EXPERIENCE',
      COMPANY_BACKGROUND: 'MATCH_TUNE_STEPS_CARDS_COMPANY_BACKGROUND',
      SKILLS: 'MATCH_TUNE_STEPS_CARDS_SKILLS',
      COMPANY_SIZES: 'MATCH_TUNE_STEPS_CARDS_COMPANY_SIZES',
      JOB_TITLE: 'MATCH_TUNE_STEPS_CARDS_JOB_TITLE',
      EDUCATION: 'MATCH_TUNE_STEPS_CARDS_EDUCATION',
      POSITION_FEEDBACK: 'MATCH_TUNE_STEPS_CARDS_POSITION_FEEDBACK',
    },
    REQUIREMENT_CONTAINER: {
      INPUT_PLACEHOLDER: 'MATCH_TUNE_REQUIREMENT_CONTAINER_INPUT_PLACEHOLDER',
      EMPTY_STATE_TEXT: 'MATCH_TUNE_REQUIREMENT_CONTAINER_EMPTY_STATE_TEXT',
      NOTIFICATION_TIP: {
        INFO_LABEL: 'MATCH_TUNE_REQUIREMENT_CONTAINER_NOTIFICATION_TIP_INFO_LABEL',
        TITLE: 'MATCH_TUNE_REQUIREMENT_CONTAINER_NOTIFICATION_TIP_TITLE',
        DESCRIPTION: 'MATCH_TUNE_REQUIREMENT_CONTAINER_NOTIFICATION_TIP_DESCRIPTION',
        BUTTON_TEXT: 'MATCH_TUNE_REQUIREMENT_CONTAINER_NOTIFICATION_TIP_BUTTON_TEXT',
      },
    },
    LOCATIONS_STEP: {
      TITLE: 'MATCH_TUNE_LOCATIONS_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_LOCATIONS_STEP_SUBTITLE',
      PRIMARY_LOCATION_PLACEHOLDER: 'MATCH_TUNE_LOCATIONS_STEP_PRIMARY_LOCATION_PLACEHOLDER',
      PRIMARY_LOCATION_LABEL: 'MATCH_TUNE_LOCATIONS_STEP_PRIMARY_LOCATION_LABEL',
      SECONDARY_LOCATION_PLACEHOLDER: 'MATCH_TUNE_LOCATIONS_STEP_SECONDARY_LOCATION_PLACEHOLDER',
      SECONDARY_LOCATION_LABEL: 'MATCH_TUNE_LOCATIONS_STEP_SECONDARY_LOCATION_LABEL',
      ADD_ANOTHER_LOCATION_BUTTON_TEXT: 'MATCH_TUNE_LOCATIONS_STEP_ADD_ANOTHER_LOCATION_BUTTON_TEXT',
      MILE_RADIUS_SELECT_LABEL: 'MATCH_TUNE_LOCATIONS_STEP_MILE_RADIUS_SELECT_LABEL',
      MILE_RADIUS_SELECT_VALUE: 'MATCH_TUNE_LOCATIONS_STEP_MILE_RADIUS_SELECT_VALUE',
      MILE_RADIUS_SELECT_VALUE_EXACT_LOCATION: 'MATCH_TUNE_LOCATIONS_STEP_MILE_RADIUS_SELECT_VALUE_EXACT_LOCATION',
      LOCATION_MENU_ITEM_TYPE: {
        ['AREA' as string]: 'MATCH_TUNE_LOCATIONS_STEP_LOCATION_MENU_ITEM_TYPE_AREA',
        ['CITY' as string]: 'MATCH_TUNE_LOCATIONS_STEP_LOCATION_MENU_ITEM_TYPE_CITY',
        ['STATE' as string]: 'MATCH_TUNE_LOCATIONS_STEP_LOCATION_MENU_ITEM_TYPE_STATE',
      },
    },
    LEVEL_OF_EXPERIENCE_STEP: {
      TITLE: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_SUBTITLE',
      RELEVANT_LABEL: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_RELEVANT_LABEL',
      OVERALL_LABEL: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_OVERALL_LABEL',
      RELEVANCE_MENU: {
        MENU_TITLE: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_RELEVANCE_MENU_MENU_TITLE',
        RELEVANT_TITLE: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_RELEVANCE_MENU_RELEVANT_TITLE',
        RELEVANT_DESCRIPTION: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_RELEVANCE_MENU_RELEVANT_DESCRIPTION',
        OVERALL_TITLE: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_RELEVANCE_MENU_OVERALL_TITLE',
        OVERALL_DESCRIPTION: 'MATCH_TUNE_LEVEL_OF_EXPERIENCE_STEP_RELEVANCE_MENU_OVERALL_DESCRIPTION',
      },
    },
    EDUCATION_STEP: {
      TITLE: 'MATCH_TUNE_EDUCATION_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_EDUCATION_STEP_SUBTITLE',
      FIELD_OF_STUDY_EMPTY_STATE_TEXT: 'MATCH_TUNE_EDUCATION_STEP_FIELD_OF_STUDY_EMPTY_STATE_TEXT',
      DEGREE_TITLE: 'MATCH_TUNE_EDUCATION_STEP_DEGREE_TITLE',
      FIELD_OF_STUDY_TITLE: 'MATCH_TUNE_EDUCATION_STEP_FIELD_OF_STUDY_TITLE',
    },
    INDUSTRY_EXPERIENCE_STEP: {
      TITLE: 'MATCH_TUNE_INDUSTRY_EXPERIENCE_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_INDUSTRY_EXPERIENCE_STEP_SUBTITLE',
      EMPTY_STATE_TEXT: 'MATCH_TUNE_INDUSTRY_EXPERIENCE_STEP_EMPTY_STATE_TEXT',
    },
    COMPANY_SIZE_STEP: {
      TITLE: 'MATCH_TUNE_COMPANY_SIZE_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_COMPANY_SIZE_STEP_SUBTITLE',
    },
    SKILLS_STEP: {
      TITLE: 'MATCH_TUNE_SKILLS_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_SKILLS_STEP_SUBTITLE',
      EMPTY_STATE_TEXT: 'MATCH_TUNE_SKILLS_STEP_EMPTY_STATE_TEXT',
    },
    JOB_TITLE_STEP: {
      TITLE: 'MATCH_TUNE_JOB_TITLE_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_JOB_TITLE_STEP_SUBTITLE',
      JOB_TITLE_INPUT_TITLE: 'MATCH_TUNE_JOB_TITLE_STEP_JOB_TITLE_INPUT_TITLE',
      JOB_TITLE_INPUT_PLACEHOLDER: 'MATCH_TUNE_JOB_TITLE_STEP_JOB_TITLE_INPUT_PLACEHOLDER',
      SIMILAR_TITLES_TITLE: 'MATCH_TUNE_JOB_TITLE_STEP_SIMILAR_TITLES_TITLE',
      SIMILAR_TITLES_DESCRIPTION: 'MATCH_TUNE_JOB_TITLE_STEP_SIMILAR_TITLES_DESCRIPTION',
      SIMILAR_TITLES_LOADING_TEXT: 'MATCH_TUNE_JOB_TITLE_STEP_SIMILAR_TITLES_LOADING_TEXT',
    },
    POSITION_FEEDBACK_STEP: {
      TITLE: 'MATCH_TUNE_POSITION_FEEDBACK_STEP_TITLE',
      SUBTITLE: 'MATCH_TUNE_POSITION_FEEDBACK_STEP_SUBTITLE',
      INPUT_PLACEHOLDER: 'MATCH_TUNE_POSITION_FEEDBACK_STEP_INPUT_PLACEHOLDER',
      SUCCESS_SNACKBAR_TITLE: 'MATCH_TUNE_POSITION_FEEDBACK_STEP_SUCCESS_SNACKBAR_TITLE',
      SUCCESS_SNACKBAR_DESCRIPTION: 'MATCH_TUNE_POSITION_FEEDBACK_SUCCESS_SNACKBAR_DESCRIPTION',
    },
  },
  TALENT_NETWORK: {
    HIGH_SCORE_TITLE: 'TALENT_NETWORK_HIGH_SCORE_TITLE',
    MED_LOW_SCORE_TITLE: 'TALENT_NETWORK_MED_LOW_SCORE_TITLE',
    TITLE: {
      LOW: 'TALENT_NETWORK_TITLE_LOW',
      MEDIUM: 'TALENT_NETWORK_TITLE_MEDIUM',
      STRONG: 'TALENT_NETWORK_TITLE_STRONG',
    },
    TIPS: {
      LABEL: 'TALENT_NETWORK_TIPS_LABEL',
      LOW_CONNECTION: 'TALENT_NETWORK_TIPS_LOW_CONNECTION',
      MEDIUM_CONNECTION: 'TALENT_NETWORK_TIPS_MEDIUM_CONNECTION',
      STRONG_CONNECTION: 'TALENT_NETWORK_TIPS_STRONG_CONNECTION',
      UPGRADE_TO_PRO: 'TALENT_NETWORK_TIPS_UPGRADE_TO_PRO',
    },
    TIMELINE: {
      MULTIPLE: 'TALENT_NETWORK_TIMELINE_MULTIPLE',
      EXPERIENCE: {
        WORKED_WITH: 'TALENT_NETWORK_TIMELINE_EXPERIENCE_WORKED_WITH',
        SAME_ROLE: 'TALENT_NETWORK_TIMELINE_EXPERIENCE_SAME_ROLE',
        SIMILAR_ROLE: 'TALENT_NETWORK_TIMELINE_EXPERIENCE_SIMILAR_ROLE',
        SAME_COMPANY_SITE: 'TALENT_NETWORK_TIMELINE_EXPERIENCE_SAME_COMPANY_SITE',
      },
      EDUCATION: {
        SAME_CLASS: 'TALENT_NETWORK_TIMELINE_EDUCATION_SAME_CLASS',
        SAME_MAJOR: 'TALENT_NETWORK_TIMELINE_EDUCATION_SAME_MAJOR',
        SAME_UNIVERSITY: 'TALENT_NETWORK_TIMELINE_EDUCATION_SAME_UNIVERSITY',
        SAME_TIME: 'TALENT_NETWORK_TIMELINE_EDUCATION_SAME_TIME',
      },
      FIRST_TIME_EXPERIENCE: {
        TITLE: 'TALENT_NETWORK_TIMELINE_FIRST_TIME_EXPERIENCE_TITLE',
        TEXT: 'TALENT_NETWORK_TIMELINE_FIRST_TIME_EXPERIENCE_TEXT',
      },
    },
    DIALOG: {
      TITLE: 'TALENT_NETWORK_DIALOG_TITLE',
      SUBTITLE: 'TALENT_NETWORK_DIALOG_SUBTITLE',
      INFO_TEXT: 'TALENT_NETWORK_DIALOG_INFO_TEXT',
    },
    CARDS: {
      EDUCATION_AND_EXPERIENCE: 'TALENT_NETWORK_CARDS_EDUCATION_AND_EXPERIENCE',
      EXPERIENCE: {
        WORKED_WITH: 'TALENT_NETWORK_CARDS_EXPERIENCE_WORKED_WITH',
        SAME_ROLE: 'TALENT_NETWORK_CARDS_EXPERIENCE_SAME_ROLE',
        SIMILAR_ROLE: 'TALENT_NETWORK_CARDS_EXPERIENCE_SIMILAR_ROLE',
      },
      EDUCATION: {
        SAME_UNIVERSITY: 'TALENT_NETWORK_CARDS_EDUCATION_SAME_UNIVERSITY',
      },
    },
  },
  OUTREACH_PAGE: {
    SIGNATURE_DIALOG: {
      TITLE: 'TALENT_NETWORK_SIGNATURE_DIALOG_TITLE',
      STEP_1: {
        TITLE: 'OUTREACH_PAGE_SIGNATURE_DIALOG_STEP_1_TITLE',
        DESCRIPTION: 'OUTREACH_PAGE_SIGNATURE_DIALOG_STEP_1_DESCRIPTION',
      },
      STEP_2: {
        TITLE: 'OUTREACH_PAGE_SIGNATURE_DIALOG_STEP_2_TITLE',
        DESCRIPTION: 'OUTREACH_PAGE_SIGNATURE_DIALOG_STEP_2_DESCRIPTION',
      },
      STEP_3: {
        DESCRIPTION: 'OUTREACH_PAGE_SIGNATURE_DIALOG_STEP_3_DESCRIPTION',
        LINK_TO_SUPPORT: 'OUTREACH_PAGE_SIGNATURE_DIALOG_STEP_3_LINK_TO_SUPPORT',
      },
      ACTION: {
        CANCEL: 'OUTREACH_PAGE_SIGNATURE_DIALOG_ACTION_CANCEL',
        REFRESH: 'OUTREACH_PAGE_SIGNATURE_DIALOG_ACTION_REFRESH',
        CONFIRM: 'OUTREACH_PAGE_SIGNATURE_DIALOG_ACTION_CONFIRM',
      },
    },
    PREFERENCES_CONFIRMATION_MESSAGE: 'OUTREACH_PAGE_PREFERENCES_CONFIRMATION_MESSAGE',
    CONVERSATIONS_GROUP: {
      SECTION_TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_SECTION_TITLE',
      NESTED_PANEL: {
        TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_NESTED_PANEL_TITLE',
        OPEN_TASKS_TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_NESTED_PANEL_OPEN_TASKS_TITLE',
        CONVERSATIONS_TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_NESTED_PANEL_CONVERSATIONS_TITLE',
      },
      GROUP_NAME: {
        ['REPLIED' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_NAME_REPLIED',
        ['TO_OUTREACH' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_TO_OUTREACH',
        ['TO_FOLLOWUP' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_TO_FOLLOWUP',
        ['SENT' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_NAME_SENT',
        ['ARCHIVED' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_NAME_ARCHIVED',
        ['ALL' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_NAME_ALL',
        ['COMPLETED' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_NAME_COMPLETED',
        ['STEP' as string]: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_GROUP_NAME_STEP',
      },
      PREFERENCES: {
        GEN_AI_TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_PREFERENCES_GEN_AI_TITLE',
        CAMPAIGN_TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_PREFERENCES_CAMPAIGN_TITLE',
        MANUAL_LABEL: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_PREFERENCES_MANUAL_LABEL',
        AUTO_LABEL: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_PREFERENCES_AUTO_LABEL',
        CUSTOM_LABEL: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_PREFERENCES_CUSTOM_LABEL',
      },
    },
    FILTER: {
      STATUS_ITEM_TITLE: 'OUTREACH_PAGE_FILTER_STATUS_TITLE',
      LIKED_BY_ITEM_TITLE: 'OUTREACH_PAGE_FILTER_LIKED_BY',
      CLEAR_ITEM_TITLE: 'OUTREACH_PAGE_FILTER_CLEAR_ITEM_TITLE',
      LICKED_BY_SUB_MENU_TITLE: 'OUTREACH_PAGE_FILTER_LICKED_BY_SUB_MENU_TITLE',
      FILTER_BY_STATUS: {
        NEW_TITLE: 'OUTREACH_PAGE_FILTER_FILTER_BY_STATUS_NEW_TITLE',
        SENT_TITLE: 'OUTREACH_PAGE_FILTER_FILTER_BY_STATUS_SENT_TITLE',
        REPLIED_TITLE: 'OUTREACH_PAGE_FILTER_FILTER_BY_STATUS_REPLIED_TITLE',
      },
    },
    CONVERSATIONS_GROUP_EMPTY: {
      EMOJI: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_EMPTY_EMOJI',
      TITLE: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_EMPTY_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_CONVERSATIONS_GROUP_EMPTY_DESCRIPTION',
    },
    CONVERSATION_ITEM: {
      ARCHIVED: 'OUTREACH_PAGE_CONVERSATION_ITEM_ARCHIVED',
      SCHEDULED_MESSAGE: 'OUTREACH_PAGE_CONVERSATION_ITEM_SCHEDULED_MESSAGE',
      TASKS_MESSAGE: 'OUTREACH_PAGE_CONVERSATION_ITEM_TASK_MESSAGE',
      SEND_MESSAGE: 'OUTREACH_PAGE_CONVERSATION_ITEM_SEND_MESSAGE',
      ERROR_MESSAGE: 'OUTREACH_PAGE_CONVERSATION_ITEM_ERROR_MESSAGE',
      BOUNCED_ERROR_MESSAGE: 'OUTREACH_PAGE_CONVERSATION_ITEM_BOUNCED_ERROR_MESSAGE',
    },
    CONVERSATION_MESSAGE: {
      TRIGGER_TYPE: {
        SUGGESTED_FOLLOWUP: 'OUTREACH_PAGE_CONVERSATION_MESSAGE_TRIGGER_TYPE_SUGGESTED_FOLLOWUP',
        AUTOMATED: 'OUTREACH_PAGE_CONVERSATION_MESSAGE_TRIGGER_TYPE_AUTOMATED',
        LINKEDIN_TASK: 'OUTREACH_PAGE_CONVERSATION_MESSAGE_TRIGGER_TYPE_LINKEDIN_TASK',
      },
    },
    SPICES: {
      ['insiderNetwork' as string]: 'OUTREACH_PAGE_SPICES_INSIDER_NETWORK',
      ['likelyhoodToMove' as string]: 'OUTREACH_PAGE_SPICES_LIKELYHOOD_TO_MOVE',
      ['relevantSkills' as string]: 'OUTREACH_PAGE_SPICES_RELEVANT_SKILLS',
      ['relevantEducation' as string]: 'OUTREACH_PAGE_SPICES_RELEVANT_EDUCATION',
      ['similarCompanies' as string]: 'OUTREACH_PAGE_SPICES_SIMILAR_COMPANIES',
    },
    TONE_OF_VOICE: {
      ['AUTO' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_AUTO',
      ['PROFESSIONAL_AND_FORMAL' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_PROFESSIONAL_AND_FORMAL',
      ['FRIENDLY_AND_CONVERSATIONAL' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_FRIENDLY_AND_CONVERSATIONAL',
      ['ENTHUSIASTIC_AND_ENERGETIC' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_ENTHUSIASTIC_AND_ENERGETIC',
      ['INSPIRATIONAL_AND_ASPIRATIONAL' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_INSPIRATIONAL_AND_ASPIRATIONAL',
      ['DIRECT_AND_CONCISE' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_DIRECT_AND_CONCISE',
      ['EMPATHETIC_AND_UNDERSTANDING' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_EMPATHETIC_AND_UNDERSTANDING',
      ['INFORMATIVE_AND_DETAILED' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_INFORMATIVE_AND_DETAILED',
      ['LIGHTHEARTED_AND_HUMOROUS' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_LIGHTHEARTED_AND_HUMOROUS',
      ['PROFESSIONAL_POLISHED' as string]: 'OUTREACH_PAGE_TONE_OF_VOICE_PROFESSIONAL_POLISHED',
    },
    SEARCH: {
      TITLE: 'OUTREACH_PAGE_SEARCH_TITLE',
      SEARCH_PLACEHOLDER: 'OUTREACH_PAGE_SEARCH_SEARCH_PLACEHOLDER',
    },
    FILTER_CARDS: {
      TITLE_LIKED: 'OUTREACH_PAGE_FILTER_CARDS_TITLE_LIKED',
      TITLE_REPLIES: 'OUTREACH_PAGE_FILTER_CARDS_TITLE_REPLIES',
    },
    EMAILS: {
      MISSING_ALERT_LABEL: 'OUTREACH_PAGE_EMAILS_MISSING_ALERT_LABEL',
      MISSING_ALERT_DESCRIPTION: 'OUTREACH_PAGE_EMAILS_MISSING_ALERT_DESCRIPTION',
      MISSING_ALERT_ACTION_BUTTON_TEXT: 'OUTREACH_PAGE_EMAILS_MISSING_ALERT_ACTION_BUTTON_TEXT',
      INVALID_ALERT: 'OUTREACH_PAGE_EMAILS_INVALID_ALERT',
      TO: {
        LABEL: 'OUTREACH_PAGE_EMAILS_TO_LABEL',
      },
      FROM: {
        LABEL: 'OUTREACH_PAGE_EMAILS_FROM_LABEL',
        USER_EMAIL_ALIAS: 'OUTREACH_PAGE_EMAILS_TO_USER_EMAIL_ALIAS',
      },
      EMAIL_LESS: {
        TITLE: 'OUTREACH_PAGE_EMAILS_EMAIL_LESS_TITLE',
        TOOLTIP: 'OUTREACH_PAGE_EMAILS_EMAIL_LESS_TOOLTIP',
      },
    },
    EDITOR: {
      SAVE_CHANGES_BUTTON_TEXT: 'OUTREACH_PAGE_EDITOR_SAVE_CHANGES_BUTTON_TEXT',
      MARK_AS_DONE_BUTTON_TEXT: 'OUTREACH_PAGE_EDITOR_MARK_AS_DONE_BUTTON_TEXT',
      SEND_MESSAGE_NOW_BUTTON_TEXT: 'OUTREACH_PAGE_EDITOR_SEND_MESSAGE_NOW_BUTTON_TEXT',
      FUTURE_TASK_INFO_TEXT: 'OUTREACH_PAGE_EDITOR_FUTURE_TASK_INFO_TEXT',
      GENERATE_MESSAGE_ERROR_CHIP_TEXT: 'OUTREACH_PAGE_EDITOR_GENERATE_MESSAGE_ERROR_CHIP_TEXT',
      GENERATE_MESSAGE_ERROR_CHIP_BUTTON_TEXT: 'OUTREACH_PAGE_EDITOR_GENERATE_MESSAGE_ERROR_CHIP_BUTTON_TEXT',
      REGENERATE_MESSAGE_BUTTON_TEXT: 'OUTREACH_PAGE_EDITOR_REGENERATE_MESSAGE_BUTTON_TEXT',
      MENTIONS_PLACEHOLDER: {
        TITLE: 'OUTREACH_PAGE_EDITOR_MENTIONS_PLACEHOLDER_MENTIONS_PLACEHOLDER_TITLE',
      },
      MENTIONS: {
        MENU_ITEMS: {
          TALENT_FIRST_NAME: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_TALENT_FIRST_NAME',
          TALENT_LAST_NAME: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_TALENT_LAST_NAME',
          TALENT_YEARS_EXPERIENCE: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_TALENT_YEARS_EXPERIENCE',
          TALENT_CURRENT_COMPANY_NAME: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_TALENT_CURRENT_COMPANY_NAME',
          ACCOUNT_COMPANY_NAME: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_ACCOUNT_COMPANY_NAME',
          SENDER_NAME: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_SENDER_NAME',
        },
        MENU_ITEMS_TITLE: {
          TALENT: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_TITLE_TALENT',
          SENDER: 'OUTREACH_PAGE_EDITOR_MENTIONS_MENU_ITEMS_TITLE_SENDER',
        },
      },
      SUBJECT: {
        PLACEHOLDER: 'OUTREACH_PAGE_EDITOR_SUBJECT_PLACEHOLDER',
      },
      MESSAGE: {
        PLACEHOLDER: 'OUTREACH_PAGE_EDITOR_MESSAGE_PLACEHOLDER',
      },
      SPICES_INFO: {
        HINT_TEXT: 'OUTREACH_PAGE_EDITOR_SPICES_INFO_HINT_TEXT',
        TONE_OF_VOICE: {
          TOOLTIP_TITLE: 'OUTREACH_PAGE_EDITOR_SPICES_INFO_TONE_OF_VOICE_TOOLTIP_TITLE',
          TOOLTIP_DESCRIPTION: 'OUTREACH_PAGE_EDITOR_SPICES_INFO_TONE_OF_VOICE_TOOLTIP_DESCRIPTION',
        },
        SPICE: {
          TOOLTIP_TITLE: 'OUTREACH_PAGE_EDITOR_SPICES_INFO_SPICE_TOOLTIP_TITLE',
          TOOLTIP_DESCRIPTION: 'OUTREACH_PAGE_EDITOR_SPICES_INFO_SPICE_TOOLTIP_DESCRIPTION',
        },
      },
      ALERT_HEADER: {
        LABEL: 'OUTREACH_PAGE_EDITOR_ALERT_HEADER_LABEL',
        INFO_TEXT: 'OUTREACH_PAGE_EDITOR_ALERT_HEADER_INFO_TEXT',
      },
      AUTO_MODE_HEADER: {
        LABEL: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_LABEL',
        INFO_TEXT_PLURAL: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_INFO_TEXT_PLURAL',
        INFO_TEXT_SINGULAR: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_INFO_TEXT_SINGULAR',
        INFO_TEXT_TODAY: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_INFO_TEXT_TODAY',
        INFO_TEXT_NO_TIMESTAMP: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_INFO_TEXT_NO_TIMESTAMP',
        STEPS_TEXT: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_STEPS_TEXT',
        MENU_OPTIONS: {
          CANCEL_CAMPAIGN: 'OUTREACH_PAGE_EDITOR_AUTO_MODE_HEADER_MENU_OPTIONS_CANCEL_CAMPAIGN',
        },
      },
      MANUAL_MODE_HEADER: {
        LABEL: 'OUTREACH_PAGE_EDITOR_MANUAL_MODE_HEADER_LABEL_TODAY',
        INFO_TEXT: 'OUTREACH_PAGE_EDITOR_MANUAL_MODE_HEADER_INFO_TEXT',
        INFO_TEXT_EMPTY_CONVERSATION: 'OUTREACH_PAGE_EDITOR_MANUAL_MODE_HEADER_INFO_TEXT_EMPTY_CONVERSATION',
        ACTION_BUTTON_TEXT: 'OUTREACH_PAGE_EDITOR_MANUAL_MODE_HEADER_ACTION_BUTTON_TEXT',
      },
      LOADING_TEXT: [
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_0',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_1',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_2',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_3',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_4',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_5',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_6',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_7',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_8',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_9',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_10',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_11',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_12',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_13',
        'OUTREACH_PAGE_EDITOR_LOADING_TEXT_14',
      ],
      CONTACT_DETAILS_LOADING_TEXT: 'OUTREACH_PAGE_EDITOR_CONTACT_DETAILS_LOADING_TEXT',
      SEND_VIA_LINKEDIN_MENU: {
        TITLE: 'OUTREACH_PAGE_EDITOR_SEND_VIA_LINKEDIN_MENU_TITLE',
        DESCRIPTION: 'OUTREACH_PAGE_EDITOR_SEND_VIA_LINKEDIN_MENU_DESCRIPTION',
        ITEMS: {
          OPEN_LINKEDIN: 'OUTREACH_PAGE_EDITOR_SEND_VIA_LINKEDIN_MENU_ITEMS_OPEN_LINKEDIN',
          MARK_MESSAGE_AS_SENT: 'OUTREACH_PAGE_EDITOR_SEND_VIA_LINKEDIN_MENU_ITEMS_MARK_MESSAGE_AS_SENT',
        },
      },
    },
    EMPTY_CONVERSATION: {
      TITLE_LABEL: 'OUTREACH_PAGE_EMPTY_CONVERSATION_TITLE_LABEL',
      TITLE: 'OUTREACH_PAGE_EMPTY_CONVERSATION_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_EMPTY_CONVERSATION_DESCRIPTION',
    },
    EMPTY_CONVERSATION_NO_INTEGRATION: {
      TITLE_LABEL: 'OUTREACH_PAGE_EMPTY_CONVERSATION_NO_INTEGRATION_TITLE_LABEL',
      TITLE: 'OUTREACH_PAGE_EMPTY_CONVERSATION_NO_INTEGRATION_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_EMPTY_CONVERSATION_NO_INTEGRATION_DESCRIPTION',
      CONNECT_EMAIL_INFO_TEXT: 'OUTREACH_PAGE_EMPTY_CONVERSATION_NO_INTEGRATION_CONNECT_EMAIL_INFO_TEXT',
      CONNECT_EMAIL_BUTTON: 'OUTREACH_PAGE_EMPTY_CONVERSATION_NO_INTEGRATION_CONNECT_EMAIL_BUTTON',
    },
    ARCHIVED_CONVERSATION: {
      DEFAULT: {
        TITLE: 'OUTREACH_PAGE_ARCHIVED_CONVERSATION_DEFAULT_TITLE',
      },
      EMAIL_LESS: {
        TITLE: 'OUTREACH_PAGE_ARCHIVED_CONVERSATION_EMAIL_LESS_TITLE',
        TOOLTIP: 'OUTREACH_PAGE_ARCHIVED_CONVERSATION_EMAIL_LESS_TOOLTIP',
      },
    },
    CONVERSATION_HEADER: {
      ARCHIVED_CONVERSATION: {
        TOOLTIP: 'OUTREACH_PAGE_CONVERSATION_HEADER_ARCHIVED_CONVERSATION_TOOLTIP',
        CONFIRMATION: {
          TITLE: 'OUTREACH_PAGE_CONVERSATION_HEADER_ARCHIVED_CONVERSATION_CONFIRMATION_TITLE',
          DESCRIPTION: 'OUTREACH_PAGE_CONVERSATION_HEADER_ARCHIVED_CONVERSATION_CONFIRMATION_DESCRIPTION',
          CONFIRM_BUTTON_TITLE: 'OUTREACH_PAGE_CONVERSATION_HEADER_ARCHIVED_CONVERSATION_CONFIRMATION_CONFIRM_BUTTON_TITLE',
          CANCEL_BUTTON_TITLE: 'OUTREACH_PAGE_CONVERSATION_HEADER_ARCHIVED_CONVERSATION_CONFIRMATION_CANCEL_BUTTON_TITLE',
        },
      },
      FOR_LABEL: 'CONVERSATION_HEADER_FOR_LABEL',
      SHOW_DETAILS_BUTTON: 'CONVERSATION_HEADER_SHOW_DETAILS_BUTTON',
      TOOLTIPS: {
        ARCHIVE: 'CONVERSATION_HEADER_TOOLTIPS_ARCHIVE',
        LINKEDIN: 'CONVERSATION_HEADER_TOOLTIPS_LINKEDIN',
        SHOW_DETAILS: 'CONVERSATION_HEADER_TOOLTIPS_SHOW_DETAILS',
      },
    },
    SEND_MESSAGE_ERROR: {
      TITLE: 'OUTREACH_PAGE_SEND_MESSAGE_ERROR_TITLE',
      GENERAL_DESCRIPTION: 'OUTREACH_PAGE_SEND_MESSAGE_ERROR_GENERAL_DESCRIPTION',
      EMAIL_VALIDATION_ERROR_DESCRIPTION: 'OUTREACH_PAGE_SEND_MESSAGE_ERROR_EMAIL_VALIDATION_ERROR_DESCRIPTION',
    },
    SEND_MESSAGE_BANNER_ERROR: {
      TITLE: 'OUTREACH_PAGE_SEND_MESSAGE_BANNER_ERROR',
      BOUNCED_TITLE: 'OUTREACH_PAGE_SEND_MESSAGE_BANNER_ERROR_BOUNCED_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_SEND_MESSAGE_BANNER_ERROR_DESCRIPTION',
      BOUNCED_DESCRIPTION: 'OUTREACH_PAGE_SEND_MESSAGE_BANNER_ERROR_BOUNCED_DESCRIPTION',
      BUTTON: 'OUTREACH_PAGE_SEND_MESSAGE_BANNER_ERROR_BUTTON',
    },
    UPGRADE_TO_PRO_BANNER: {
      TITLE: 'OUTREACH_PAGE_UPGRADE_TO_PRO_BANNER_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_UPGRADE_TO_PRO_BANNER_DESCRIPTION',
      BUTTON_TEXT: 'OUTREACH_PAGE_UPGRADE_TO_PRO_BANNER_BUTTON_TEXT',
    },
    CONNECT_EMAIL_BANNER: {
      TITLE: 'OUTREACH_PAGE_CONNECT_EMAIL_BANNER_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_CONNECT_EMAIL_BANNER_DESCRIPTION',
      BUTTON_TEXT: 'OUTREACH_PAGE_CONNECT_EMAIL_BANNER_BUTTON_TEXT',
    },
    RECONNECT_EMAIL_BANNER: {
      TITLE: 'OUTREACH_PAGE_RECONNECT_EMAIL_BANNER_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_RECONNECT_EMAIL_BANNER_DESCRIPTION',
      BUTTON_TEXT: 'OUTREACH_PAGE_RECONNECT_EMAIL_BANNER_BUTTON_TEXT',
    },
    SETUP_GEN_AI_BANNER: {
      TITLE: 'OUTREACH_PAGE_SETUP_GEN_AI_BANNER_TITLE',
      BUTTON_TEXT: 'OUTREACH_PAGE_SETUP_GEN_AI_BANNER_BUTTON_TEXT',
    },
    CAMPAIGN_ON_HOLD: {
      TITLE: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_BANNER_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_BANNER_DESCRIPTION',
      DESCRIPTION_USER_EMAIL_NOT_CONNECTED: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_BANNER_DESCRIPTION_USER_EMAIL_NOT_CONNECTED',
      DESCRIPTION_EMAIL_NOT_CONNECTED: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_BANNER_DESCRIPTION_EMAIL_NOT_CONNECTED',
      DESCRIPTION_INTERNAL_ERROR: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_BANNER_DESCRIPTION_INTERNAL_ERROR',
      BUTTON_TEXT: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_BANNER_BUTTON_TEXT',
      RETRY_BUTTON_TEXT: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_RETRY_BUTTON_TEXT',
      SUPPORT_BUTTON: 'OUTREACH_PAGE_CAMPAIGN_ON_HOLD_SUPPORT_BUTTON',
    },
    SUGGESTED_MESSAGES: {
      DEFAULT: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_DEFAULT',
      SHORT_TO_THE_POINT: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_SHORT_TO_THE_POINT',
      INFORMAL_FRIENDLY: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_INFORMAL_FRIENDLY',
      CAREER_STEP_UP: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_CAREER_STEP_UP',
      INSIDER_NETWORK: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_INSIDER_NETWORK',
      DIVERSITY_INCLUSION: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_DIVERSITY_INCLUSION',
      COMPANY_CULTURE: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_COMPANY_CULTURE',
      DESCRIPTION: 'OUTREACH_PAGE_SUGGESTED_MESSAGES_DESCRIPTION',
    },
    SETUP_GEN_AI_CONVERSATION_BANNER: {
      TITLE: 'OUTREACH_PAGE_SETUP_GEN_AI_CONVERSATION_BANNER_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_SETUP_GEN_AI_CONVERSATION_BANNER_DESCRIPTION',
      BUTTON_TEXT: 'OUTREACH_PAGE_SETUP_GEN_AI_CONVERSATION_BANNER_BUTTON_TEXT',
    },
  },
  OUTREACH_CONTENT_PREFERENCES_PAGE: {
    TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_TITLE',
    UNSAVED_CHANGES: 'OUTREACH_CONTENT_PREFERENCES_PAGE_UNSAVED_CHANGES',
    TONE_OF_VOICE: {
      TITLE: 'OUTREACH_PAGE_TONE_OF_VOICE_TITLE',
      SUBTITLE: 'OUTREACH_PAGE_TONE_OF_VOICE_SUBTITLE',
      DESCRIPTION: 'OUTREACH_PAGE_TONE_OF_VOICE_DESCRIPTION',
      SELECTION_LABEL: 'OUTREACH_PAGE_TONE_OF_VOICE_SELECTION_LABEL',
    },
    AGENCY_PREFERENCES: {
      TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_TITLE',
      INPUTS: {
        AGENCY_MODE_TOGGLE: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_AGENCY_MODE_TOGGLE_TITLE',
        },
        AGENCY_NAME: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_AGENCY_NAME_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_AGENCY_NAME_DESCRIPTION',
          PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_AGENCY_NAME_PLACEHOLDER',
        },
        ABOUT_AGENCY: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_ABOUT_AGENCY_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_ABOUT_AGENCY_DESCRIPTION',
        },
        HIRING_COMPANY_NAME: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_HIRING_COMPANY_NAME_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_HIRING_COMPANY_NAME_DESCRIPTION',
          PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_HIRING_COMPANY_NAME_PLACEHOLDER',
        },
        HIRING_COMPANY_NAME_CONFIDENTIAL: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_HIRING_COMPANY_NAME_CONFIDENTIAL_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_HIRING_COMPANY_NAME_CONFIDENTIAL_DESCRIPTION',
        },
        ABOUT_HIRING_COMPANY: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_ABOUT_HIRING_COMPANY_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AGENCY_PREFERENCES_INPUTS_ABOUT_HIRING_COMPANY_DESCRIPTION',
        },
      },
    },
    AI_INSTRUCTIONS: {
      TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AI_INSTRUCTIONS_TITLE',
      INPUT_TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AI_INSTRUCTIONS_INPUT_TITLE',
      INPUT_DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AI_INSTRUCTIONS_INPUT_DESCRIPTION',
      INPUT_PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_AI_INSTRUCTIONS_INPUT_PLACEHOLDER',
    },
    MESSAGE_CONTENT: {
      PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MESSAGE_CONTENT_PLACEHOLDER',
      AUTO_FILL_BUTTON: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MESSAGE_CONTENT_AUTO_FILL_BUTTON',
    },
    MORE_OPTIONS: {
      TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_TITLE',
      INPUTS: {
        MESSAGE_LENGTH: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_MESSAGE_LENGTH_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_MESSAGE_LENGTH_DESCRIPTION',
          PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_MESSAGE_LENGTH_PLACEHOLDER',
          INPUT_LABEL: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_MESSAGE_LENGTH_INPUT_LABEL',
        },
        INCLUDE_EMAIL_SIGNATURE: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_INCLUDE_EMAIL_SIGNATURE_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_INCLUDE_EMAIL_SIGNATURE_DESCRIPTION',
          ENABLE_READ_PERMISSIONS_BANNER_TEXT: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_INCLUDE_EMAIL_SIGNATURE_ENABLE_READ_PERMISSIONS_BANNER_TEXT',
          PREVIEW_SIGNATURE_BUTTON: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_INCLUDE_EMAIL_SIGNATURE_PREVIEW_SIGNATURE_BUTTON',
        },
        TRACK_EMAIL_OPENS: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_TRACK_EMAIL_OPENS_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_TRACK_EMAIL_OPENS_DESCRIPTION',
          BANNER_TEXT: 'OUTREACH_CONTENT_PREFERENCES_PAGE_MORE_OPTIONS_INPUTS_TRACK_EMAIL_OPENS_BANNER_TEXT',
        },
      },
    },
    ROLE: {
      TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_TITLE',
      INPUTS: {
        ROLE_NAME: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ROLE_NAME_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ROLE_NAME_DESCRIPTION',
          PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ROLE_NAME_PLACEHOLDER',
        },
        ABOUT_ROLE: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ABOUT_ROLE_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ABOUT_ROLE_DESCRIPTION',
        },
        ROLE_LOCATION: {
          TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ROLE_LOCATION_TITLE',
          DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ROLE_LOCATION_DESCRIPTION',
          PLACEHOLDER: 'OUTREACH_CONTENT_PREFERENCES_PAGE_ROLE_INPUTS_ROLE_LOCATION_PLACEHOLDER',
        },
      },
    },
    MESSAGE_PREVIEW: {
      TITLE: 'OUTREACH_PAGE_MESSAGE_PREVIEW_TITLE',
      REGENERATE_BUTTON: 'OUTREACH_PAGE_MESSAGE_PREVIEW_REGENERATE_BUTTON',
      GENERATE_BUTTON: 'OUTREACH_PAGE_MESSAGE_PREVIEW_GENERATE_BUTTON',
      GENERATE_BUTTON_LOADING: 'OUTREACH_PAGE_MESSAGE_PREVIEW_GENERATE_BUTTON_LOADING',
      BANNER_TEXT: 'OUTREACH_PAGE_MESSAGE_PREVIEW_BANNER_TEXT',
      SHOW_PREVIEW_BUTTON: 'OUTREACH_PAGE_MESSAGE_PREVIEW_SHOW_PREVIEW_BUTTON',
      LOADING_TEXT: [
        'OUTREACH_PAGE_MESSAGE_PREVIEW_LOADING_TEXT_0',
        'OUTREACH_PAGE_MESSAGE_PREVIEW_LOADING_TEXT_1',
        'OUTREACH_PAGE_MESSAGE_PREVIEW_LOADING_TEXT_2',
        'OUTREACH_PAGE_MESSAGE_PREVIEW_LOADING_TEXT_3',
        'OUTREACH_PAGE_MESSAGE_PREVIEW_LOADING_TEXT_4',
        'OUTREACH_PAGE_MESSAGE_PREVIEW_LOADING_TEXT_5',
      ],
    },
    MESSAGE_SPICES: {
      TITLE: 'OUTREACH_PAGE_MESSAGE_SPICES_TITLE',
      DESCRIPTION: 'OUTREACH_PAGE_MESSAGE_SPICES_DESCRIPTION',
      ITEMS_TITLE: {
        INSIDER_NETWORK: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_TITLE_INSIDER_NETWORK',
        LIKELYHOOD_TO_MOVE: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_TITLE_LIKELYHOOD_TO_MOVE',
        RELEVANT_SKILLS: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_TITLE_RELEVANT_SKILLS',
        RELEVANT_EDUCATION: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_TITLE_RELEVANT_EDUCATION',
        SIMILAR_COMPANIES: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_TITLE_SIMILAR_COMPANIES',
      },
      ITEMS_DESCRIPTION: {
        INSIDER_NETWORK: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_DESCRIPTION_INSIDER_NETWORK',
        LIKELYHOOD_TO_MOVE: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_DESCRIPTION_LIKELYHOOD_TO_MOVE',
        RELEVANT_SKILLS: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_DESCRIPTION_RELEVANT_SKILLS',
        RELEVANT_EDUCATION: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_DESCRIPTION_RELEVANT_EDUCATION',
        SIMILAR_COMPANIES: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_DESCRIPTION_SIMILAR_COMPANIES',
      },
      ITEMS_MESSAGES: {
        INSIDER_NETWORK: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_MESSAGES_INSIDER_NETWORK',
        LIKELYHOOD_TO_MOVE: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_MESSAGES_LIKELYHOOD_TO_MOVE',
        RELEVANT_SKILLS: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_MESSAGES_RELEVANT_SKILLS',
        RELEVANT_EDUCATION: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_MESSAGES_RELEVANT_EDUCATION',
        SIMILAR_COMPANIES: 'OUTREACH_PAGE_MESSAGE_CONTENT_ITEMS_MESSAGES_SIMILAR_COMPANIES',
      },
    },
    GEN_AI_ACTIVATION_BANNER: {
      LABEL: 'OUTREACH_CONTENT_PREFERENCES_PAGE_GEN_AI_ACTIVATION_BANNER_LABEL',
      TITLE: 'OUTREACH_CONTENT_PREFERENCES_PAGE_GEN_AI_ACTIVATION_BANNER_TITLE',
      DESCRIPTION: 'OUTREACH_CONTENT_PREFERENCES_PAGE_GEN_AI_ACTIVATION_BANNER_DESCRIPTION',
    },
  },
  CAMPAIGN_PAGE: {
    TITLE: 'CAMPAIGN_PAGE_TITLE',
    MODES_SECTION: {
      TITLE: 'CAMPAIGN_PAGE_MODES_SECTION_TITLE',
      DESCRIPTION: 'CAMPAIGN_PAGE_MODES_SECTION_DESCRIPTION',
      BUTTONS: {
        ACTIVE_BUTTON_INDICATION: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_ACTIVE_BUTTON_INDICATION',
        MANUAL: {
          TITLE: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_MANUAL_TITLE',
          SUBTITLE: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_MANUAL_SUBTITLE',
          INFO_TEXT: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_MANUAL_INFO_TEXT',
        },
        CUSTOM: {
          TITLE: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_CUSTOM_TITLE',
          SUBTITLE: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_CUSTOM_SUBTITLE',
          BADGE_TEXT: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_CUSTOM_BADGE_TEXT',
          INFO_TEXT: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_CUSTOM_INFO_TEXT',
        },
        AUTO: {
          TITLE: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_AUTO_TITLE',
          SUBTITLE: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_AUTO_SUBTITLE',
          INFO_TEXT: 'CAMPAIGN_PAGE_MODES_SECTION_BUTTONS_AUTO_INFO_TEXT',
        },
      },
    },
    CUSTOM_MODE_DIALOG: {
      TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_TITLE',
      DESCRIPTION: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_DESCRIPTION',
      ADD_STEP_BUTTON: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_ADD_STEP_BUTTON',
      SAVE_STEPS_BUTTON: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_SAVE_STEPS_BUTTON',
      REMOVE_STEP_BUTTON: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_REMOVE_STEP_BUTTON',
      FIRST_STEP_MESSAGE_AS_SOON_AS_POSSIBLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_FIRST_STEP_MESSAGE_AS_SOON_AS_POSSIBLE',
      FIRST_STEP_MESSAGE_NEXT_BUSINESS_DAY: 'CAMPAIGN_PAGE_CUSTOM_MODE_DIALOG_FIRST_STEP_MESSAGE_NEXT_BUSINESS_DAY',
      STEPS: {
        TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_TITLE',
        FIRST_DESCRIPTION: [
          'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_FIRST_DESCRIPTION_0',
          'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_FIRST_DESCRIPTION_1',
          'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_FIRST_DESCRIPTION_2',
        ],
        FOLLOW_UP_DESCRIPTION: [
          'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_FOLLOW_UP_DESCRIPTION_0',
          'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_FOLLOW_UP_DESCRIPTION_1',
          'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_FOLLOW_UP_DESCRIPTION_2',
        ],
        VALIDATION_ERROR: {
          TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_EDITOR_VALIDATION_ERROR_TITLE',
          DESCRIPTION: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_EDITOR_VALIDATION_ERROR_DESCRIPTION',
        },
        SELECT_METHOD: {
          NEW: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_METHOD_NEW',
          EMAIL: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_METHOD_EMAIL',
          PHONE: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_METHOD_PHONE',
          LINKEDIN_MANUAL: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_METHOD_LINKEDIN_MANUAL',
          SMS: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_TIME_SMS',
        },
        SELECT_TIME_PLACEHOLDER: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_TIME_PLACEHOLDER',
        SELECT_DAYS_PLACEHOLDER: 'CAMPAIGN_PAGE_CUSTOM_MODE_SELECT_DAYS_PLACEHOLDER',
        EDITOR: {
          SWITCH_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_EDITOR_SWITCH_LABEL',
          SUBJECT_AS_REPLY: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_EDITOR_SUBJECT_AS_REPLY',
        },
        SELECTION_POPUP: {
          CONFIRM_BUTTON: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_CONFIRM_BUTTON',
          SELECT_TYPE: {
            TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_TITLE',
            DESCRIPTION: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_DESCRIPTION',
            EMAIL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_EMAIL',
            LINKEDIN_MANUAL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_LINKEDIN_MANUAL',
            SMS: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_SMS',
            PHONE: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_PHONE',
            AUTOMATIC_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_AUTOMATIC_LABEL',
            MANUAL_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TYPE_MANUAL_LABEL',
          },
          SELECT_DAYS: {
            TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_DAYS_TITLE',
            DESCRIPTION: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_DAYS_DESCRIPTION',
            INPUT_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_DAYS_INPUT_LABEL',
            TOGGLE_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_DAYS_TOGGLE_LABEL',
          },
          SELECT_TIME: {
            LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TIME_LABEL',
            TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TIME_TITLE',
            DESCRIPTION: 'CAMPAIGN_PAGE_CUSTOM_MODE_STEPS_SELECTION_POPUP_SELECT_TIME_DESCRIPTION',
          },
        },
      },
    },
    CUSTOM_MODE_OPTIONS: {
      TITLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_TITLE',
      DESCRIPTION: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_DESCRIPTION',
      EDIT_BUTTON: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_EDIT_BUTTON',
      STEP_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_STEP_LABEL',
      FIRST_STEP_NEXT_BUSINESS_DAY: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_FIRST_STEP_NEXT_BUSINESS_DAY',
      FIRST_STEP_AS_SOON_AS_POSSIBLE: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_FIRST_STEP_AS_SOON_AS_POSSIBLE',
      N_STEP: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_N_STEP',
      MANUEL_LABEL: 'CAMPAIGN_PAGE_CUSTOM_MODE_OPTIONS_MANUEL_LABEL',
      MANUEL_LABEL_INFO: 'CAMPAIGN_PAGE_CUSTOM_MODE_MANUEL_LABEL_INFO',
    },
    AUTO_MODE_OPTIONS: {
      TITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_TITLE',
      SWITCH: {
        AUTO: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SWITCH_AUTO',
        CUSTOM: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SWITCH_CUSTOM',
      },
      EMAIL_SENDER: {
        TITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_EMAIL_SENDER_TITLE',
        DESCRIPTION: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_EMAIL_SENDER_DESCRIPTION',
        PRIMARY_SENDER_SELECT_PLACEHOLDER: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_EMAIL_SENDER_PRIMARY_SENDER_SELECT_PLACEHOLDER',
        PRIMARY_SENDER_SELECT_LABEL: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_EMAIL_SENDER_PRIMARY_SENDER_SELECT_LABEL',
        PRIMARY_SENDER_SELECT_MENU_TITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_EMAIL_SENDER_PRIMARY_SENDER_SELECT_MENU_TITLE',
      },
      SEND_TIME: {
        TITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SEND_TIME_TITLE',
        DESCRIPTION: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SEND_TIME_DESCRIPTION',
        INFO_TEXT: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SEND_TIME_INFO_TEXT',
        DAY_TYPE_SELECT_PLACEHOLDER: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SEND_TIME_DAY_TYPE_SELECT_PLACEHOLDER',
        DAY_TYPE: {
          BUSINESS_DAY: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SEND_TIME_DAY_TYPE_BUSINESS_DAY',
          ANY_DAY: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_SEND_TIME_DAY_TYPE_ANY_DAY',
        },
      },
      FOLLOWUPS_NUMBER: {
        TITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_FOLLOWUPS_NUMBER_TITLE',
        DESCRIPTION: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_FOLLOWUPS_NUMBER_DESCRIPTION',
        INFO_TEXT: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_FOLLOWUPS_NUMBER_INFO_TEXT',
      },
    },
    MESSAGING_TYPE_SECTION: {
      TITLE: 'CAMPAIGN_PAGE_MESSAGING_TYPE_SECTION_TITLE',
      DESCRIPTION: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_MESSAGING_TYPE_SECTION_DESCRIPTION',
      PLACEHOLDER: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_MESSAGING_TYPE_SECTION_PLACEHOLDER',
      MENU: {
        TITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_MESSAGING_TYPE_SECTION_MENU_TITLE',
        SUBTITLE: 'CAMPAIGN_PAGE_AUTO_MODE_OPTIONS_MESSAGING_TYPE_SECTION_MENU_SUBTITLE',
      },
    },
    AUTO_ARCHIVE_CONVERSATION: {
      TITLE: 'CAMPAIGN_PAGE_AUTO_ARCHIVE_CONVERSATION_TITLE',
      DESCRIPTION: 'CAMPAIGN_PAGE_AUTO_ARCHIVE_CONVERSATION_DESCRIPTION',
      SWITCH: {
        ACTIVE: 'CAMPAIGN_PAGE_AUTO_ARCHIVE_CONVERSATION_ACTIVE',
        INACTIVE: 'CAMPAIGN_PAGE_AUTO_ARCHIVE_CONVERSATION_INACTIVE',
      },
    },
    FASTER_FOLLOWUPS: {
      TITLE: 'CAMPAIGN_PAGE_FASTER_FOLLOWUPS_TITLE',
      DESCRIPTION: 'CAMPAIGN_PAGE_FASTER_FOLLOWUPS_DESCRIPTION',
      SWITCH: {
        ACTIVE: 'CAMPAIGN_PAGE_FASTER_FOLLOWUPS_ACTIVE',
        INACTIVE: 'CAMPAIGN_PAGE_FASTER_FOLLOWUPS_INACTIVE',
      },
    },
    DISCONNECT_EMAIL: {
      DESCRIPTION: 'CAMPAIGN_PAGE_DISCONNECT_EMAIL_DESCRIPTION',
      LINK: 'CAMPAIGN_PAGE_DISCONNECT_EMAIL_LINK',
      CONTACT_TO_DISCONNECT: 'CAMPAIGN_PAGE_DISCONNECT_EMAIL_CONTACT_TO_DISCONNECT',
      DIALOG: {
        TITLE: 'CAMPAIGN_PAGE_DISCONNECT_DIALOG_TITLE',
        DESCRIPTION: 'CAMPAIGN_PAGE_DISCONNECT_DIALOG_DESCRIPTION',
        CONFIRM_DISCONNECT: 'CAMPAIGN_PAGE_DISCONNECT_DIALOG_CONFIRM_DISCONNECT',
        KEEP_CONNECTED: 'CAMPAIGN_PAGE_DISCONNECT_DIALOG_KEEP_CONNECTED',
      },
    },
  },
  OUTREACH_INITIALIZATION_DIALOG: {
    TITLE: 'OUTREACH_INITIALIZATION_DIALOG_TITLE',
    DESCRIPTION: 'OUTREACH_INITIALIZATION_DIALOG_DESCRIPTION',
    BROWSER_COMPATIBILITY: 'OUTREACH_INITIALIZATION_DIALOG_BROWSER_COMPATIBILITY',
    GENERAL_ERROR_MESSAGE: 'OUTREACH_INITIALIZATION_DIALOG_GENERAL_ERROR_MESSAGE',
    PERMISSIONS_ERROR_MESSAGE: 'OUTREACH_INITIALIZATION_DIALOG_PERMISSIONS_ERROR_MESSAGE',
    READ_PERMISSIONS_INFO_TEXT: 'OUTREACH_INITIALIZATION_DIALOG_READ_PERMISSIONS_INFO_TEXT',
  },
  OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG: {
    HEADLINE: 'OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG_HEADLINE',
    TITLE: 'OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG_TITLE',
    DESCRIPTION: 'OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG_DESCRIPTION',
    ACTION_BUTTON_TEXT: 'OUTREACH_EMAIL_CONNECTED_SUCCESSFULLY_DIALOG_ACTION_BUTTON_TEXT',
  },
  MATCHING_PROGRESS_PAGE: {
    TITLE: {
      MAKING: 'MATCHING_PROGRESS_PAGE_TITLE_MAKING',
      ADJUSTMENT: 'MATCHING_PROGRESS_PAGE_TITLE_ADJUSTMENT',
      STILL_WORKING: 'MATCHING_PROGRESS_PAGE_TITLE_STILL_WORKING',
      LONGER_THAN_EXPECTED: 'MATCHING_PROGRESS_PAGE_TITLE_LONGER_THAN_EXPECTED',
    },
    SUBTITLE: {
      ANALYZING: 'MATCHING_PROGRESS_PAGE_SUBTITLE_ANALYZING',
      MAKING_SURE: 'MATCHING_PROGRESS_PAGE_SUBTITLE_CHECKING',
    },
    DESCRIPTION: {
      ANALYZING: 'MATCHING_PROGRESS_PAGE_DESCRIPTION_ANALYZING',
      ADJUSTMENT: 'MATCHING_PROGRESS_PAGE_DESCRIPTION_ADJUSTMENT',
      MAKING_SURE: 'MATCHING_PROGRESS_PAGE_DESCRIPTION_MAKING_SURE',
      CLOSER_LOOK: 'MATCHING_PROGRESS_PAGE_DESCRIPTION_LOOK',
    },
    MATCHING_PROGRESS: {
      TIME_LEFT: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_TIME_LEFT',
      FINALIZING: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_FINALIZING',
      CHECK_LATER: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_CHECK_LATER',
    },
    MATCHING_STEPS: {
      FINDING: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_FINDING',
      ANALYZING: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_ANALYZING',
      LEARNING: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_LEARNING',
      EXTRACTING: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_EXTRACTING',
      CHERRY_PICKING: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_CHERRY_PICKING',
      ALMOST_THERE: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_ALMOST_THERE',
      THANKS: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_THANKS',
      TOO_LONG: {
        NOTIFY: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_TOO_LONG_NOTIFY',
        NEED_HELP: 'MATCHING_PROGRESS_PAGE_MATCHING_STEPS_TOO_LONG_NEED_HELP',
      },
    },
  },
  AUTOMATING_CAMPAIGN_DIALOG: {
    TITLE: 'AUTOMATING_CAMPAIGN_DIALOG_TITLE',
    DESCRIPTION: 'AUTOMATING_CAMPAIGN_DIALOG_DESCRIPTION',
  },
  COMPANY_SIZE_LABEL: 'COMPANY_SIZE_LABEL',
  COMPANY_SIZE: {
    ['SMALL' as string]: 'COMPANY_SIZE_SMALL',
    ['MEDIUM' as string]: 'COMPANY_SIZE_MEDIUM',
    ['LARGE' as string]: 'COMPANY_SIZE_LARGE',
    ['VERY_LARGE' as string]: 'COMPANY_SIZE_VERY_LARGE',
  },
  LEVEL_OF_EXPERIENCE: {
    ['ENTRY_LEVEL' as string]: 'LEVEL_OF_EXPERIENCE_ENTRY_LEVEL',
    ['STARTING_OUT' as string]: 'LEVEL_OF_EXPERIENCE_STARTING_OUT',
    ['SOMEWHAT_EXPERIENCED' as string]: 'LEVEL_OF_EXPERIENCE_SOMEWHAT_EXPERIENCED',
    ['HIGHLY_EXPERIENCED' as string]: 'LEVEL_OF_EXPERIENCE_HIGHLY_EXPERIENCED',
    ['INCREDIBLY_EXPERIENCED' as string]: 'LEVEL_OF_EXPERIENCE_INCREDIBLY_EXPERIENCED',
  },
  MATCH_CARD: {
    ENGAGE_BUTTON_TEXT: 'MATCH_CARD_ENGAGE_BUTTON_TEXT',
    EDUCATION_LABEL: 'MATCH_CARD_EDUCATION_TIMELINE_LABEL',
    EXPERIENCE: {
      LABEL: 'MATCH_CARD_EXPERIENCE_LABEL',
      SKILL_TOOLTIP: 'MATCH_CARD_EXPERIENCE_SKILL_TOOLTIP',
      SKILL_ASKED_BY_USER_TOOLTIP: 'MATCH_CARD_EXPERIENCE_SKILL_ASKED_BY_USER_TOOLTIP',
      INDUSTRY_TOOLTIP: 'MATCH_CARD_EXPERIENCE_INDUSTRY_TOOLTIP',
      COMPANY_SIZE_TOOLTIP: 'MATCH_CARD_EXPERIENCE_COMPANY_SIZE_TOOLTIP',
      MORE_TAGS_TOOLTIP: 'MATCH_CARD_EXPERIENCE_MORE_TAGS_TOOLTIP',
    },
    SKILLS: {
      TALENT_PROFILE_SOURCE_TOOLTIP_TEXT: 'MATCH_CARD_SKILLS_TALENT_PROFILE_SOURCE_TOOLTIP_TEXT',
      INFERRED_SOURCE_TOOLTIP_TEXT: 'MATCH_CARD_SKILLS_INFERRED_SOURCE_TOOLTIP_TEXT',
      COMPANY_INSIGHT_TOOLTIP_TEXT: 'MATCH_CARD_SKILLS_COMPANY_INSIGHT_TOOLTIP_TEXT',
    },
    ATS_INDICATION: {
      CANDIDATE_IN_ATS: 'MATCH_CARD_CANDIDATE_IN_ATS',
      PREVIOUSLY_INTERACTED: 'MATCH_CARD_PREVIOUSLY_INTERACTED',
      ENGAGED_ELSEWHERE: 'MATCH_CARD_ENGAGED_ELSEWHERE',
    },
    EXPLAINABILITY: {
      EXACT_SKILL: 'MATCH_CARD_EXPLAINABILITY_EXACT_SKILL',
      SIMILAR_SKILL: 'MATCH_CARD_EXPLAINABILITY_SIMILAR_SKILL',
      EXACT_SKILL_MORE_INFO_TOOLTIP_TEXT: 'MATCH_CARD_EXPLAINABILITY_EXACT_SKILL_MORE_INFO_TOOLTIP_TEXT',
      SIMILAR_SKILL_MORE_INFO_TOOLTIP_TEXT: 'MATCH_CARD_EXPLAINABILITY_SIMILAR_SKILL_MORE_INFO_TOOLTIP_TEXT',
      EXACT_INDUSTRY: 'MATCH_CARD_EXPLAINABILITY_EXACT_INDUSTRY',
      SIMILAR_INDUSTRY: 'MATCH_CARD_EXPLAINABILITY_SIMILAR_INDUSTRY',
      EXACT_INDUSTRY_MORE_INFO_TOOLTIP_TEXT: 'MATCH_CARD_EXPLAINABILITY_EXACT_INDUSTRY_MORE_INFO_TOOLTIP_TEXT',
      COMPANY_SIZE_MORE_INFO_TOOLTIP_TEXT: 'MATCH_CARD_EXPLAINABILITY_COMPANY_SIZE_MORE_INFO_TOOLTIP_TEXT',
      SIMILAR_INDUSTRY_MORE_INFO_TOOLTIP_TEXT: 'MATCH_CARD_EXPLAINABILITY_SIMILAR_INDUSTRY_MORE_INFO_TOOLTIP_TEXT',
      VERIFIED_FROM_COMPANY_PROFILE_TEXT: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_COMPANY_PROFILE_TEXT',
      VERIFIED_FROM_COMPANY_DESCRIPTION: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_COMPANY_DESCRIPTION',
      VERIFIED_FROM_COMPANY_INDUSTRIES: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_COMPANY_INDUSTRIES',
      VERIFIED_FROM_TALENT_SPECIFIC_ROLE_DESCRIPTION_TEXT: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_TALENT_SPECIFIC_ROLE_DESCRIPTION_TEXT',
      VERIFIED_FROM_TALENT_SPECIFIC_ROLE_SKILLS_TEXT: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_TALENT_SPECIFIC_ROLE_SKILLS_TEXT',
      VERIFIED_FROM_TALENT_GENERAL_PROFILE_DESCRIPTION_TEXT: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_TALENT_GENERAL_PROFILE_DESCRIPTION_TEXT',
      VERIFIED_FROM_TALENT_GENERAL_PROFILE_SKILLS_TEXT: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFIED_FROM_TALENT_GENERAL_PROFILE_SKILLS_TEXT',
      INFO_MENU: {
        SELF_EVIDENCE_SECTION_TITLE: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_SELF_EVIDENCE_SECTION_TITLE',
        RELEVANCE_EXPLANATION_SECTION_TITLE: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_RELEVANCE_EXPLANATION_SECTION_TITLE',
        VERIFICATION_SOURCE_SECTION_TITLE: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_VERIFICATION_SOURCE_SECTION_TITLE',
        IS_SIMILAR_TO: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_IS_SIMILAR_TO',
        SKILL_VERIFIED: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_SKILL_VERIFIED',
        INDUSTRY_VERIFIED: 'MATCH_CARD_EXPLAINABILITY_INFO_MENU_INDUSTRY_VERIFIED',
      },
    },
    EXPLAINABILITY_WIDGET: {
      LABEL: 'MATCH_CARD_EXPLAINABILITY_WIDGET_LABEL',
      ITEMS_LABELS: {
        LOCATION: 'MATCH_CARD_EXPLAINABILITY_ITEMS_LABELS_LOCATION',
        LEVEL_OF_EXPERIENCE: 'MATCH_CARD_EXPLAINABILITY_ITEMS_LABELS_LEVEL_OF_EXPERIENCE',
        SKILLS: 'MATCH_CARD_EXPLAINABILITY_ITEMS_LABELS_SKILLS',
        COMPANY_BACKGROUND: 'MATCH_CARD_EXPLAINABILITY_ITEMS_LABELS_COMPANY_BACKGROUND',
        COMPANY_SIZE: 'MATCH_CARD_EXPLAINABILITY_ITEMS_LABELS_COMPANY_SIZE',
        EDUCATION: 'MATCH_CARD_EXPLAINABILITY_ITEMS_LABELS_EDUCATION',
      },
    },
  },
  POSITION_SELECTION_GROUP: {
    MATCH_TITLE: 'POSITION_SELECTION_GROUP_MATCH_TITLE',
    OVERVIEW_TITLE: 'POSITION_SELECTION_GROUP_OVERVIEW_TITLE',
    OUTREACH_TITLE: 'POSITION_SELECTION_GROUP_OUTREACH_TITLE',
  },
  MATCH_WIDGETS: {
    BADGE_TITLE: 'MATCH_WIDGETS_BADGE_TITLE',
    ACADEMIC_EXCELLENCE: {
      TITLE: 'MATCH_WIDGETS_ACADEMIC_EXCELLENCE_TITLE',
      DESCRIPTION: 'MATCH_WIDGETS_ACADEMIC_EXCELLENCE_DESCRIPTION',
    },
    EXPERIENCE_BY_INDUSTRY: {
      TITLE: 'MATCH_WIDGETS_EXPERIENCE_BY_INDUSTRY_TITLE',
    },
    LIKELIHOOD_TO_MOVE: {
      TITLE: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_TITLE',
      DESCRIPTION: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_DESCRIPTION',
      LEVEL_LABEL: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_LEVEL_LABEL',
      LEVEL: {
        LOW: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_LEVEL_LOW',
        MEDIUM: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_LEVEL_MEDIUM',
        HIGH: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_LEVEL_HIGH',
      },
      CHILD_WIDGETS: {
        PERSONAL_TENURE_COMPARISON: {
          DESCRIPTION: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_CHILD_WIDGETS_PERSONAL_TENURE_COMPARISON_DESCRIPTION',
        },
        LAYOFF_TALENT: {
          DESCRIPTION: 'MATCH_WIDGETS_LIKELIHOOD_TO_MOVE_CHILD_WIDGETS_LAYOFF_TALENT_DESCRIPTION',
        },
      },
    },
    SKILLS: {
      TITLE: 'MATCH_WIDGETS_SKILLS_TITLE',
    },
    TALENT_NETWORK: {
      TITLE: 'MATCH_WIDGETS_TALENT_NETWORK_TITLE',
    },
  },
  ENGAGEMENT_FEEDBACK: {
    THANKS: 'THANKS',
    GOT_YOU: 'GOT_YOU',
    CHEERS: 'CHEERS',
    SUPER: 'SUPER',
    AWESOME: 'AWESOME',
    LOVE_IT: 'LOVE_IT',
  },
  POSITION_OVERVIEW_PAGE: {
    POSITION_ASSISTANT: {
      LABEL: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_CARD_LABEL',
      NEXT_BUTTON: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_CARD_NEXT_BUTTON',
      DISMISS_BUTTON: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_CARD_DISMISS_BUTTON',
      EMPTY_ASSISTANT: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_EMPTY_ASSISTANT_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_EMPTY_ASSISTANT_DESCRIPTION',
      },
      ACTIVATED_AUTOMATED_OUTREACH: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ACTIVATED_AUTOMATED_OUTREACH_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ACTIVATED_AUTOMATED_OUTREACH_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ACTIVATED_AUTOMATED_OUTREACH_ACTION_TITLE',
      },
      ATS_INTEGRATION: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ATS_INTEGRATION_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ATS_INTEGRATION_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ATS_INTEGRATION_ACTION_TITLE',
      },
      PROMPT_FOLLOW_UP: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_PROMPT_FOLLOW_UP_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_PROMPT_FOLLOW_UP_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_PROMPT_FOLLOW_UP_ACTION_TITLE',
      },
      ENCOURAGE_TIMELY_REPLIES: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ENCOURAGE_TIMELY_REPLIES_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ENCOURAGE_TIMELY_REPLIES_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_ENCOURAGE_TIMELY_REPLIES_ACTION_TITLE',
      },
      OUTREACH_TO_LIKED_MATCHES: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_OUTREACH_TO_LIKED_MATCHES_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_OUTREACH_TO_LIKED_MATCHES_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_OUTREACH_TO_LIKED_MATCHES_ACTION_TITLE',
      },
      MILESTONE_CELEBRATION: {
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_ACTION_TITLE',
        FIRST_REPLAY: {
          TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_FIRST_REPLAY_TITLE',
          DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_FIRST_REPLAY_DESCRIPTION',
        },
        REVIEWED_100_MATCHES: {
          TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_REVIEWED_100_MATCHES_TITLE',
          DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_REVIEWED_100_MATCHES_DESCRIPTION',
        },
        ADDED_50_TO_OUTREACH: {
          TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_ADDED_50_TO_OUTREACH_TITLE',
          DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_MILESTONE_CELEBRATION_ADDED_50_TO_OUTREACH_DESCRIPTION',
        },
      },
      IMPROVE_OUTREACH_ENGAGEMENT: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_IMPROVE_OUTREACH_ENGAGEMENT_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_IMPROVE_OUTREACH_ENGAGEMENT_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_IMPROVE_OUTREACH_ENGAGEMENT_ACTION_TITLE',
      },
      BUILD_STRONGER_CANDIDATE_PIPELINE: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_BUILD_STRONGER_CANDIDATE_PIPELINE_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_BUILD_STRONGER_CANDIDATE_PIPELINE_DESCRIPTION',
        ACTION_TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_ASSISTANT_BUILD_STRONGER_CANDIDATE_PIPELINE_ACTION_TITLE',
      },
    },
    ATS: {
      CONNECT_TO_ATS_TEXT: 'POSITION_OVERVIEW_PAGE_ATS_CONNECT_TO_ATS_TEXT',
      SYNCED_TEXT: 'POSITION_OVERVIEW_PAGE_ATS_SYNCED_TEXT',
    },
    POSITION_REALIZATION_METRICS: {
      EFFORT_INVESTED: {
        LABEL: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_EFFORT_INVESTED_LABEL',
        TITLE: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_EFFORT_INVESTED_TITLE',
        CALCULATING_TITLE: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_EFFORT_INVESTED_CALCULATING_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_EFFORT_INVESTED_DESCRIPTION',
        NEGATIVE_TIME_DESCRIPTION: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_EFFORT_INVESTED_NEGATIVE_TIME_DESCRIPTION',
        TOOLTIP_TEXT: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_EFFORT_INVESTED_TOOLTIP_TEXT',
      },
      TALENT_RARITY: {
        LABEL: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_TALENT_RARITY_LABEL',
        TITLE: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_TALENT_RARITY_TITLE',
        DESCRIPTION: 'POSITION_OVERVIEW_PAGE_REALIZATION_METRICS_TALENT_RARITY_DESCRIPTION',
      },
    },
    POSITION_STATISTICS_CARDS: {
      LABEL: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_LABEL',
      TO_REVIEW: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_TITLE',
        INFO_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_INFO_TEXT',
        INFO_TEXT_DAILY_LIMIT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_INFO_TEXT_DAILY_LIMIT',
        INFO_TEXT_MATCHING_PROGRESS: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_INFO_TEXT_MATCHING_PROGRESS',
        INFO_TEXT_NO_MATCHES_FOUND: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_INFO_TEXT_NO_MATCHES_FOUND',
        ACTION_BUTTON_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_ACTION_BUTTON_TEXT',
      },
      TO_REACHOUT: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REACHOUT_TITLE',
        INFO_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REACHOUT_INFO_TEXT',
        ACTION_BUTTON_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REACHOUT_ACTION_BUTTON_TEXT',
      },
      TO_REPLY: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REPLY_TITLE',
        INFO_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REPLY_INFO_TEXT',
        ACTION_BUTTON_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_STATISTICS_CARDS_TO_REPLY_ACTION_BUTTON_TEXT',
      },
    },
    POSITION_PERFORMANCE_FUNNEL: {
      TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_TITLE',
      OPTIMAL_INFO_LABEL: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_OPTIMAL_INFO_LABEL',
      ACTUAL_INFO_LABEL: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_ACTUAL_INFO_LABEL',
      PEOPLE_MATCHED: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_PEOPLE_MATCHED_TITLE',
        SUBTITLE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_PEOPLE_MATCHED_SUBTITLE',
        TAGS: {
          OUT_OF_COMPANIES: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_PEOPLE_MATCHED_TAGS_OUT_OF_COMPANIES',
          GREAT_MIX: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_PEOPLE_MATCHED_TAGS_GREAT_MIX',
        },
      },
      LIKED: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_LIKED_TITLE',
        TAGS: {
          OPTIMAL_RESULT: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_LIKED_TAGS_OPTIMAL_RESULT',
          KEEP_IT_OUT: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_LIKED_TAGS_KEEP_IT_OUT',
          GREAT_START: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_LIKED_TAGS_GREAT_START',
          EXCELLENT_PIPELINE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_LIKED_TAGS_EXCELLENT_PIPELINE',
          AMAZING: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_LIKED_TAGS_AMAZING',
        },
      },
      CONTACTED: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_CONTACTED_TITLE',
        TAGS: {
          FOLLOWUPS: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_CONTACTED_TAGS_FOLLOWUPS',
          WELL_DONE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_CONTACTED_TAGS_WELL_DONE',
          HIGH_OPEN_RATE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_CONTACTED_TAGS_HIGH_OPEN_RATE',
          OPEN_RATE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_CONTACTED_TAGS_OPEN_RATE',
          SENT: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_CONTACTED_TAGS_SENT',
        },
      },
      REPLIED: {
        TITLE: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_REPLIED_TITLE',
        INFO_TEXT: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_REPLIED_INFO_TEXT',
        TAGS: {
          LOW: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_REPLIED_TAGS_LOW',
          MED: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_REPLIED_TAGS_MED',
          HIGH: 'POSITION_OVERVIEW_PAGE_POSITION_PERFORMANCE_FUNNEL_REPLIED_TAGS_HIGH',
        },
      },
    },
  },
  POSITION_ADJUSTMENTS_PAGE: {
    SUBTITLE: 'POSITION_ADJUSTMENTS_PAGE_SUBTITLE',
    TITLE: 'POSITION_ADJUSTMENTS_PAGE_TITLE',
    ITEMS_CONTAINER_HINT: 'POSITION_ADJUSTMENTS_PAGE_ITEMS_CONTAINER_HINT',
    CONFIRM_BUTTON_TEXT: 'POSITION_ADJUSTMENTS_PAGE_CONFIRM_BUTTON_TEXT',
    EDIT_PREFERENCES: 'POSITION_ADJUSTMENTS_PAGE_EDIT_PREFERENCES',
    REDIRECT_TO_EDIT_POSITION: 'POSITION_ADJUSTMENTS_PAGE_REDIRECT_TO_EDIT_POSITION',
    ADJUSTMENT_ITEM_ACTION_BUTTON_TEXT: 'POSITION_ADJUSTMENTS_PAGE_ADJUSTMENT_ITEM_ACTION_BUTTON_TEXT',
    ADJUSTMENT_ITEM_ACTION_BUTTON_SELECTED_TEXT: 'POSITION_ADJUSTMENTS_PAGE_ADJUSTMENT_ITEM_ACTION_BUTTON_SELECTED_TEXT',
    LOCATION_ADJUSTMENT: {
      TITLE: 'POSITION_ADJUSTMENTS_PAGE_LOCATION_ADJUSTMENT_TITLE',
      DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_LOCATION_ADJUSTMENT_DESCRIPTION',
    },
    TITLE_ADJUSTMENT: {
      TITLE: 'POSITION_ADJUSTMENTS_PAGE_TITLE_ADJUSTMENT_TITLE',
      DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_TITLE_ADJUSTMENT_DESCRIPTION',
    },
    RECOMMENDED_ADJUSTMENTS: {
      LOCATION: {
        TITLE: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_LOCATION_TITLE',
        DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_LOCATION_DESCRIPTION',
      },
      LEVEL_OF_EXPERIENCE: {
        TITLE: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_LEVEL_OF_EXPERIENCE_TITLE',
        DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_LEVEL_OF_EXPERIENCE_DESCRIPTION',
      },
      COMPANY_SIZE: {
        TITLE: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_COMPANY_SIZE_TITLE',
        DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_COMPANY_SIZE_DESCRIPTION',
      },
      MUST_HAVES: {
        TITLE: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_MUST_HAVES_TITLE',
        DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_MUST_HAVES_DESCRIPTION',
      },
      JOB_TITLE: {
        TITLE: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_JOB_TITLE_TITLE',
        DESCRIPTION: 'POSITION_ADJUSTMENTS_PAGE_RECOMMENDED_ADJUSTMENTS_JOB_TITLE_DESCRIPTION',
      },
    },
  },
  HOMEPAGE: {
    POSITION_EMPTY: {
      TITLE: 'HOMEPAGE_POSITION_EMPTY_TITLE',
      DESCRIPTION: 'HOMEPAGE_POSITION_EMPTY_DESCRIPTION',
    },
    ERRORS: {
      CLOSED_POSITION_TITLE: 'HOMEPAGE_ERRORS_CLOSED_POSITION_TITLE',
      CLOSED_POSITION_DESCRIPTION: 'HOMEPAGE_ERRORS_CLOSED_POSITION_DESCRIPTION',
    },
    TEAMMATES_LABEL: 'HOMEPAGE_TEAMMATES_LABEL',
    POSITIONS_TITLE: 'HOMEPAGE_POSITIONS_TITLE',
    ACTIVE_POSITIONS_WIDGET: {
      LABEL: 'HOMEPAGE_ACTIVE_POSITIONS_WIDGET_LABEL',
      POSITIONS_COUNT_TEXT: 'HOMEPAGE_ACTIVE_POSITIONS_WIDGET_POSITIONS_COUNT_TEXT',
    },
    HEADER: {
      TITLE: 'HOMEPAGE_HEADER_TITLE',
      DESCRIPTION: 'HOMEPAGE_HEADER_DESCRIPTION',
      TRIAL_BANNER: {
        LABEL: 'HOMEPAGE_HEADER_TRIAL_BANNER_LABEL',
        ENDED: {
          TITLE: 'HOMEPAGE_HEADER_TRIAL_BANNER_ENDED_TITLE',
          DESCRIPTION: 'HOMEPAGE_HEADER_TRIAL_BANNER_ENDED_DESCRIPTION',
          ACTION_BUTTON_TEXT: 'HOMEPAGE_HEADER_TRIAL_BANNER_ENDED_ACTION_BUTTON_TEXT',
        },
        WILL_END: {
          TITLE: 'HOMEPAGE_HEADER_TRIAL_BANNER_WILL_END_TITLE',
          DESCRIPTION: 'HOMEPAGE_HEADER_TRIAL_BANNER_WILL_END_DESCRIPTION',
          ACTION_BUTTON_TEXT: 'HOMEPAGE_HEADER_TRIAL_BANNER_WILL_END_ACTION_BUTTON_TEXT',
        },
      },
      DAILY_STREAK: {
        CONSONANT_LABEL: 'HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL',
        VOWEL_LABEL: 'HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_VOWEL_LABEL',
        COUNTER: 'HOMEPAGE_HEADER_DAILY_STREAK_COUNTER',
        TITLE_VARIANT: [
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_1'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_2'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_3'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_4'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_5'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_6'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_7'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_VOWEL_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_8'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_9'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_10'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_VOWEL_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_11'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_12'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_13'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_14'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_15'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_16'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_17'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_VOWEL_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_18'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_19'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_20'],
          ['HOMEPAGE_HEADER_DAILY_STREAK_CONSONANT_VOWEL_LABEL', 'HOMEPAGE_HEADER_DAILY_STREAK_TITLE_VARIANT_21'],
        ],
      },
    },
    POSITION_CARD: {
      CREATE_POSITION_CARD_TITLE: 'HOMEPAGE_POSITION_CARD_CREATE_POSITION_CARD_TITLE',
      ONE_MORE_LOCATION: 'HOMEPAGE_POSITION_CARD_ONE_MORE_LOCATION',
      X_MORE_LOCATIONS: 'HOMEPAGE_POSITION_CARD_X_MORE_LOCATIONS',
      CLOSED_TEXT: 'HOMEPAGE_POSITION_CARD_CLOSED_TEXT',
      CLOSED_INFO: 'HOMEPAGE_POSITION_CARD_CLOSED_INFO',
      CREATED: 'HOMEPAGE_POSITION_CARD_CREATED',
      UPDATED: 'HOMEPAGE_POSITION_CARD_UPDATED',
    },
    POSITION_STATISTICS_CARDS: {
      TO_REVIEW: {
        TITLE: 'HOMEPAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_TITLE',
        TITLE_DAILY_LIMIT: 'HOMEPAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_TITLE_DAILY_LIMIT',
        TITLE_MATCHING_IN_THE_MAKING: 'HOMEPAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_TITLE_MATCHING_IN_THE_MAKING',
        TITLE_EMPTY: 'HOMEPAGE_POSITION_STATISTICS_CARDS_TO_REVIEW_TITLE_EMPTY',
      },
      TO_REACHOUT: {
        TITLE: 'HOMEPAGE_POSITION_STATISTICS_CARDS_TO_REACHOUT_TITLE',
      },
      TO_REPLY: {
        TITLE: 'HOMEPAGE_POSITION_STATISTICS_CARDS_TO_REPLY_TITLE',
      },
      LABEL_ATS: 'HOMEPAGE_POSITION_STATISTICS_CARDS_LABEL_ATS',
    },
  },
  TIME_OF_DAY: {
    NIGHT: 'NIGHT',
    EVENING: 'EVENING',
    AFTERNOON: 'AFTERNOON',
    MORNING: 'MORNING',
  },
  USER_PICKER: {
    ASSIGN: 'USER_PICKER_ASSIGN',
    INVITE_TEAMMATE_TEXT: 'USER_PICKER_INVITE_TEAMMATE_TEXT',
    RECRUITER: 'USER_PICKER_RECRUITER',
    RECRUITER_MENU_TITLE: 'USER_PICKER_RECRUITER_MENU_TITLE',
    HIRING_MANAGER: 'USER_PICKER_HIRING_MANAGER',
    HIRING_MANAGER_MENU_TITLE: 'USER_PICKER_HIRING_MANAGER_MENU_TITLE',
  },
  USER_MENU_ITEM: {
    INVITE_PENDING: 'USER_MENU_ITEM_INVITE_PENDING',
    EMAIL_NOT_CONFIGURED: 'USER_MENU_ITEM_EMAIL_NOT_CONFIGURED',
    ME: 'USER_MENU_ITEM_ME',
  },
  GUIDANCE_MODAL: {
    TITLE: 'GUIDANCE_MODAL_TITLE',
    OPEN_GUIDANCE_MODAL_BUTTON_TEXT: 'GUIDANCE_MODAL_OPEN_GUIDANCE_MODAL_BUTTON_TEXT',
    COMPANY_BACKGROUND_MODAL_TITLE: 'GUIDANCE_MODAL_COMPANY_BACKGROUND_MODAL_TITLE',
    SKILLS_MODAL_TITLE: 'GUIDANCE_MODAL_SKILLS_MODAL_TITLE',
    OUTREACH_AI_INSTRUCTIONS_MODAL_TITLE: 'GUIDANCE_MODAL_OUTREACH_AI_INSTRUCTIONS_MODAL_TITLE',
  },
  CONNECT_EMAIL_NOTIFICATION: {
    TITLE: 'CONNECT_EMAIL_NOTIFICATION_TITLE',
    CURRENT_USER_DESCRIPTION: 'CONNECT_EMAIL_NOTIFICATION_CURRENT_USER_DESCRIPTION',
    CURRENT_USER_DESCRIPTION_WITHOUT_POSITION: 'CONNECT_EMAIL_NOTIFICATION_CURRENT_USER_DESCRIPTION_WITHOUT_POSITION',
    DESCRIPTION: 'CONNECT_EMAIL_NOTIFICATION_DESCRIPTION',
    DESCRIPTION_WITHOUT_POSITION: 'CONNECT_EMAIL_NOTIFICATION_DESCRIPTION_WITHOUT_POSITION',
    DESCRIPTION_WITHOUT_USERNAME: 'CONNECT_EMAIL_NOTIFICATION_DESCRIPTION_WITHOUT_USERNAME',
    DESCRIPTION_WITHOUT_POSITION_AND_USERNAME: 'CONNECT_EMAIL_NOTIFICATION_DESCRIPTION_WITHOUT_POSITION_AND_USERNAME',
    ACTION_BUTTON_TEXT: 'CONNECT_EMAIL_NOTIFICATION_ACTION_BUTTON_TEXT',
    CLOSE_BUTTON_TEXT: 'CONNECT_EMAIL_NOTIFICATION_CLOSE_BUTTON_TEXT',
  },
  CAMPAIGN_ERROR_NOTIFICATION: {
    TITLE: 'CAMPAIGN_ERROR_NOTIFICATION_TITLE',
    DESCRIPTION: 'CAMPAIGN_ERROR_NOTIFICATION_DESCRIPTION',
    ACTION_BUTTON_TEXT: 'CAMPAIGN_ERROR_NOTIFICATION_ACTION_BUTTON_TEXT',
    SECONDARY_ACTION_BUTTON_TEXT: 'CAMPAIGN_ERROR_NOTIFICATION_SECONDARY_ACTION_BUTTON_TEXT',
    CLOSE_BUTTON_TEXT: 'CAMPAIGN_ERROR_NOTIFICATION_CLOSE_BUTTON_TEXT',
  },
  UNSAVED_CHANGES: 'UNSAVED_CHANGES',
  GLOBAL_ERROR: {
    FORBIDDEN: {
      TITLE: 'GLOBAL_ERROR_FORBIDDEN_TITLE',
      DESCRIPTION: 'GLOBAL_ERROR_FORBIDDEN_DESCRIPTION',
    },
  },
  USER_ROLE_CHANGED_BANNER: {
    TITLE: 'USER_ROLE_CHANGED_BANNER_TILE',
    DESCRIPTION: 'USER_ROLE_CHANGED_BANNER_DESCRIPTION',
    BUTTON: 'USER_ROLE_CHANGED_BANNER_BUTTON',
  },
  MATCHES_PAGE: {
    CHAT: {
      AI_PERFECT: 'MATCHES_PAGE_CHAT_AI_PERFECT',
      INPUT: {
        PLACEHOLDER: 'MATCHES_PAGE_CHAT_INPUT_PLACEHOLDER',
        NETWORK_ERROR: 'MATCHES_PAGE_CHAT_INPUT_NETWORK_ERROR',
      },
      SNACKBAR: {
        DAILY_LIMIT_REACHED: 'MATCHES_PAGE_CHAT_SNACKBAR_DAILY_LIMIT_REACHED',
        SOMETHING_WENT_WRONG: 'MATCHES_PAGE_CHAT_SNACKBAR_SOMETHING_WENT_WRONG',
        RETRY: 'MATCHES_PAGE_CHAT_SNACKBAR_RETRY',
      },
    },
    EMPTY_STATE: {
      HI_USER: 'MATCHES_PAGE_EMPTY_STATE_HI_USER',
      THE_FUTURE_OF_HIRING: 'MATCHES_PAGE_EMPTY_STATE_THE_FUTURE_OF_HIRING',
      SUBTITLE: 'MATCHES_PAGE_EMPTY_STATE_SUBTITLE',
      THINGS_YOU_CAN_ASK: 'MATCHES_PAGE_EMPTY_STATE_THINGS_YOU_CAN_ASK',
      SUGGESTION1: 'MATCHES_PAGE_EMPTY_STATE_SUGGESTION1',
      SUGGESTION2: 'MATCHES_PAGE_EMPTY_STATE_SUGGESTION2',
      SUGGESTION3: 'MATCHES_PAGE_EMPTY_STATE_SUGGESTION3',
      SUGGESTION4: 'MATCHES_PAGE_EMPTY_STATE_SUGGESTION4',
    },
    DEADEND: {
      TITLE: 'MATCHES_PAGE_DEADEND_TITLE',
      DESCRIPTION: 'MATCHES_PAGE_DEADEND_DESCRIPTION',
    },
    ERROR: {
      TITLE: 'MATCHES_PAGE_ERROR_TITLE',
      DESCRIPTION: 'MATCHES_PAGE_ERROR_DESCRIPTION',
      BOTTOM: {
        NOTIFY_ONCE_READY: 'MATCHES_PAGE_ERROR_BOTTOM_NOTIFY_ONCE_READY',
        NEED_HELP: 'MATCHES_PAGE_ERROR_BOTTOM_NEED_HELP',
      },
    },
    MATCHING_PROGRESS: {
      GETTING_READY: 'MATCHES_PAGE_MATCHING_PROGRESS_GETTING_READY',
      UNDERSTANDING_CONTEXT: 'MATCHES_PAGE_MATCHING_PROGRESS_UNDERSTANDING_CONTEXT',
      FINDING_BEST_MATCHES: 'MATCHES_PAGE_MATCHING_PROGRESS_FINDING_BEST_MATCHES',
      EVALUATING_FIT: 'MATCHES_PAGE_MATCHING_PROGRESS_EVALUATING_FIT',
      SHORTLISTING_CANDIDATES: 'MATCHES_PAGE_MATCHING_PROGRESS_SHORTLISTING_CANDIDATES',
      BUILDING_PROFILES: 'MATCHES_PAGE_MATCHING_PROGRESS_BUILDING_PROFILES',
      FINALIZING_MATCHES: 'MATCHES_PAGE_MATCHING_PROGRESS_FINALIZING_MATCHES',
      PREPARING_RESULTS: 'MATCHES_PAGE_MATCHING_PROGRESS_PREPARING_RESULTS',
    },
  },
};

export default STRING_KEYS;
