import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import HomepageHeader from '../../components/homepage-header/homepage-header.component';
import PositionCard from '../../components/position-card/position-card.component';
import AtsInitializationDialogs
  from '../../../../components/dialogs/ats-initialization-dialogs/ats-initialization-dialogs.component';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../consts/analytics';
import { useGetOpenPositionsQuery } from '../../../../store/rtk-query/apis/position/hooks/get-open-positions.query-hook';
import CreatePositionCard from '../../components/create-position-card/create-position-card.component';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../../create-position/consts/position-analytics.consts';
import { useGetMyAccountSelector } from '../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import HomepageBodyHeader from '../../components/homepage-body-header/homepage-body-header.component';
import { useAppState } from '../../../../store/selectors';
import { PAGE_TITLES } from '../../../../consts';
import { LOGIN_STATE } from '../../../../store/slices/app-state/app-state.consts';
import { PAGE_NAMES } from '../../../../consts/pages';
import ClosePositionModal from '../../../../components/dialogs/close-position-modal/close-position-modal.component';
import InviteColleagueModal
  from '../../../../components/dialogs/invite-colleague-modal/invite-colleague-dialog.component';
import { PositionResponse } from '../../../../store/rtk-query/apis/position/dto/response/position.response';
import { HEADER_CONTAINER_HEIGHT_PX } from '../../components/homepage-header/homepage-header.styled';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';
import { PositionState } from '../../../../enums/position-state.enum';
import { paymentQuery } from '../../../../store/rtk-query/apis/payment/payment.toolkit-api';
import { PricingPlanEnum } from '../../../../enums/user-plan.enum';
import { useGetPositionPriceSelector } from '../../../../store/rtk-query/apis/payment/selectors/get-position-price.selector';
import { usePositionLimitUpgradeModal } from '../../../../hooks/position-limit-upgrade-modal';
import EmailConnectionErrorNotification
  from '../../../../components/dialogs/email-connection-error-notification/email-connection-error-notification.component';
import CampaignErrorNotification
  from '../../../../components/dialogs/campaign-error-notification/campaign-error-notification.component';
import STRING_KEYS from '../../../../language/keys';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import { usePermissionCheck } from '../../../../hooks/use-permissions.hook';
import { PERMISSIONS } from '../../../../consts/permissions.const';
import PositionsEmpty from '../../components/positions-empty/positions-empty.component';
import {
  useCreatePositionMutation,
} from '../../../../store/rtk-query/apis/position/hooks/create-position.mutation-hook';
import { AppRouting } from '../../../../services/appRoutingResolver';
import {
  MatchingMakingTransitionScreen,
} from '../../../../components/Common/matching-making-transition/matching-making-transition.component';
import {
  BodyContainer, BodyWrapper, Container, PositionsContainer,
} from './home.styled';

const Homepage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { data: positions } = useGetOpenPositionsQuery(false, true);
  const { data: { plan: { maximumOpenPositions = 0, pricingPlan, positionsLimit = 0 } } } = useGetMyAccountSelector();
  const { analyticsTrackEvent, analyticsPageEvent } = useAnalyticsContext();
  const { loginState } = useAppState();
  const headerRef = useRef<HTMLDivElement>(null);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [isAtsInitializationDialogOpen, setIsAtsInitializationDialogOpen] = useState(false);
  const [atsInitializationPositionId, setAtsInitializationPositionId]  = useState<string>('');
  const [closePositionId, setClosePositionId]  = useState<string>('');
  const [isClosePositionModalOpen, setIsClosePositionModalOpen] = useState<boolean>(false);
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
  const { data: { price: positionPrice } } = useGetPositionPriceSelector();
  const [getPositionPrice] = paymentQuery.useLazyGetPositionPriceQuery();
  const [isPositionLimitUpgradeDialogOpen, setIsPositionLimitUpgradeDialogOpen] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const { allowed: allowedCreatePosition } = usePermissionCheck([PERMISSIONS.position.createAll]);

  const [
    createPosition,
    { isSuccess: isPositionCreateSuccess, isLoading: isPositionCreateLoading, data: createdPosition },
  ] = useCreatePositionMutation();

  const openPositionsAmount = useMemo(() => positions.filter((position) => position.state === PositionState.Open).length, [positions]);

  const onCreatePositionButtonClick = () => {
    const eventData = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_CLICKED_FROM_HOME_PAGE,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.HOMEPAGE,
      positionQuota: maximumOpenPositions,
    };
    analyticsTrackEvent(eventData);

    if (openPositionsAmount >= maximumOpenPositions) {
      setIsPositionLimitUpgradeDialogOpen(true);
    } else {
      createPosition();
    }
  };

  const UpgradeModal = usePositionLimitUpgradeModal({
    isOpen: isPositionLimitUpgradeDialogOpen,
    createPositionHandler: () => {
      setIsPositionLimitUpgradeDialogOpen(false);
      onCreatePositionButtonClick();
    },
    closeHandler: () => setIsPositionLimitUpgradeDialogOpen(false),
  });

  useEffect(() => {
    document.title = PAGE_TITLES.HOME;

    if (loginState === LOGIN_STATE.LOGGED_IN) {
      analyticsPageEvent({
        eventName: ANALYTICS_EVENTS.HOME_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: PAGE_NAMES.HOME_PAGE,
      });
    }

    if (pricingPlan === PricingPlanEnum.PRO && maximumOpenPositions < positionsLimit && !positionPrice) {
      getPositionPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPositionCreateSuccess && createdPosition) {
      history.push(AppRouting.buildMatchesPageUrl(createdPosition.id));
    }
  }, [isPositionCreateSuccess, createdPosition, history]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsHeaderSticky(event.currentTarget?.scrollTop >= (HEADER_CONTAINER_HEIGHT_PX - HEADER_HEIGHT));
  };

  const onClickClosePosition = (positionId: string) => {
    setIsClosePositionModalOpen(true);
    setClosePositionId(positionId);
  };

  const onClickClosePositionDialog = () => {
    setIsClosePositionModalOpen(false);
    setClosePositionId('');
  };

  const onClickAtsInitialization = (positionId: string) => {
    setIsAtsInitializationDialogOpen(true);
    setAtsInitializationPositionId(positionId);
  };

  const onClickCloseAtsInitializationDialog = () => {
    setIsAtsInitializationDialogOpen(false);
    setAtsInitializationPositionId('');
  };

  const onInviteUsersButtonClick = () => {
    setIsInviteUsersModalOpen(true);

    const payload = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_INVITE_TEAMMATE,
      eventGroup: EVENT_GROUP.CLICK,
    };
    analyticsTrackEvent(payload);
  };

  const renderPositions = (positionsArray: PositionResponse[]) => {
    if (!positionsArray.length) {
      return (
        <PositionsEmpty
          allowedCreatePosition={ allowedCreatePosition }
          createPositionButtonClickHandler={ onCreatePositionButtonClick }
        />
      );
    }

    return (
      <PositionsContainer>
        {
          positionsArray.map((position, index) => (
            <PositionCard
              key={ position.id }
              position={ position }
              closePositionHandler={ onClickClosePosition }
              atsInitializationHandler={ onClickAtsInitialization }
              positionIndex={ index }
            />
          ))
        }
        { allowedCreatePosition && (
          <CreatePositionCard clickHandler={ onCreatePositionButtonClick } />
        )}
      </PositionsContainer>
    );
  };

  if (isPositionCreateLoading) {
    analyticsPageEvent({
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_FINISH_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      pageName: PRODUCT_AREA.HOMEPAGE,

    });

    return (
      <MatchingMakingTransitionScreen />
    );
  }

  return (
    <>
      <Container onScroll={ handleScroll } ref={ headerRef }>
        <HomepageHeader isHeaderSticky={ isHeaderSticky } />
        <BodyContainer>
          <BodyWrapper>
            <HomepageBodyHeader
              inviteUserButtonClickHandler={ onInviteUsersButtonClick }
              createPositionButtonClickHandler={ onCreatePositionButtonClick }
              upgradeButtonClickHandler={ () => setIsPositionLimitUpgradeDialogOpen(true) }
              isPositionCreateLoading={ isPositionCreateLoading }
            />
            { renderPositions(positions) }
          </BodyWrapper>
        </BodyContainer>
      </Container>
      { UpgradeModal }
      { atsInitializationPositionId && (
        <AtsInitializationDialogs
          positionId={ atsInitializationPositionId }
          isOpen={ isAtsInitializationDialogOpen }
          closeHandler={ () => onClickCloseAtsInitializationDialog() }
          productArea={ PRODUCT_AREA.HOMEPAGE }
        />
      ) }
      {
        isClosePositionModalOpen && (
          <ClosePositionModal
            positionId={ closePositionId }
            onClose={ onClickClosePositionDialog }
          />
        )
      }
      {
        isInviteUsersModalOpen && (
          <InviteColleagueModal
            isOpen={ isInviteUsersModalOpen }
            closeHandler={ () => setIsInviteUsersModalOpen(false) }
          />
        )
      }
      <EmailConnectionErrorNotification />
      <CampaignErrorNotification onErrorHandler={ () => setShowError(true) } />
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
        isOpen={ showError }
        onClose={ () => setShowError(false) }
        shouldAutoDismiss
      />
    </>
  );
};

export default Homepage;
