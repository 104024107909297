import styled from 'styled-components';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { ButtonFont, CaptionFont } from '../../../../themes/fonts';

export const Background = styled.div`
  background-image: url(${ASSETS.REGISTRATION_PAGE_BACKGROUND});
  background-position: 50% -40px;
  background-size: 100vw;
  background-repeat: no-repeat;
  filter: blur(13px);
  mix-blend-mode: normal;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 80px);
  z-index: -1;
  opacity: ${THEME.OPACITY.MED};
`;

export const PageContentsWrapper = styled.div`
  margin-top: ${THEME.SPACING.GRAND};
  width: 400px;
    display: flex;
    flex-direction: column;
    gap: ${THEME.SPACING.LOOSE};
`;

export const Wrapper = styled.div`
  text-align: center;
  flex-direction: column;
`;

export const AlreadyHaveAccountText = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const LoginButton = styled.div`
  ${ButtonFont};
  width: 48px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  justify-self: center;
  cursor: pointer;
  margin-top: ${THEME.SPACING.TINY};
  color: ${THEME.COLORS.ACCENT};
`;

export const DividerWithTitle = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  opacity: ${THEME.OPACITY.LOW};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: revert;
  align-items: center;
  :before, :after {
    content: '';
    background-color: ${THEME.COLORS.PRIMARY_DARK};
    opacity: ${THEME.OPACITY.LOW};
    height: 1px;
    flex-grow: 1;
  }
  span {
    margin: 0 15px;
  }
`;
