import React from 'react';
import { ChatUserMessageProps } from './chat-user-message';
import {
  AvatarWrapper,
  ChatBubble,
  Column,
  Container,
  UserAvatar,
  UserName,
} from './chat-user-message.styled';

export const ChatUserMessage = ({ conversationMessage }: ChatUserMessageProps): JSX.Element => {
  return (
    <>
      <Container sender={ conversationMessage.messageType }>
        <Column>
          <UserName>{conversationMessage?.sender?.name}</UserName>
          <ChatBubble key={ conversationMessage.id } sender={ conversationMessage.messageType }>
            { conversationMessage.message }
          </ChatBubble>
        </Column>
        <AvatarWrapper>
          <UserAvatar src={ conversationMessage?.sender?.profilePictureUrl } />
        </AvatarWrapper>
      </Container>
    </>
  );
};
