import styled, { css, keyframes } from 'styled-components';
import { THEME } from '../../../themes';
import { CaptionFont, Subtitle1Font } from '../../../themes/fonts';
import { ASSETS } from '../../../assets';

const shakeHorizontal = keyframes` 
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px); 
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${THEME.SPACING.LOOSE};
`;

export const StyledForm = styled.form<{isDisabled: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  width: 90%;
  max-width: 400px;
  opacity: ${(props) => props.isDisabled ? THEME.OPACITY.LOW : THEME.OPACITY.HIGHEST};
  pointer-events: ${(props) => props.isDisabled ? 'none' : 'all'};
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  margin-top: ${THEME.SPACING.MEDIUM};
`;

export const ErrorContainer = styled.div`
  width: 464px;
  margin-top: ${THEME.SPACING.TINY};
`;

export const PasswordInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const PasswordInput = styled.input<{ isError: boolean }>`
  ${Subtitle1Font};
  font-weight: normal;
  width: 100%;
  margin-top: ${THEME.SPACING.TINY};
  background-color: ${THEME.COLORS_RGB.GHOST_GRAY};
  color: ${THEME.COLORS_RGB.CONTENT_PRIMARY_MED};
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.BASE}
    ${THEME.SPACING.GRAND};
  border-radius: ${THEME.RADIUS.ROUNDISH};
  border: solid 1px
    ${({ isError }) => isError ? THEME.COLORS.ALERT : THEME.COLORS_RGB.PRIMARY_DIVIDER};
  caret-color: ${THEME.COLORS.ACCENT};
  :focus {
    outline: none;
  }
  :before {
  }
  animation: ${({ isError }) => isError ?
    css`
          ${shakeHorizontal} 0.7s cubic-bezier(0.175, 0.885, 0.320, 1.275) both
        ` :
    ''};
`;

export const ValidationError = styled.div`
  ${CaptionFont};
  line-height: 1.33;
  color: ${THEME.COLORS.ALERT};
  margin-top: ${THEME.SPACING.TINY};
`;

export const PasswordIcon = styled.div`
  position: absolute;
  top: 9px;
  left: 0;
  width: 53px;
  height: 53px;
  border-radius: ${THEME.RADIUS.ROUNDISH};
  background: url(${ASSETS.SECRET_ICON}) no-repeat ${THEME.SPACING.MEDIUM};
  background-size: 24px;
  z-index: 1;
`;
