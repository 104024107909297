import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Body1Font, Title2Font } from '../../../../../themes/fonts';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  max-width: 1640px;
  grid-gap: 64px;
`;

export const IconContainer = styled.div`
  position: absolute;
`;

export const LeftColumn = styled.div`
  padding-left: ${THEME.SPACING.GRAND};
  align-content: center;
`;

export const ColumnRight = styled.div``;

export const Title = styled.div`
   margin-bottom: ${THEME.SPACING.BASE};
  color: ${THEME.COLORS.CONTENT_A};
  ${Title2Font};
  font-weight: 600;
`;

export const Description = styled.div`
  ${Body1Font};
  line-height: 24px;
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
  max-width: 640px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NextDailyMatches = styled.div`
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const NeedFlexibility = styled.span`
  color: ${THEME.COLORS.CONTENT_A};
  ${Body1Font};
`;

export const ConsiderUpgrading = styled.span`
  color: ${THEME.COLORS.CONTENT_A};
  ${Body1Font};
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ActionContainer = styled.div`
  margin-top: ${THEME.SPACING.LOOSE};
  display: flex;
  button:first-of-type {
    margin-right: ${THEME.SPACING.TINO};
  }
`;
