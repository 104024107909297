import {
  ChangeEventHandler,
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PerfectMenuSize } from '../perfect-base-menu/perfect-base-menu.enums';
import BaseMenu from '../perfect-base-menu/perfect-base-menu.component';
import {
  ArrowIcon,
  ArrowIconContainer,
  Container,
  ContentContainer,
  Input,
  Label,
  LabelContainer,
  LabelIconWrapper,
} from './select.styled';
import { SelectProps } from './select';

const Select = ({
  label,
  LabelIcon,
  ActionIcon,
  placeholder,
  value,
  onChangeHandler,
  onInputChange,
  children,
  StartAdornment,
  menuProps,
  disabled = false,
  formatDisplayValue,
  placement,
  isAutocomplete = false,
  ...otherProps
}: SelectProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [inputValue, setInputValue] = useState<string>('');

  const anchorElementRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [
    selectedElement,
    setSelectedElement,
  ] = useState<SelectItemProps | null>(null);

  const openOptionsMenu = useCallback(() => {
    setAnchorElement(anchorElementRef?.current);
  }, []);

  const closeOptionsMenu = useCallback(() => {
    setAnchorElement(null);
    setInputValue('');
  }, []);

  const handleContainerClick = useCallback(() => {
    if (!disabled) {
      if (anchorElement) {
        closeOptionsMenu();

        if (isAutocomplete) {
          inputRef.current?.blur();
        }
      } else {
        openOptionsMenu();

        if (isAutocomplete) {
          inputRef.current?.focus();
        }
      }
    }
  }, [
    anchorElement,
    closeOptionsMenu,
    disabled,
    isAutocomplete,
    openOptionsMenu,
  ]);

  const onSelectItemClick = useCallback(
    (selectedValue: string) => {
      onChangeHandler(selectedValue);
      closeOptionsMenu();
    },
    [closeOptionsMenu, onChangeHandler],
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value ?? '';
    setInputValue(newValue);
    onInputChange?.(newValue);
  };

  const childrenWithProps = useMemo(() => {
    return Children.map(children, (child) => {
      const item = child as ReactElement<PropsWithChildren<SelectItemProps>>;

      // Checking isValidElement is the safe way and avoids a TS error too.
      if (isValidElement(item)) {
        if (
          inputValue.length &&
          !item.props.displayValue
            ?.toLowerCase()
            ?.includes(inputValue.toLowerCase())
        ) {
          return null;
        }

        const selected = value !== undefined && value === item.props.value;

        if (selected) {
          setSelectedElement(item.props);
        }

        return cloneElement(item, {
          onClickHandler: onSelectItemClick,
          selected,
          value: item.props.value,
          children: item.props.children,
        });
      }

      return item;
    });
  }, [children, inputValue, onSelectItemClick, value]);

  const renderLabel = (): JSX.Element | undefined => {
    if (!label && !LabelIcon) {
      return undefined;
    }

    return (
      <LabelContainer>
        {LabelIcon && <LabelIconWrapper>{LabelIcon}</LabelIconWrapper>}
        {label && <Label>{label}</Label>}
      </LabelContainer>
    );
  };

  let selectedItemDisplayedValue = value;

  if (selectedElement) {
    if (formatDisplayValue) {
      selectedItemDisplayedValue = formatDisplayValue(selectedElement);
    } else {
      selectedItemDisplayedValue =
        selectedElement.displayValue ?? selectedElement.value;
    }
  }

  return (
    <>
      <Container
        ref={ anchorElementRef }
        onClick={ handleContainerClick }
        isActive={ !!anchorElement }
        disabled={ disabled }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...otherProps }
      >
        {StartAdornment}

        <ContentContainer>
          {renderLabel()}

          <Input
            value={
              inputValue.length ? inputValue : selectedItemDisplayedValue ?? ''
            }
            placeholder={ placeholder }
            readOnly={ !isAutocomplete || disabled }
            onChange={ handleInputChange }
            autoComplete="new-password"
            ref={ inputRef }
          />
        </ContentContainer>

        {ActionIcon ?? (
          <ArrowIconContainer>
            <ArrowIcon />
          </ArrowIconContainer>
        )}
      </Container>
      
      <BaseMenu
        isOpen={ !!anchorElement }
        anchorElement={ anchorElement || undefined }
        placement={ placement ?? 'auto-start' }
        onClose={ closeOptionsMenu }
        size={ PerfectMenuSize.Large }
        Title={ menuProps?.Title }
        disabledArrowNavigation
      >
        {childrenWithProps}
      </BaseMenu>
    </>
  );
};

export default Select;
