import { ConversationMessage } from '../dto/response/conversation-message';

export const getConversationByPositionIdResponseTransformer = (response: PaginationResponse<ConversationMessage[]>): PaginationResponse<ConversationMessage[]> => {
  return {
    ...response,
    results: response.results.map((message) => ({
      ...message,
      isTyping: false,
      isThinking: false,
      actions: [], // Set default actions array
      actionsDisabled: false, // Set default value
    })),
  };
};
