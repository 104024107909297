import styled from 'styled-components';
import { Title2Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import { WordItemProps } from './word-slider';

export const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;

export const TrackContainer = styled.div`
  position: relative;
  height: 40px;
`;

export const WordsContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WordContent = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 1rem;
`;

export const WordItem = styled.div<WordItemProps>`
  position: absolute;
  left: 50%;
  width: ${(props) => props.$width}px;
  transition: transform 500ms ease-in-out;
  transform: translateX(
    calc(${(props) => (props.$offset * props.$slotWidth) + (props.$isAnimating ? -props.$slotWidth : 0)}px - 50%)
  );
`;

export const WordText = styled.span<{ $isCentered: boolean }>`
  ${Title2Font};
  font-weight: 600;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  transition: color 300ms ease-in-out;
  color: ${(props) => props.$isCentered ? THEME.COLORS.ACCENT : THEME.COLORS.TRUE_BLACK};
`;
