import { useAuth } from '@frontegg/react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import STRING_KEYS from '../../language/keys';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../consts/analytics';
import { PAGE_NAMES } from '../../consts/pages';
import { useAnalyticsContext } from '../../contexts/analytics/useAnalyticsContext';
import { PAGE_TITLES } from '../../consts';
import LoginAndRegistrationComponent from '../login-and-reqistration-container/login-and-registration.component';
import { AppRouting } from '../../services/appRoutingResolver';
import LoginForm from './login-form/login-form.component';
import {
  Accent, DontHaveAccountText, SignUpButton, Wrapper,
} from './login.styled';
import AccountLogo from './account-logo/account-logo.component';

function LoginScreen(): JSX.Element {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = PAGE_TITLES.LOGIN;
  }, []);

  const { t: translate } = useTranslation();
  const { loginState } = useAuth();
  const { analyticsPageEvent } = useAnalyticsContext();
  const { loading: isLoading, error: errorMessage } = loginState;

  useEffect(() => {
    analyticsPageEvent({
      eventName: ANALYTICS_EVENTS.LOGIN_PAGE_VIEWED,
      pageName: PAGE_NAMES.LOGIN_PAGE,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginAndRegistrationComponent
      headerSticky
      isLoading={ isLoading }
      title={ <> {translate(STRING_KEYS.WELCOME_BACK_TO)} <Accent>{translate(STRING_KEYS.PERFECT)}</Accent></> }
      accountLogo={ <AccountLogo /> }
    >
      <LoginForm errorMessage={ errorMessage } />

      <Wrapper>
        <DontHaveAccountText>
          {translate(STRING_KEYS.DONT_HAVE_ACCOUNT)}
        </DontHaveAccountText>
        
        <SignUpButton onClick={ () => AppRouting.navigateToUrl(AppRouting.buildGlobalOnboardingUrl(window.location)) }>
          {translate(STRING_KEYS.SIGNUP)}
        </SignUpButton>
      </Wrapper>
    </LoginAndRegistrationComponent>
  );
}

export default LoginScreen;
