import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CHAT_INPUT_HEIGHT } from '../perfect-ai-chat/perfect-ai-chat.styled';
import { Subtitle2Font } from '../../../../../themes/fonts';
import { temporaryElevation } from '../../../../../themes/elevations';

export const Container = styled.div`
  position: absolute;
  min-height: 40px;
  bottom: calc(${CHAT_INPUT_HEIGHT}px + ${THEME.SPACING.BASE});
  left: 0;
  right: 0;
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  color: ${THEME.COLORS.TRUE_BLACK};
  z-index: 1000;
  border-radius: ${THEME.RADIUS.BASE};
  margin: 0 ${THEME.SPACING.TINY};
  ${temporaryElevation};
`;

export const MessageRow = styled.div`
  display: flex;
  justify-content: center;
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  gap: ${THEME.SPACING.TINY};
  padding: ${THEME.SPACING.TINO};
`;

export const Text = styled.p`
  ${Subtitle2Font};
  margin: 0;
  padding: 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${THEME.SPACING.TINY};
  margin-bottom: ${THEME.SPACING.TINO};
  padding-left: 37px;
`;
