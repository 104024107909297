import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Body2Font } from '../../../../../../themes/fonts';

export const ActionContainer = styled.div`
  margin-top: ${THEME.SPACING.BASE};
  padding-left: ${THEME.SPACING.BASE};
  display: flex;
  gap: ${THEME.SPACING.TINY};
  flex-wrap: wrap;
`;

export const ActionButton = styled.button<{ disabled?: boolean }>`
  ${Body2Font};
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.BASE};
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.CONTENT_PRIMARY};
  border: none;
  border-radius: ${THEME.RADIUS.BASE};
  opacity: ${(props) => (props.disabled ? THEME.OPACITY.LOW : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;
  font-weight: 600;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, ${THEME.OPACITY.GHOSTLY});
`;
