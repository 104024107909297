import { GetQuery, PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { POSITION_ID_PATH_PARAM, ROUTES } from './recruiter-agent.consts';
import { CreateMessageRequest } from './dto/request/create-message.request';
import { GetConversationMessageQueryArguments } from './dto/query-arguments/get-conversation-message.query-arguments';
import { CreateMessageQueryArguments } from './dto/query-arguments/create-message.query-arguments';
import { RetryGenerationQueryArguments } from './dto/query-arguments/retry-generation.query-arguments';

export const getRecruiterAgentInfoQuery = (positionId: string): GetQuery => ({
  url: ROUTES.RECRUITER_AGENT_INFO.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const getConversationQuery = ({ positionId }: GetConversationMessageQueryArguments): GetQuery => ({
  url: ROUTES.RECRUITER_AGENT_CONVERSATION_BY_POSITION_ID.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Get,
});

export const createMessageQuery = ({ message, positionId }: CreateMessageQueryArguments): PostQuery<CreateMessageRequest> => ({
  url: ROUTES.RECRUITER_AGENT_MESSAGE.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Post,
  data: { message },
});

export const retryGenerationQuery = ({ positionId }: RetryGenerationQueryArguments): PostQuery<undefined> => ({
  url: ROUTES.RECRUITER_AGENT_RETRY_GENERATION.replace(POSITION_ID_PATH_PARAM, positionId),
  method: QueryMethods.Post,
});
