import { useEffect } from 'react';
import { getMatchByIdQuery } from '../../../../store/rtk-query/apis/match/hooks/get-match-by-id.query-hook';
import { THEME } from '../../../../themes';
import { BodyWrapper, StyledCard, StyledDialog } from './match-card-modal.styled';

const MatchCardModal = ({
  positionId,
  matchId = '',
  isOpen,
  closeHandler,
}: MatchCardModalProps): JSX.Element => {
  const {
    data: match,
    error: matchError,
  } = getMatchByIdQuery(positionId, matchId);

  useEffect(() => {
    if (matchError) {
      closeHandler();
    }
  }, [closeHandler, matchError]);

  if (!match) {
    return <></>;
  }

  return (
    <StyledDialog
      isOpen={ isOpen }
      onClose={ closeHandler }
      widthSize={ `${THEME.BREAKPOINTS.WIDTH.LAPTOP_MIN}px` }
    >
      <BodyWrapper>
        <StyledCard
          match={ match }
          viewChangeHandler={ () => null }
          engagedMatchCount={ 0 }
          matchesLength={ 0 }
          disableExpansion
          hideActions
        />
      </BodyWrapper>
    </StyledDialog>
  );
};

export default MatchCardModal;
