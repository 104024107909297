import { useEffect } from 'react';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PRODUCT_AREA } from '../../consts/analytics';
import { AnalyticsProvider } from '../../contexts/analytics';
import {
  useCreateUserOnboardingMutation,
  useUpdateOnboardingProgressMutation,
} from '../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { useAppSelector, useAppState } from '../../store/selectors';
import { setAiChatPrompt, setPromo } from '../../store/slices/onboarding-state/onboarding-state.toolkit-slice';
import { AppRouting } from '../../services/appRoutingResolver';
import { useUrlQuery } from '../../hooks/route';
import { selectAiChatPrompt, selectPromo } from '../../store/selectors/onboarding.selectors';
import { LOGIN_STATE } from '../../store/slices/app-state/app-state.consts';
import { removeInitialLoadings } from '../../store/slices/app-state/app-state.toolkit-slice';
import { RegistrationPage } from './pages/onboarding-registration-page/onboarding-registration.page';
import { ONBOARDING_ROUTES } from './consts/onboarding.consts';
import {
  OnboardingEmailVerificationPage,
} from './pages/onboarding-email-verification-page/onboarding-email-verification.page';
import { AccountRecruitingUsageType, OnboardingStep } from './enums/onboarding.enums';
import { OnboardingSetupProfilePage } from './pages/onboarding-setup-profile-page/onboarding-setup-profile.page';
import { OnboardingStepsLayout } from './components/onboarding-steps-layout/onboarding-steps-layout.component';
import {
  OnboardingSetupProfileFormState,
} from './components/onboarding-setup-profile-form/onboarding-setup-profile-form';
import {
  OnboardingCompletedTransitionPage,
} from './pages/onboarding-completed-transition-page/onboarding-completed-transition.page';

export const OnboardingRouter = (): JSX.Element | null => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search: queryParams, pathname } = useLocation();
  const { loginState, showSplashScreen } = useAppState();

  const [createUserOnboarding] = useCreateUserOnboardingMutation();
  const [updateUserOnboarding] = useUpdateOnboardingProgressMutation();

  const isCurrentRouteRequiresUserToBeLoggedIn = pathname !== '/' && pathname !== ONBOARDING_ROUTES.REGISTRATION;
  const isUserLoggedIn =  loginState === LOGIN_STATE.LOGGED_IN;

  useEffect(() => {
    // If the user is logged in, remove the splash screen
    if (showSplashScreen && isUserLoggedIn) {
      dispatch(removeInitialLoadings());
    }
  }, [dispatch, isUserLoggedIn, showSplashScreen]);

  const pageQuery = useUrlQuery();
  const aiChatPromptInUrl = pageQuery.get(AppRouting.URL_PARAMS.ONBOARDING.AI_CHAT_PROMPT);
  const promoInUrl = pageQuery.get(AppRouting.URL_PARAMS.PROMO);

  const promoInState = useAppSelector(selectPromo);
  const aiChatPromptInState = useAppSelector(selectAiChatPrompt);

  useEffect(() => {
    // Save the AI chat prompt from the query string to the state if it's different from the state
    if (!!aiChatPromptInUrl && aiChatPromptInUrl !== aiChatPromptInState) {
      dispatch(setAiChatPrompt(aiChatPromptInUrl));
    }
  }, [aiChatPromptInState, aiChatPromptInUrl, dispatch]);

  useEffect(() => {
    // Save the promo from the query string to the state if it's different from the state
    if (!!promoInUrl && promoInUrl !== promoInState) {
      dispatch(setPromo(promoInUrl));
    }
  }, [dispatch, promoInState, promoInUrl]);

  const handleLoginComplete = async () => {
    await createUserOnboarding({ aiChatPrompt: aiChatPromptInState, promo: promoInState });
    history.push(ONBOARDING_ROUTES.SETUP_PROFILE);
  };

  const handleSetupProfileFormSubmit = async (data: OnboardingSetupProfileFormState) => {
    await updateUserOnboarding({
      step: OnboardingStep.SetupProfile,
      dynamicUpdateParams: {
        userName: `${data.firstName} ${data.lastName}`,
        companyLinkedinUrl: data.companyLinkedInUrl as string,
        accountRecruitingUsageType: data.recruitingUsageType ?? AccountRecruitingUsageType.Other,
        phoneNumber: data.phoneNumber as string,
      },
    });

    history.push(ONBOARDING_ROUTES.EMAIL_VERIFICATION);
  };

  const handleEmailVerificationComplete = () => {
    history.push(ONBOARDING_ROUTES.ONBOARDING_COMPLETED);
  };

  // In case the page requires the user to be logged in, don't render it until the user is logged in,
  // otherwise network requests will start firing without a token
  if (isCurrentRouteRequiresUserToBeLoggedIn && !isUserLoggedIn) {
    return null;
  }

  return (
    <AnalyticsProvider productArea={ PRODUCT_AREA.ONBOARDING_PAGE }>
      <Switch>
        <Route exact path="/">
          <Redirect to={ ONBOARDING_ROUTES.REGISTRATION } />
        </Route>

        <Route path={ ONBOARDING_ROUTES.ONBOARDING_COMPLETED } exact>
          <OnboardingCompletedTransitionPage />
        </Route>

        <Route path={ ONBOARDING_ROUTES.REGISTRATION } exact>
          <RegistrationPage onLoginComplete={ handleLoginComplete } />
        </Route>

        <OnboardingStepsLayout>
          <Route path={ ONBOARDING_ROUTES.SETUP_PROFILE } exact>
            <OnboardingSetupProfilePage onFormSubmit={ handleSetupProfileFormSubmit } />
          </Route>

          <Route path={ ONBOARDING_ROUTES.EMAIL_VERIFICATION } exact>
            <OnboardingEmailVerificationPage onVerificationComplete={ handleEmailVerificationComplete } />
          </Route>

        </OnboardingStepsLayout>

        <Route path="*">
          {/* keep query params while navigating */}
          <Redirect to={ `${ONBOARDING_ROUTES.REGISTRATION}${queryParams}` } />
        </Route>
      </Switch>
    </AnalyticsProvider>
  );
};
