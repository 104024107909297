import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import STRING_KEYS from '../../../../../language/keys';
import { ASSETS } from '../../../../../assets';
import { CloseIcon } from '../minimize-chat-button/minimize-chat-button.styled';
import {
  AiTitle,
  ChatButton,
  ChatIcon,
  CloseButton,
  Header,
  Message,
  MessageBubble,
  NliImage,
  Container,
} from './minimized-chat.styled';

export const MinimizedChat = ({
  latestMessage, onClick, hide, isSmallScreen,
}: MinimizedChatProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const closeMessageBubbleHandler = () => {
    setIsVisible(false);
  };

  return (
    <Container>
      { latestMessage && !isSmallScreen && (
        <MessageBubble isVisible={ isVisible && !!latestMessage }>
          <CloseButton onClick={ closeMessageBubbleHandler }>
            <CloseIcon />
          </CloseButton>
          <Header>
            <NliImage src={ ASSETS.NLI_LOGO } />
            <AiTitle>{translate(STRING_KEYS.MATCHES_PAGE.CHAT.AI_PERFECT)}</AiTitle>
          </Header>
          <Message>
            {latestMessage}
          </Message>
        </MessageBubble>
      ) }
      <ChatButton onClick={ onClick } hide={ hide }>
        <ChatIcon />
      </ChatButton>
    </Container>
  );
};
