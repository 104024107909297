import styled from '@emotion/styled';
import { Backdrop, Drawer } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { THEME } from '../../../themes';
import { SidePanelVariant } from './side-panel.enums';

export const SidePanelComponent = styled(Drawer)<DrawerProps>`
  position: relative;
  z-index: ${({ variant }) => variant === SidePanelVariant.Persistent ? 'unset' :  THEME.Z_INDEX.HIGHEST + 1};
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    box-shadow: 0 4px 24px 0 rgb(46 37 128 / 8%);
  }
  .MuiPaper-root {
    z-index: 100;
    border-left: unset;
    border-radius: ${({ variant }) => variant === SidePanelVariant.Temporary ? 'unset' : THEME.RADIUS.CURVY};
    position: ${({ variant }) => variant === SidePanelVariant.Persistent ? 'absolute' : 'fixed'};
    @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MIN}px) {
      border-radius: unset;
      height: 100%;
    }
  }
`;

export const BackdropComponent = styled(Backdrop)`
  background-color: ${THEME.COLORS_RGB.TRUE_BLACK_012};
`;
