export const REDUCER_PATH = 'recruiterAgentApi';
export const POSITION_ID_PATH_PARAM = ':positionId';

export const ROUTES = {
  RECRUITER_AGENT: '/recruiter-agent',
  RECRUITER_AGENT_INFO: `/recruiter-agent/positions/${POSITION_ID_PATH_PARAM}/info`,
  RECRUITER_AGENT_CONVERSATION_BY_POSITION_ID: `/recruiter-agent/positions/${POSITION_ID_PATH_PARAM}/conversations`,
  RECRUITER_AGENT_MESSAGE_CHUNK: `/recruiter-agent/positions/${POSITION_ID_PATH_PARAM}/conversations/message/generating`,
  RECRUITER_AGENT_MESSAGE: `/recruiter-agent/positions/${POSITION_ID_PATH_PARAM}/conversations/message`,
  RECRUITER_AGENT_RETRY_GENERATION: `/recruiter-agent/positions/${POSITION_ID_PATH_PARAM}/conversations/retry-generation`,
};
