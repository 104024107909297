import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Subtitle1Font, Title1Font } from '../../../../../themes/fonts';
import { ASSETS } from '../../../../../assets';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${THEME.SPACING.XGIANT} ${THEME.SPACING.LARGE} ${THEME.SPACING.XGIANT} ${THEME.SPACING.XGIANT};
`;

export const Title = styled.div`
  ${Title1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const Description = styled.p`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  max-width: 640px;
  line-height: 1.5;
`;

export const BottomPart = styled.p`
  position: absolute;
  bottom: ${THEME.SPACING.LARGE};
`;

export const NotificationIcon = styled(ASSETS.BELL)`
  width: 17px;
  height: 18px;
`;

export const FirstParagraphWrapper = styled.div`
  display: flex;
  gap: ${THEME.SPACING.TINY};
`;

export const FirstParagraph = styled.span`
  ${Subtitle1Font};
  font-weight: bold;
  margin-bottom: ${THEME.SPACING.TINY};
`;

export const SecondParagraph = styled.span`
  ${Subtitle1Font};
  opacity: ${THEME.OPACITY.MED};
`;
