import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../../language/keys';
import { THEME } from '../../../../../../themes';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import useWindowDimensions from '../../../../../../hooks/useWindowDimensions';
import {
  AlertActionButton,
  AlertActionIconButton,
  Container,
  LinkedinIcon,
  MissingEmailAlertIcon,
} from './alert-text-editor-header.styled';
import { AlertTextEditorHeaderProps } from './alert-text-editor-header';

const AlertTextEditorHeader = ({
  label,
  infoText,
  backgroundColor,
  actionButtonHandler,
}: AlertTextEditorHeaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { width } = useWindowDimensions();

  const renderAlertActionButton = () => {
    if (width < THEME.BREAKPOINTS.WIDTH.LAPTOP_MIN) {
      return (
        <AlertActionIconButton
          StartIcon={ LinkedinIcon }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          onClick={ () => actionButtonHandler?.() }
        />
      );
    }

    return (
      <AlertActionButton
        variant={ PerfectButtonVariants.Ghost }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        StartIcon={ LinkedinIcon }
        onClick={ () => actionButtonHandler?.() }
      >
        { translate(STRING_KEYS.OUTREACH_PAGE.EMAILS.MISSING_ALERT_ACTION_BUTTON_TEXT) }
      </AlertActionButton>
    );
  };

  return (
    <Container
      label={ label }
      infoText={ infoText }
      Icon={ <MissingEmailAlertIcon /> }
      EndAdornment={ renderAlertActionButton() }
      backgroundColor={ backgroundColor }
    />
  );
};

export default AlertTextEditorHeader;
