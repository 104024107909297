import styled from 'styled-components';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';

export const FilledButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.SURFACE_INVERSE};
`;

export const GhostButton = styled(BaseButton)`
  color: ${THEME.COLORS.SURFACE_INVERSE};
  background-color: ${THEME.COLORS.TRANSPARENT};
`;

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
`;

export const GenAiIcon = styled(ASSETS.STARS_BUBBLE_FILL)`
  width: 14px;
  height: 14px;
  fill: currentColor;
`;
