import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import SelectItem from '../../../../components/Common/select/select-item/select-item.component';
import { useGetAllowedCompaniesQuery } from '../../../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { PerfectButtonSize } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { ReactComponent as CastleIcon } from '../../../../assets/fluentui-emojis/castle_color.svg';
import { ReactComponent as BullseyeIcon } from '../../../../assets/fluentui-emojis/bullseye_color.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/fluentui-emojis/mobile-phone.svg';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import useDebounce from '../../../../hooks/debounce';
import Spinner from '../../../../components/Common/spinner/spinner.component';
import { AllowedCompany } from '../../../../store/rtk-query/apis/onboarding/dto/response/get-allowed-companies.response';
import { ASSETS } from '../../../../assets';
import { OTHER_COMPANY, ROLES } from './onboarding-setup-profile-form.consts';
import {
  Form,
  PerfectButton,
  PerfectInput,
  PerfectPhoneInput,
  PerfectSelect,
} from './onboarding-setup-profile-form.styled';
import { OnboardingSetupProfileFormProps, OnboardingSetupProfileFormState } from './onboarding-setup-profile-form';

export const OnboardingSetupProfileForm = ({
  onSubmit,
}: OnboardingSetupProfileFormProps): JSX.Element => {
  const [companyInputValue, setCompanyInputValue] = useState<string>('');
  const debouncedCompanyInputValue = useDebounce(companyInputValue, 300);

  const {
    data: { allowedCompanies = [] } = {},
    isFetching: isAllowedCompaniesLoading,
  } = useGetAllowedCompaniesQuery(
    { searchQuery: debouncedCompanyInputValue },
    {},
  );

  const { data: user } = useGetMyUserQuery();

  // When registering a user not through social provider, the default first name is the email
  const isUserNameSameAsEmail = user?.email === user?.firstName;

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormik<OnboardingSetupProfileFormState>({
    initialValues: {
      firstName: isUserNameSameAsEmail ? null : user?.firstName ?? null,
      lastName: isUserNameSameAsEmail ? null : user?.lastName ?? null,
      companyLinkedInUrl: allowedCompanies[0]?.sanitizedLinkedInUrl ?? null,
      recruitingUsageType: null,
      phoneNumber: null,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('Required field'),
      lastName: Yup.string().required('Required field'),
      companyLinkedInUrl: Yup.string().required('Required field'),
      recruitingUsageType: Yup.string().required('Required field'),
      phoneNumber: Yup.string().optional().nullable(),
    }),
  });

  const companyOptions: AllowedCompany[] = [...allowedCompanies, OTHER_COMPANY];

  return (
    <Form onSubmit={ handleSubmit }>
      <PerfectInput
        name="firstName"
        placeholder="First name"
        value={ values.firstName ?? '' }
        onChange={ handleChange }
      />

      <PerfectInput
        name="lastName"
        placeholder="Last name"
        value={ values.lastName ?? '' }
        onChange={ handleChange }
      />

      <PerfectSelect
        $spanBothColumns
        onChangeHandler={ (value) => setFieldValue('companyLinkedInUrl', value) }
        placeholder="Your company"
        value={ values.companyLinkedInUrl ?? '' }
        StartAdornment={ <CastleIcon /> }
        isAutocomplete
        placement="bottom"
        onInputChange={ setCompanyInputValue }
        ActionIcon={
          isAllowedCompaniesLoading ? (
            <Spinner width={ 20 } height={ 20 } animationDuration={ 1000 } />
          ) : null
        }
      >
        {companyOptions?.map(({ name, sanitizedLinkedInUrl, logoUrl }) => (
          <SelectItem
            value={ sanitizedLinkedInUrl as string }
            key={ sanitizedLinkedInUrl }
            displayValue={ name }
            imageUrl={ logoUrl ?? ASSETS.EXPERIENCE_FALLBACK }
          >
            <div>{name}</div>
          </SelectItem>
        ))}
      </PerfectSelect>

      <PerfectSelect
        $spanBothColumns
        onChangeHandler={ (value) => setFieldValue('recruitingUsageType', value) }
        placeholder="Recruiting for"
        value={ values.recruitingUsageType ?? '' }
        StartAdornment={ <BullseyeIcon /> }
        placement="bottom"
      >
        {ROLES.map(({ label, value }) => (
          <SelectItem key={ value } value={ value } displayValue={ label }>
            <div>{label}</div>
          </SelectItem>
        ))}
      </PerfectSelect>

      <PerfectPhoneInput
        $spanBothColumns
        name="phoneNumber"
        placeholder="Phone number (optional)"
        value={ values.phoneNumber ?? '' }
        StartAdornment={ <PhoneIcon /> }
        onChange={ (value) => setFieldValue('phoneNumber', value) }
      />

      <PerfectButton
        $spanBothColumns
        type="submit"
        size={ PerfectButtonSize.ExtraLarge }
        isLoading={ isSubmitting }
        disabled={ !isValid }
      >
        Continue
      </PerfectButton>
    </Form>
  );
};
