export enum OnboardingStep {
  /** @deprecated */
  Registration = 'REGISTRATION',
  /** @deprecated */
  TransitionWelcomeUser = 'TRANSITION_WELCOME_USER',
  /** @deprecated */
  LetsBegin = 'LETS_BEGIN',
  /** @deprecated */
  Experience = 'EXPERIENCE',
  /** @deprecated */
  Background = 'BACKGROUND',
  /** @deprecated */
  Education = 'EDUCATION',
  /** @deprecated */
  JobDescription = 'JOB_DESCRIPTION',
  SetupProfile = 'SETUP_PROFILE',
  EmailVerification = 'EMAIL_VERIFICATION',
  OnboardingCompleted = 'ONBOARDING_COMPLETED',
}

export enum AccountRecruitingUsageType {
  RecruiterForOwnCompany = 'RECRUITER_FOR_OWN_COMPANY',
  RecruiterForOtherCompanies = 'RECRUITER_FOR_OTHER_COMPANIES',
  Other = 'OTHER',
}
