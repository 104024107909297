import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { MenuItemSelectedVariant } from './menu-item.enum';

const iconSelectedVariantStyle = css`
  padding: 0 ${THEME.SPACING.BASE} 0 ${THEME.SPACING.XLOOSE};
`;

const backgroundColorSelectedVariantStyle = css`
  padding: 0 ${THEME.SPACING.BASE};
`;

const disabledStyle = css`
  opacity: ${THEME.OPACITY.MED};
  cursor: not-allowed;
`;

export const MenuItemContainer = styled.li<{
  disabled: boolean;
  isSubMenu: boolean;
  selectedVariant: MenuItemSelectedVariant;
}>`
  box-sizing: border-box;
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 ${THEME.SPACING.BASE};
  cursor: pointer;
  ${({ disabled }) => (disabled ? disabledStyle : undefined)};
  ${({ selectedVariant }) => selectedVariant === MenuItemSelectedVariant.Icon ?
    iconSelectedVariantStyle :
    backgroundColorSelectedVariantStyle};

  :hover,
  :focus {
    background-color: ${THEME.COLORS.ACCENT};
    color: ${THEME.COLORS.PURE_WHITE};
  }
`;

export const SelectedIcon = styled(ASSETS.V_SIGN)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${THEME.SPACING.BASE};
  height: 10px;
`;

export const Image = styled.img`
  height: 30px;
  width: 30px;
`;
