import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { USER_AVATAR_SIZE_PX } from '../../pages/home/home.styled';
import STRING_KEYS from '../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import AvatarsList from '../../../../components/Common/AvatarsList';
import { THEME } from '../../../../themes';
import { PERMISSIONS } from '../../../../consts/permissions.const';
import { useGetAccountUsersQuery } from '../../../../store/rtk-query/apis/user/hooks/get-account-users.query-hook';
import { usePermissionCheck } from '../../../../hooks/use-permissions.hook';
import Tooltip from '../../../../components/tooltip/tooltip.component';
import {
  Container,
  AddUserIcon,
  AddUserIconWrapper,
  BodyHeaderRightContainer,
  BodyTitle,
  CreatePositionButton,
  UsersAvatarListLabel,
  UsersAvatarListWrapper,
  VerticalDivider,
  CreatePositionButtonIcon,
  ActivePositionsWidgetWrapper,
} from './homepage-body-header.styled';
import ActivePositionsWidget from './active-positions-widget/active-positions-widget.component';

const HomepageBodyHeader = ({
  createPositionButtonClickHandler, inviteUserButtonClickHandler, upgradeButtonClickHandler, isPositionCreateLoading,
}: HomepageBodyHeaderProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: users } = useGetAccountUsersQuery();
  const { allowed: allowedInvite } = usePermissionCheck([PERMISSIONS.user.inviteAll, PERMISSIONS.user.inviteCollaborator]);
  const { allowed: allowedCreatePosition } = usePermissionCheck([PERMISSIONS.position.createAll]);

  const avatarListIcons = useMemo(() => {
    return users?.map((user) => {
      return {
        userName: user?.fullName || user?.email || '',
        profileImage: user?.profilePictureUrl,
      };
    });
  }, [users]);

  const renderUsersAvatars = () => {
    return (
      <div>
        <UsersAvatarListLabel>
          { translate(STRING_KEYS.HOMEPAGE.TEAMMATES_LABEL) }
        </UsersAvatarListLabel>
        <UsersAvatarListWrapper onClick={ inviteUserButtonClickHandler }>
          <AvatarsList
            maxNumberInGroup={ 5 }
            iconSize={ USER_AVATAR_SIZE_PX }
            iconBorderWidthPx={ 0 }
            iconOutlineWidthPx={ 2 }
            spacing={ 3 }
            iconOutlineColor={ THEME.COLORS.LIGHT_GREY }
            icons={ avatarListIcons }
          />
          { allowedInvite && (
            <AddUserIconWrapper>
              <AddUserIcon />
            </AddUserIconWrapper>
          )}
        </UsersAvatarListWrapper>
      </div>
    );
  };

  const renderCreatePositionButton = () => {
    const Button = (
      <CreatePositionButton
        StartIcon={ CreatePositionButtonIcon }
        onClick={ createPositionButtonClickHandler }
        size={ PerfectButtonSize.Large }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        isLoading={ isPositionCreateLoading }
        disabled={ !allowedCreatePosition }
      >
        { translate(STRING_KEYS.CREATE_POSITION.CREATE_POSITION_OPEN_BUTTON) }
      </CreatePositionButton>
    );
    if (!allowedCreatePosition) {
      return (
        <Tooltip
          content={ translate(STRING_KEYS.CREATE_POSITION.CREATE_POSITION_OPEN_BUTTON_TOOLTIP) }
        >
          { Button }
        </Tooltip>
      );
    }

    return Button;
  };

  return (
    <Container>
      <BodyTitle>{ translate(STRING_KEYS.HOMEPAGE.POSITIONS_TITLE) }</BodyTitle>
      <BodyHeaderRightContainer>
        { renderUsersAvatars() }
        <VerticalDivider />
        <ActivePositionsWidgetWrapper>
          <ActivePositionsWidget upgradeButtonClickHandler={ upgradeButtonClickHandler } />
        </ActivePositionsWidgetWrapper>
        { renderCreatePositionButton() }
      </BodyHeaderRightContainer>
    </Container>
  );
};

export default HomepageBodyHeader;
