import { PositionResponse } from '../dto/response/position.response';

export const getPositionByIdResponseTransformer = (response: PositionResponse): PositionResponse => {
  response.latestRun = {
    triggeredAt: response.latestRun?.triggeredAt || response?.lastEngagedAt,
    status: response.latestRun?.status,
    noResults: response.latestRun?.noResults,
    deadEndRecommendations: response.latestRun?.deadEndRecommendations,
    triggerType: response.latestRun?.triggerType,
  };

  return response;
};
