import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { Body2Font, CaptionFont } from '../../../../../../themes/fonts';
import { fadeIn } from '../chat-message.styled';
import { ConversationMessageType } from '../../../../../../enums/conversation-message-type.enum';

export const Container = styled.div<{ sender: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.sender === ConversationMessageType.AgentMessage ? 'flex-start' : 'flex-end'};
  align-self: flex-end;
  max-width: 75%;
  width: 100%;
  gap: ${THEME.SPACING.MICRO};
  
  animation: ${fadeIn} 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: both;
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const AvatarWrapper = styled.span`
  align-self: flex-end;
  height: 24px;
`;

export const UserAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: ${THEME.RADIUS.CIRCLE};
`;

export const ChatBubble = styled.div<{ sender: string }>`
  ${Body2Font};
  width: 100%;
  padding: ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.CURVY};
  border-bottom-right-radius: 0;
  background-color: ${THEME.COLORS.SURFACE_INVERSE};
  color: ${THEME.COLORS.PURE_WHITE};
  align-self: flex-end;
  word-wrap: break-word;
  display: inline-block;
`;

export const UserName = styled.div`
  ${CaptionFont};
  display: flex;
  opacity: ${THEME.OPACITY.MED};
  font-weight: 500;
  margin-bottom: ${THEME.SPACING.TINY};
  margin-right: ${THEME.SPACING.MICRO};
  align-self: flex-end;
`;
