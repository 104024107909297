export const ONBOARDING_ROUTES = {
  REGISTRATION: '/registration',
  SETUP_PROFILE: '/setup-profile',
  EMAIL_VERIFICATION: '/email-verification',
  ONBOARDING_COMPLETED: '/completed',
};

export const ROLES = {
  RECRUITER: 'RECRUITER',
  MANAGER: 'MANAGER',
};

export const ONBOARDING_PAGE_TITLES = {
  REGISTRATION: 'Onboarding - Registration | Perfect',
  LETS_BEGIN: 'Onboarding - Lets Begin | Perfect',
  EXPERIENCE: 'Onboarding - Experience | Perfect',
  INDUSTRIES: 'Onboarding - Industries | Perfect',
  EDUCATION: 'Onboarding - Education | Perfect',
  SKILLS: 'Onboarding - Skills | Perfect',
  JOB_DESCRIPTION: 'Onboarding - Job Description | Perfect',
  EMAIL_VERIFICATION: 'Onboarding - Email Verification | Perfect',
  ONBOARDING_COMPLETED: 'Onboarding - All Done | Perfect',
};
