import React, { useState } from 'react';
import { useAuthActions } from '@frontegg/react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../Common/Buttons/PrimaryButton';
import STRING_KEYS from '../../../language/keys';
import { isValidEmail } from '../../../utils';
import {
  FormContainer, StyledForm, SubmitButtonWrapper, ValidationError,
} from './styled';
import { EmailInput } from './EmailInput';

type ForgotPasswordFormProps = {
  errorMessage: string,
};

function ForgotPasswordForm({ errorMessage }: ForgotPasswordFormProps):JSX.Element {
  const { t: translate } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const actions = useAuthActions();

  const resetErrors = () => {
    setEmailError('');
  };

  const submitForm = () => {
    resetErrors();

    let hasErrors = false;

    if (!email) {
      setEmailError(
        translate(
          STRING_KEYS.PLEASE_ENTER_INPUT_VALUE,
          { inputName: translate(STRING_KEYS.EMAIL) },
        ),
      );
      hasErrors = true;
    } else if (!isValidEmail(email)) {
      setEmailError(
        translate(
          STRING_KEYS.PLEASE_ENTER_VALID_VALUE,
          { valueName: translate(STRING_KEYS.EMAIL_ADDRESS) },
        ),
      );
      hasErrors = true;
    }

    if (!hasErrors) {
      actions.forgotPassword({ email });
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitForm();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError('');
    actions.resetLoginState();
    setEmail(event.currentTarget.value);
  };

  return (
    <FormContainer>
      <StyledForm onSubmit={ handleFormSubmit }>
        <EmailInput
          onChange={ handleEmailChange }
          isError={ !!emailError || !!errorMessage }
          error={ emailError }
        />
        {errorMessage && (
          <ValidationError>
            { errorMessage }
          </ValidationError>
        )}
        <SubmitButtonWrapper
          isLoading={ false }
        >
          <PrimaryButton
            title={ translate(STRING_KEYS.RESET_MY_PASSWORD) }
            type="submit"
            disableMargin
          />
        </SubmitButtonWrapper>
      </StyledForm>
    </FormContainer>
  );
}

export default ForgotPasswordForm;
