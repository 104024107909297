import React from 'react';
import { ChatActionsProps } from './chat-actions';
import { ActionButton, ActionContainer } from './chat-actions.styled';

export const ChatActions = ({ message, onActionClick }: ChatActionsProps): JSX.Element => {
  return (
    <ActionContainer>
      {message.actions?.map((action) => (
        <ActionButton
          key={ action }
          onClick={ () => onActionClick?.(action) }
          disabled={ message.actionsDisabled }
        >
          {action}
        </ActionButton>
      ))}
    </ActionContainer>
  );
};
