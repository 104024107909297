import { COLORS } from '../consts/colors';
import { COLORS_FILTERS } from '../consts/colors_filters';
import { COLORS_RGB } from '../consts/colors_rgb';

export const THEME = {
  FILTERS: {
    PRIMARY_FILTER: COLORS_FILTERS.DARK_PURPLE,
    SECONDARY_FILTER: COLORS_FILTERS.LIGHT_GREEN,
    BLACK_FILTER: COLORS_FILTERS.BLACK,
    CONTENT_FILTER: COLORS_FILTERS.DARK_CONTENT,
  },
  COLORS_RGB: {
    SECONDARY_005: COLORS_RGB.SECONDARY_005,
    SECONDARY_064: COLORS_RGB.SECONDARY_064,
    SECONDARY_024: COLORS_RGB.SECONDARY_024,
    SECONDARY_012: COLORS_RGB.SECONDARY_012,
    TRUE_BLACK_012: COLORS_RGB.TRUE_BLACK_012,
    CONTENT_A_034: COLORS_RGB.CONTENT_A_034,
    SUCCESS_012: COLORS_RGB.GREEN_012,
    SUCCESS_024: COLORS_RGB.GREEN_024,
    ACCENT_012: COLORS_RGB.ACCENT_012,
    ACCENT_024: COLORS_RGB.ACCENT_024,
    ACCENT_005: COLORS_RGB.ACCENT_005,
    PRIMARY_DARK_012: COLORS_RGB.PRIMARY_DARK_012,
    LIGHT_RED: COLORS_RGB.LIGHT_RED,
    LIGHT_GRAY: COLORS_RGB.LIGHT_GRAY,
    WHITE_DIVIDER: COLORS_RGB.WHITE_DIVIDER,
    PRIMARY_DARK_GHOST: COLORS_RGB.PRIMARY_DARK_GHOST,
    PRIMARY_DIVIDER: COLORS_RGB.LIGHT_PURPLE,
    CONTENT_PRIMARY_MED: COLORS_RGB.CONTENT_PRIMARY_MED,
    CONTENT_PRIMARY_GHOST: COLORS_RGB.CONTENT_PRIMARY_GHOST,
    CONTENT_PRIMARY_034: COLORS_RGB.CONTENT_PRIMARY_034,
    CONTENT_PRIMARY_LOW: COLORS_RGB.CONTENT_PRIMARY_LOW,
    CONTENT_PRIMARY_012: COLORS_RGB.CONTENT_PRIMARY_012,
    ACCENT_DIVIDER: COLORS_RGB.ACCENT_012,
    ACCENT_GHOST: COLORS_RGB.ACCENT_08,
    ACCENT_MED: COLORS_RGB.ACCENT_06,
    PRIMARY_DARK_DIVIDER: COLORS_RGB.PRIMARY_DARK_DIVIDER,
    GHOST_GRAY: COLORS_RGB.GRAY_GHOST,
    LOW_GRAY: COLORS_RGB.LOW_GRAY,
    BLACK_DIVIDER: COLORS_RGB.BLACK_DIVIDER,
    GREY_MED: COLORS_RGB.GREY_MED,
    PRIMARY_GHOSTLY: COLORS_RGB.PRIMARY_GHOSTLY,
    PRIMARY_DARK_MED: COLORS_RGB.PRIMARY_DARK_MED,
    PRIMARY_DARK_LOW: COLORS_RGB.PRIMARY_DARK_LOW,
    ALERT_045: COLORS_RGB.ALERT_045,
    ALERT_008: COLORS_RGB.ALERT_008,
    WARNING: COLORS_RGB.WARNING,
    WARNING_LOW: COLORS_RGB.WARNING_LOW,
    PRIMARY_GHOST: COLORS_RGB.PRIMARY_GHOST,
    YELLOW_012: COLORS_RGB.YELLOW_012,
    YELLOW_024: COLORS_RGB.WARNING_LOW,
    YELLOW_064: COLORS_RGB.YELLOW_064,
  },
  COLORS: {
    PRIMARY: COLORS.PURPLE,
    PRIMARY_DARK: COLORS.DARK_PURPLE,
    SECONDARY: COLORS.LIGHT_PURPLE,
    ACCENT: COLORS.PINK,
    DECOR: COLORS.BLUE,
    GREY: COLORS.GREY,
    MID_GREY: COLORS.MID_GREY,
    LIGHT_GREY: COLORS.LIGHT_GREY,
    LIGHT_PINK: COLORS.LIGHT_PINK,
    SUCCESS: COLORS.GREEN,
    WARNING: COLORS.YELLOW,
    ALERT: COLORS.RED,
    NEUTRAL: COLORS.OPACITY,
    PURE_WHITE: COLORS.WHITE0,
    SURFACE_PRIMARY: COLORS.WHITE1,
    CONTENT_PRIMARY: COLORS.BLACK2,
    SURFACE_INVERSE: COLORS.BLACK3,
    CONTENT_INVERSE: COLORS.WHITE3,
    CONTENT_A: COLORS.DARK_BLUE,
    TRUE_BLACK: COLORS.BLACK,
    TRANSPARENT: COLORS.TRANSPARENT,
  },
  FONT_SIZE: {
    TINY: '10px',
    TINO: '12px',
    BASE: '14px',
    MEDIUM: '16px',
    LARGE: '20px',
    XLARGE: '24px',
    GRAND: '32px',
    XGRAND: '44px',
    GIANT: '48px',
    XGIANT: '56px',
  },
  SPACING: {
    NANO: '2px',
    MICRO: '4px',
    TINY: '8px',
    TINO: '12px',
    BASE: '16px',
    MEDIUM: '24px',
    LOOSE: '32px',
    XLOOSE: '40px',
    LARGE: '48px',
    XLARGE: '56px',
    GRAND: '64px',
    XGRAND: '72px',
    GIANT: '80px',
    XGIANT: '96px',
  },
  RADIUS: {
    SHARP: '0px',
    NANO: '3px',
    TIDY: '4px',
    BASE: '8px',
    ROUNDISH: '12px',
    ROUND: '16px',
    CURVY: '24px',
    LOOPY: '32px',
    CIRCLE: '999px',  // Israel's asked
  },
  OPACITY: {
    HIGHEST: 1,
    HIGH: 0.9,
    MED: 0.6,
    LOW: 0.34,
    FOCUS: 0.24,
    DIVIDER: 0.12,
    GHOST: 0.08,
    GHOSTLY: 0.05,
    TRANSPARENT: 0,
  },
  FONT_FAMILY: {
    MERRIWEATHER: 'Merriweather',
    PERFECT_FEATURES: 'Perfect features',
  },
  SPECIAL_CHARACTERS: {
    DOUBLE_CHECK: '"\\0041"',
    CHECK: '"\\0042"',
    LIKED: '"\\004c"',
    PASSED: '"\\0058"',
    PERFECT_ICON: '"\\0050"',
    LIKE_OUTLINED: '"\\006c"',
    PASS_OUTLINED: '"\\0078"',
    TOKEN: '"\\0074"',
    SCHEDULE_SEND: '"\\0043"',
  },
  UNKNOWN_COLORS: {
    NAVY: COLORS.DARK_BLUE,
    ACCENT_005_HEX: '#fff3f7',
    PRIMARY_005_HEX: '#f2f2f8',
  },
  BREAKPOINTS: {
    WIDTH: {
      TABLET: 1000,
      LAPTOP_MIN: 1240,
      LAPTOP_MAIN: 1366,
      LAPTOP_BASE: 1440,
      DESKTOP: 1920,
      LARGE: 1760,
    },
    HEIGHT: {
      XSMALL: 450,
      SMALL: 560,
    },
  },
  Z_INDEX: {
    HIGH: 1302,
    HIGHEST: 2302,
  },
};
