import { GetQuery, PatchQuery, PostQuery } from '../../@types/rtk-query';
import { QueryMethods } from '../../enums/query-methods.enums';
import { ROUTES } from './onboarding.consts';
import { UpdateOnboardingProgressRequest } from './dto/request/update-onboarding-progress.request';
import { CreateUserOnboardingRequest } from './dto/request/create-user-onboarding.request';
import { GetAllowedCompaniesRequest } from './dto/request/get-allowed-companies.request';

export const getOnboardingQuery = (): GetQuery => ({
  url: `${ROUTES.ONBOARDING}/progress`,
  method: QueryMethods.Get,
});

export const createOnboardingQuery = ({ aiChatPrompt, promo }:CreateUserOnboardingRequest): PostQuery<CreateUserOnboardingRequest> => ({
  url: ROUTES.ONBOARDING,
  method: QueryMethods.Post,
  data: { aiChatPrompt, promo },
});

export const updateOnboardingProgressQuery = ({ step, dynamicUpdateParams }: UpdateOnboardingProgressRequest): PatchQuery<UpdateOnboardingProgressRequest> => {
  return ({
    url: ROUTES.ONBOARDING,
    method: QueryMethods.Patch,
    data: { step, dynamicUpdateParams },
  });
};

export const getAllowedCompaniesQuery = ({ searchQuery, limit }: GetAllowedCompaniesRequest): GetQuery => ({
  url: `${ROUTES.ALLOWED_COMPANIES}`,
  method: QueryMethods.Get,
  params: { searchQuery: searchQuery ?? '', limit: (limit ?? 5).toString() },
});

export const sendVerificationEmailQuery = (): PostQuery<undefined> => ({
  url: `${ROUTES.ONBOARDING}/send-verification-email`,
  method: QueryMethods.Post,
});

export const verifyEmailQuery = ({
  code,
}: {
  code: string;
}): PostQuery<undefined> => ({
  url: `${ROUTES.ONBOARDING}/verify-email/${code}`,
  method: QueryMethods.Post,
});
