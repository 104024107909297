import {
  Actions,
  Container,
  MessageRow,
  Text,
} from './chat-snackbar.styled';
import { ChatSnackbarProps } from './chat-snackbar';

export const ChatSnackbar = ({ text, Icon, firstAction }: ChatSnackbarProps): JSX.Element => {
  return (
    <Container>
      <MessageRow>
        { Icon }
        <Text>
          { text }
        </Text>
      </MessageRow>
      <Actions>
        { firstAction }
      </Actions>
    </Container>
  );
};
