import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import BaseButton from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';
import { THEME } from '../../../../../themes';

export const SlothIcon = styled(ASSETS.SLOTH_ON_TREE_2)`
`;

export const LianaIcon = styled(ASSETS.LIANA)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const FilledButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.SURFACE_INVERSE};
`;

export const GhostButton = styled(BaseButton)`
  color: ${THEME.COLORS.SURFACE_INVERSE};
  background-color: ${THEME.COLORS.TRANSPARENT};
`;
