import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { SIDE_PANEL_WIDTH_EXTRA_SMALL, SIDE_PANEL_WIDTH_MEDIUM, SIDE_PANEL_WIDTH_SMALL } from '../match-side-panel/match-side-panel.styled';
import { calculateOutreachPageHeight } from './outreach.utils';

const getGridTemplateCss = (isAutoMode: boolean, isStepSelected: boolean, manualWidth: number, isLoading?: boolean) => {
  if (isAutoMode) {
    if (!isStepSelected || isLoading) {
      return `${SIDE_PANEL_WIDTH_EXTRA_SMALL}px 1fr`;
    }

    if (isStepSelected) {
      return `calc(${SIDE_PANEL_WIDTH_EXTRA_SMALL + SIDE_PANEL_WIDTH_SMALL}px + ${THEME.SPACING.TINY}) 1fr`;
    }
  }

  return `${manualWidth}px 1fr`;
};

export const LayoutContainer = styled.div<
  { isGlobalBannerVisible: boolean, isAutoMode: boolean, isStepSelected: boolean, isLoading?: boolean, isBannerVisible: boolean }
>`
  display: grid;
  grid-template-columns: ${({ isAutoMode, isStepSelected, isLoading }) => getGridTemplateCss(isAutoMode, isStepSelected, SIDE_PANEL_WIDTH_MEDIUM, isLoading)};
  padding: 0 ${THEME.SPACING.BASE} ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  grid-gap: ${THEME.SPACING.BASE};
  height: ${({ isBannerVisible, isGlobalBannerVisible }) => calculateOutreachPageHeight({ isBannerVisible, isGlobalBannerVisible })};
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE}px) {
    grid-template-columns: ${({ isAutoMode, isStepSelected, isLoading }) => getGridTemplateCss(isAutoMode, isStepSelected, SIDE_PANEL_WIDTH_SMALL, isLoading)};
  }
  transition: grid-template-columns 300ms;
`;

export const PageContainer = styled.div<{isAutoMode: boolean, fullWidth?: boolean, isStepSelected?: boolean}>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => fullWidth ? '1fr' : `1fr ${SIDE_PANEL_WIDTH_MEDIUM}px`};
  grid-gap: ${THEME.SPACING.BASE};
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LARGE}px) {
    grid-template-columns: ${({ isStepSelected, isAutoMode }) => {
    if (isAutoMode && isStepSelected) {
      return '1fr';
    }
    
    return '';
  }};
  }
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE}px) {
    grid-template-columns: 1fr;
  }
  overflow: hidden;
`;

export const OutreachPageBodyContent = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 ${THEME.SPACING.LOOSE};
  position: relative;

  ${({ disabled }) => disabled && (`
    &:before {
      content: '';
      position: absolute;
      background-color: ${THEME.COLORS.PURE_WHITE};
      opacity: ${THEME.OPACITY.MED};
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `)}
`;

export const BannerContainer = styled.div`
  margin: ${THEME.SPACING.TINY} ${THEME.SPACING.BASE};
  border-radius: ${THEME.RADIUS.ROUND};
  overflow: hidden;
  flex-shrink: 0;
  z-index: 112;
  background-color: ${THEME.COLORS.PURE_WHITE};
`;
