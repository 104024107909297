import { PropsWithChildren } from 'react';
import { PerfectLogo } from '../../PerfectLogo';
import { Container } from './page-header.styled';

export const PageHeader = ({
  children, logoSize, borderBottomDisable, isSticky,
}: PropsWithChildren<PageHeaderProps>):JSX.Element => {
  return (
    <Container borderBottomDisable={ borderBottomDisable || false } isSticky={ isSticky || false }>
      <PerfectLogo size={ logoSize } />
      { children }
    </Container>
  );
};

PageHeader.defaultProps = {
  children: undefined,
  logoSize: undefined,
  borderBottomDisable: false,
  isSticky: false,
};
