export enum MatchesPageContentType {
  PositionStatistics = 'POSITION_STATISTICS',
  DailyMatchLimit = 'DAILY_MATCH_LIMIT',
  MatchingProgress = 'MATCHING_PROGRESS',
  PositionAdjustmentsDefault = 'POSITION_ADJUSTMENTS_DEFAULT',
  PositionAdjustmentsRecommendations = 'POSITION_ADJUSTMENTS_RECOMMENDATIONS',
  PositionNoResultsError = 'POSITION_NO_RESULTS_ERROR',
  MatchesCarousel = 'MATCHES_CAROUSEL',
  EmptyState= 'EMPTY_STATE',
}
