import { Trans, useTranslation } from 'react-i18next';
import {
  MouseEvent, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import STRING_KEYS from '../../../../../language/keys';
import Tooltip from '../../../../../components/tooltip/tooltip.component';
import { useGetMyAccountSelector } from '../../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import {
  ExportMatchesIcon,
  ToReachoutIcon,
  ToReplyCardIcon,
  ToReviewCardDailyLimitBodyIcon,
  ToReviewCardIcon,
  ToReviewCardMatchingProgressBodyIcon,
} from '../../../pages/position-overview-page/position-overview.styled';
import { THEME } from '../../../../../themes';
import { MATCH_STATUSES } from '../../../../../consts';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import { ConversationStatusEnum } from '../../../../../store/rtk-query/apis/outreach/enums/conversation-status.enum';
import { MatchingStatus } from '../../../../../enums/matching-status.enum';
import PositionCardSettings from '../../position-card-settings/position-card-settings.component';
import {
  ANALYTICS_EVENTS,
  EVENT_GROUP,
  POSITION_CARD_COMPONENT_ELEMENT_TYPE,
} from '../../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import InviteColleagueModal
  from '../../../../../components/dialogs/invite-colleague-modal/invite-colleague-dialog.component';
import { PositionState } from '../../../../../enums/position-state.enum';
import { IconButtonVariants } from '../../../../../components/Common/Buttons/icon-button/icon-button.enums';
import { PerfectButtonHoverVariants } from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import UserPickersContainer from '../../user-pickers-container/user-pickers-container.component';
import { useShouldLockFeature } from '../../../../../hooks/should-lock-feature.hook';
import {
  AtsConnected,
  AtsIcon,
  AtsTooltipConnected,
  BodyContainer,
  Divider, DraftTag,
  FooterContainer,
  HeaderContainer,
  LinkedAts,
  LinkedAtsJobTitle,
  PositionCardContainer,
  PositionInfoBottom,
  PositionTitleContainer,
  PositionTitleWrapper,
  SettingsButton,
  SettingsButtonIcon,
} from '../position-card.styled';
import StatisticCard from '../statistic-card/statistic-card.component';
import PositionLocation from '../../../../../components/Common/position-location/position-location.component';
import { useExportEngagedMatchesCSV } from '../../../hooks/export-csv.hook';
import { InfoSnackBar } from '../../../../../components/Common/CustomSnackBar/InfoSnackBar';
import { UpgradePlanDialog } from '../../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { TimeAgoItem } from '../../../../../components/Common/TimeAgoItem';
import { PositionStatus } from '../../../../../store/rtk-query/apis/position/enums/position-status.enum';
import { OpenPositionCardProps } from './open-position-card';
import {
  Label,
  LastUpdated,
  MiddleDot,
  PositionCreated,
  TimeAgoItemWrapper,
} from './open-position-card.styled';

const POSITION_ITEM_COMPONENT_NAME = 'position_card';
const POSITION_ITEM_COMPONENT_PARENT_NAME = 'all_positions';
const POSITION_MENU_COMPONENT_NAME = 'position_card_menu';
const POSITION_MENU_COMPONENT_PARENT_NAME = 'position_card';
const CLOSE_POSITION_ELEMENT_TYPE = 'close_position';

const MAX_USER_PICKER_AVATARS = 4;

const OpenPositionCard = ({
  position,
  closePositionHandler,
  atsInitializationHandler,
  positionIndex,
}: OpenPositionCardProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const { data: { atsIntegration } } = useGetMyAccountSelector();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const isLocked = useShouldLockFeature();
  const isPositionClosed = useMemo(() => position.state !== PositionState.Open, [position?.state]);
  const isPositionDraft = useMemo(() => position.status === PositionStatus.Draft, [position?.status]);
  const [isInfoSnackBarOpen, setIsInfoSnackBarOpen] = useState(false);
  const [infoSnackBarText, setInfoSnackBarText] = useState('');
  const exportCSV = useExportEngagedMatchesCSV(position?.id, position?.jobTitle);

  const sendPositionTrackEvent = (
    eventName: string,
    componentName: string,
    componentParentName: string,
    componentElementType?: string,
  ) => {
    const trackEventPayload = {
      eventName,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.HOME_PAGE,
      componentPositionId: position?.id,
      componentPositionName: position?.jobTitle,
      componentPositionStatus: position?.status,
      componentName,
      componentPositionInParent: (positionIndex || 0).toString(),
      componentParentName,
      componentElementType,
    };
    analyticsTrackEvent(trackEventPayload);
  };

  const sendPositionClickTrackEvent = (componentElementType: string) => {
    sendPositionTrackEvent(
      ANALYTICS_EVENTS.POSITION_ITEM_CLICKED,
      POSITION_ITEM_COMPONENT_NAME,
      POSITION_ITEM_COMPONENT_PARENT_NAME,
      componentElementType,
    );
  };

  const onClickAtsInitialization = () => {
    if (isLocked) {
      setIsUpgradeDialogOpen(true);
    } else {
      atsInitializationHandler(position.id);
    }
  };

  const onClickClosePosition = () => {
    closePositionHandler(position.id);

    sendPositionTrackEvent(
      ANALYTICS_EVENTS.POSITION_CLOSE_CLICK,
      POSITION_MENU_COMPONENT_NAME,
      POSITION_MENU_COMPONENT_PARENT_NAME,
      CLOSE_POSITION_ELEMENT_TYPE,
    );
  };

  const onPositionCardClick = () => {
    if (isPositionClosed) {
      return;
    }

    sendPositionClickTrackEvent(POSITION_CARD_COMPONENT_ELEMENT_TYPE.REGULAR);

    if (isPositionDraft) {
      history.push(AppRouting.buildMatchesPageUrl(position.id));

      return;
    }

    history.push(AppRouting.buildPositionOverviewPageUrl(position.id));
  };

  const onToReviewCardClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    sendPositionClickTrackEvent(POSITION_CARD_COMPONENT_ELEMENT_TYPE.TO_REVIEW);
    history.push(AppRouting.buildMatchesPageUrl(position.id));
  };

  const handleExportCSVClick = async () => {
    await exportCSV();
    setInfoSnackBarText(translate(STRING_KEYS.EXPORT_MATCHES.SUCCESS_MESSAGE));
    setIsInfoSnackBarOpen(true);
  };

  const onToReachoutCardClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    sendPositionClickTrackEvent(POSITION_CARD_COMPONENT_ELEMENT_TYPE.TO_REACHOUT);
    history.push(AppRouting.buildPositionConversationsPageUrl(position.id, ConversationStatusEnum.New));
  };

  const onToReplyCardClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    sendPositionClickTrackEvent(POSITION_CARD_COMPONENT_ELEMENT_TYPE.TO_REPLY);
    history.push(AppRouting.buildPositionConversationsPageUrl(position.id, ConversationStatusEnum.Replied));
  };

  const renderTag = () => {
    const isPositionConnectedToAts = !!position.linkedAtsJob;

    if (isPositionDraft) {
      return (
        <DraftTag />
      );
    }
    if (isPositionConnectedToAts) {
      return (
        <Tooltip
          content={ (
            <AtsTooltipConnected>
              <LinkedAts>
                { translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.LABEL_ATS) }
                {/* // TODO [homepage]: should add ATS icon */}
                { atsIntegration?.atsName }
              </LinkedAts>
              <LinkedAtsJobTitle>{ position.linkedAtsJob?.jobTitle || position.jobTitle }</LinkedAtsJobTitle>
            </AtsTooltipConnected>
          ) }
        >
          <AtsConnected>
            <AtsIcon />
          </AtsConnected>
        </Tooltip>
      );
    }

    return null;
  };

  const toReviewMatches = useMemo(() => {
    return position.statistics?.engagementStatusStatistics?.find((statistics) => statistics.name === MATCH_STATUSES.TO_REVIEW)?.amount || 0;
  }, [position.statistics]);

  const ToReviewCardBodyIcon = useMemo(() => {
    if (position.matchingStatus === MatchingStatus.DailyLimit) {
      return ToReviewCardDailyLimitBodyIcon;
    } if (position.matchingStatus === MatchingStatus.MatchingInTheMaking) {
      return ToReviewCardMatchingProgressBodyIcon;
    }

    return null;
  }, [position]);

  const toReviewCardTitle = useMemo(() => {
    if (position.matchingStatus === MatchingStatus.DailyLimit) {
      return translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE_DAILY_LIMIT);
    }
    if (position.matchingStatus === MatchingStatus.MatchingInTheMaking) {
      return translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE_MATCHING_IN_THE_MAKING);
    }

    if (position.matchingStatus === MatchingStatus.Failed || toReviewMatches < 1) {
      return translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE_EMPTY);
    }

    return translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REVIEW.TITLE);
  }, [position.matchingStatus, toReviewMatches, translate]);

  const renderStatisticsCards = () => {
    if (position.status === PositionStatus.Draft) {
      return null;
    }

    return (
      <>
        <StatisticCard
          title={ toReviewCardTitle }
          TitleIcon={ ToReviewCardIcon }
          count={ toReviewMatches }
          BodyIcon={ ToReviewCardBodyIcon }
          activeColor={ THEME.COLORS.ACCENT }
          clickHandler={ onToReviewCardClick }
        />
        <StatisticCard
          title={ translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REACHOUT.TITLE) }
          TitleIcon={ ToReachoutIcon }
          count={ position.statistics?.newConversations || 0 }
          activeColor={ THEME.COLORS.SECONDARY }
          clickHandler={ onToReachoutCardClick }
        />
        <StatisticCard
          title={ translate(STRING_KEYS.HOMEPAGE.POSITION_STATISTICS_CARDS.TO_REPLY.TITLE) }
          TitleIcon={ ToReplyCardIcon }
          count={ position.statistics?.newRepliedConversations || 0 }
          activeColor={ THEME.COLORS.DECOR }
          clickHandler={ onToReplyCardClick }
        />
      </>
    );
  };

  return (
    <>
      <PositionCardContainer
        onClick={ onPositionCardClick }
        isPositionClosed={ isPositionClosed }
      >
        <HeaderContainer>
          <div>
            <PositionTitleContainer>
              <PositionTitleWrapper title={ position.jobTitle }>
                {position.jobTitle}
              </PositionTitleWrapper>
              { renderTag() }
            </PositionTitleContainer>
            <PositionInfoBottom>
              { position.status !== PositionStatus.Draft && (
                <>
                  <PositionLocation remotePosition={ !!position.remotePosition } locations={ position.locations } />
                  <MiddleDot>·</MiddleDot>
                </>
              )}
              <PositionCreated>
                <Trans i18nKey={ STRING_KEYS.HOMEPAGE.POSITION_CARD.CREATED }>
                  <TimeAgoItem date={ position.createdAt } />
                </Trans>
              </PositionCreated>
            </PositionInfoBottom>
          </div>
          <PositionCardSettings
            ActionButton={ (
              <SettingsButton
                type="button"
                variant={ IconButtonVariants.Ghost }
                hoverVariant={ PerfectButtonHoverVariants.Grow }
                StartIcon={ SettingsButtonIcon }
              />
            ) }
            atsInitializationHandler={ onClickAtsInitialization }
            closePositionHandler={ onClickClosePosition }
            isAtsIntegrated={ !!position.linkedAtsJob }
            exportMatchesHandler={ handleExportCSVClick }
          />
        </HeaderContainer>
        <BodyContainer>
          { renderStatisticsCards() }
        </BodyContainer>
        <Divider />
        <FooterContainer>
          <UserPickersContainer
            position={ position }
            openInviteUsersModalHandler={ () => setIsInviteUsersModalOpen(true) }
            maxDisplayAvatars={ MAX_USER_PICKER_AVATARS }
          />
          <LastUpdated>
            <Trans i18nKey={ STRING_KEYS.HOMEPAGE.POSITION_CARD.UPDATED }>
              <Label />
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <TimeAgoItemWrapper date={ position.lastEngagedAt! || position.createdAt } />
            </Trans>
          </LastUpdated>
        </FooterContainer>
      </PositionCardContainer>
      <InviteColleagueModal
        isOpen={ isInviteUsersModalOpen }
        closeHandler={ () => setIsInviteUsersModalOpen(false) }
      />
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ () => setIsUpgradeDialogOpen(false) }
        />
      )}
      <InfoSnackBar
        title={ infoSnackBarText }
        isOpen={ isInfoSnackBarOpen }
        onClose={ () => setIsInfoSnackBarOpen(false) }
        shouldAutoDismiss
        icon={ ExportMatchesIcon }
      />
    </>
  );
};
export default OpenPositionCard;
