import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { AnimatedCursor } from '../../animated-cursor/animated-cursor.component';
import { ChatActions } from '../chat-actions/chat-actions.component';
import { ASSETS } from '../../../../../../assets';
import STRING_KEYS from '../../../../../../language/keys';
import { TypingEffect } from '../../typing-effect/typing-effect.component';
import { ChatAiMessageProps } from './chat-ai-message';
import {
  AIContainer,
  AiTitle,
  ChatAiBubble,
  ChatAIUser,
  NliImage,
} from './chat-ai-message.styled';

export const ChatAiMessage = ({
  conversationMessage,
  onActionClick,
  onTypingComplete,
  isStreamComplete,
}: ChatAiMessageProps): JSX.Element => {
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (isStreamComplete && onTypingComplete) {
      onTypingComplete();
    }
  }, [isStreamComplete, onTypingComplete]);
  
  const renderActionItems = () => {
    if (!conversationMessage.actions || conversationMessage.isTyping) return null;
    
    return (
      <ChatActions message={ conversationMessage } onActionClick={ onActionClick } />
    );
  };

  const renderMessageContent = () => {
    let content;

    if (conversationMessage.isThinking) {
      content = <AnimatedCursor />;
    } else if (conversationMessage.isTyping) {
      content = <TypingEffect text={ conversationMessage.message } onComplete={ onTypingComplete } />;
    } else {
      content = <Markdown>{conversationMessage.message}</Markdown>;
    }

    return content;
  };

  return (
    <AIContainer>
      <ChatAiBubble key={ conversationMessage.id }>
        <ChatAIUser>
          <NliImage src={ ASSETS.NLI_LOGO } />
          <AiTitle>
            {translate(STRING_KEYS.MATCHES_PAGE.CHAT.AI_PERFECT)}
          </AiTitle>
        </ChatAIUser>
        {renderMessageContent()}
      </ChatAiBubble>
      {renderActionItems()}
    </AIContainer>
  );
};
