import { useEffect, useState } from 'react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { THEME } from '../../../themes';

export enum ConversationPageSizeEnum {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}

// TODO should define across app and will move to common folder
export const useConversationPageSize = (): ConversationPageSizeEnum => {
  const [pageSize, SetConversationPageSize] = useState(ConversationPageSizeEnum.Medium);
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width <= THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE) {
      SetConversationPageSize(ConversationPageSizeEnum.Small);
    } else if (width <= THEME.BREAKPOINTS.WIDTH.LARGE) {
      SetConversationPageSize(ConversationPageSizeEnum.Medium);
    } else {
      SetConversationPageSize(ConversationPageSizeEnum.Large);
    }
  }, [width]);

  return pageSize;
};
