import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { Container, Description, Title } from './matches-deadend.styled';

export const MatchesDeadend = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Title>
        { translate(STRING_KEYS.MATCHES_PAGE.DEADEND.TITLE) }
      </Title>
      <Description>
        <pre>{ translate(STRING_KEYS.MATCHES_PAGE.DEADEND.DESCRIPTION) }</pre>
      </Description>
    </Container>
  );
};
