/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO [refactor] eslint-disable for temporary devs deployments
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import log from 'loglevel';
import { useOnPaymentSuccess } from '../../hooks/use-on-payment-success.hooks';
import { useAcknowledgementsDialogs, useRenderAcknowledgementsDialogs } from '../../hooks/acknowledgements.hooks';
import { AppRouting } from '../../services/appRoutingResolver';
import { PRODUCT_AREA } from '../../consts/analytics';
import { NotFoundErrorPage } from '../../components/Common/Errors/NotFoundErrorPage';
import { AnalyticsProvider } from '../../contexts/analytics';
import { useUpgradeModalFromQueryParam } from '../../hooks/upgrade-modal-from-query-param.hook';
import ApplicationLayout from './components/application-layout/application-layout.component';
import { OutreachLayout } from './components/outreach/outreach-layout/outreach.layout';
import ConversationEmpty from './pages/conversation-empty/conversation-empty.page';
import ConversationsPage from './pages/conversation/conversation.page';
import PositionLayout from './components/position-layout/position-layout.component';
import PositionOverviewPage from './pages/position-overview-page/position-overview.page';
import Homepage from './pages/home/home.page';
import CampaignPage from './pages/campaign/campaign.page';
import OutreachContentPreferencesPage from './pages/outreach-content-preferences/outreach-content-preferences.page';
import MatchTunePage from './pages/match-tune/match-tune.page';
import MatchesPage from './pages/matches/matches.page';

// TODO [refactor] implement
const logger = log.getLogger('APPLICATION_ROUTER');

const ApplicationRouter = (): JSX.Element => {
  const AcknowledgementsDialogs = useRenderAcknowledgementsDialogs(useAcknowledgementsDialogs());
  const paymentComponent = useOnPaymentSuccess();
  const upgradeModal = useUpgradeModalFromQueryParam();

  return (
    <ApplicationLayout>
      <>
        { AcknowledgementsDialogs }
        { paymentComponent }
        { upgradeModal }
        <Switch>
          <Route path={ AppRouting.ROUTES.POSITION }>
            <PositionLayout>
              <Switch>
                <Route path={ AppRouting.ROUTES.POSITION_OVERVIEW } exact>
                  <PositionOverviewPage />
                </Route>
                <Route path={ AppRouting.ROUTES.OUTREACH_POSITION }>
                  <OutreachLayout>
                    <Switch>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CONTENT_PREFERENCES } exact>
                        <OutreachContentPreferencesPage />
                      </Route>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CONVERSATIONS } exact>
                        <ConversationEmpty />
                      </Route>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CONVERSATION_ID } exact>
                        <ConversationsPage />
                      </Route>
                      <Route path={ AppRouting.ROUTES.OUTREACH_POSITION_CAMPAIGN_PREFERENCES } exact>
                        <CampaignPage />
                      </Route>
                      <Route path="*" exact>
                        <Redirect to={ AppRouting.ROUTES.HOME } exact />
                      </Route>
                    </Switch>
                  </OutreachLayout>
                </Route>
                <Route path={ AppRouting.ROUTES.MATCHES } exact>
                  <MatchesPage />
                </Route>
                <Route path="*" exact>
                  <Redirect to={ AppRouting.ROUTES.HOME } exact />
                </Route>
              </Switch>
            </PositionLayout>
          </Route>
          <Route path={ AppRouting.ROUTES.ACTIVATION_COMPLETED } exact>
            {/* special route to navigate from activation flow to home screen */}
            <Redirect to={ AppRouting.ROUTES.HOME } />
          </Route>
          <Route path={ AppRouting.ROUTES.HOME } exact>
            {/* Default Route. Keep it last in the list of routes */}
            <AnalyticsProvider productArea={ PRODUCT_AREA.HOMEPAGE }>
              <Homepage />
            </AnalyticsProvider>
          </Route>
          <Route path="*">
            <NotFoundErrorPage />
          </Route>
        </Switch>
      </>
    </ApplicationLayout>
  );
};

export default ApplicationRouter;
