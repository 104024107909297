import { useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import {
  BottomPart,
  Container,
  Description,
  FirstParagraph, FirstParagraphWrapper,
  NotificationIcon, SecondParagraph,
  Title,
} from './matches-error.styled';

export const MatchesError = (): JSX.Element => {
  const { t: translate } = useTranslation();
  
  return (
    <Container>
      <Title>
        { translate(STRING_KEYS.MATCHES_PAGE.ERROR.TITLE) }
      </Title>
      <Description>
        <pre>{ translate(STRING_KEYS.MATCHES_PAGE.ERROR.DESCRIPTION) }</pre>
      </Description>
      <BottomPart>
        <FirstParagraphWrapper>
          <NotificationIcon />
          <FirstParagraph>
            { translate(STRING_KEYS.MATCHES_PAGE.ERROR.BOTTOM.NOTIFY_ONCE_READY) }
          </FirstParagraph>
        </FirstParagraphWrapper>
        <SecondParagraph>
          { translate(STRING_KEYS.MATCHES_PAGE.ERROR.BOTTOM.NEED_HELP) }
        </SecondParagraph>
      </BottomPart>
    </Container>
  );
};
