import { positionQuery } from '../position.toolkit-api';
import { PositionResponse } from '../dto/response/position.response';

type UseCreatePositionMutationType = [
  () => void,
  AppSelectorType<PositionResponse | undefined, unknown>
];

export const useCreatePositionMutation = (): UseCreatePositionMutationType => {
  const [
    createPosition,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useCreatePositionMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    createPosition,
    { ...meta },
  ];
};
