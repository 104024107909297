import styled from 'styled-components';
import MenuItem from '../../perfect-base-menu/menu-item/menu-item.component';
import { ASSETS } from '../../../../assets';
import { THEME } from '../../../../themes';

export const Container = styled.div<{ selected?: boolean }>`
  :not(:last-child) {
    border-bottom: 1px solid ${THEME.COLORS.GREY};
  }
`;
 
export const SelectedIcon = styled(ASSETS.V_SIGN)`
  height: 10px;
  margin-left: auto;
  color: ${THEME.COLORS.ACCENT};
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: normal !important;

  :hover ${SelectedIcon}, :focus ${SelectedIcon} {
    color: inherit;
  }
`;
