import styled from 'styled-components';
import { THEME } from '../../../../../../themes';
import { SIDE_PANEL_WIDTH_EXTRA_SMALL, SIDE_PANEL_WIDTH_MEDIUM, SIDE_PANEL_WIDTH_SMALL } from '../../match-side-panel/match-side-panel.styled';

export const SkeletonContainer = styled.div<{ isAutoMode: boolean }>`
  padding: ${THEME.SPACING.MEDIUM} ${THEME.SPACING.MEDIUM} 0;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  width: ${({ isAutoMode }) => isAutoMode ? `${SIDE_PANEL_WIDTH_EXTRA_SMALL}px` : `${SIDE_PANEL_WIDTH_MEDIUM}px`};
  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE}px) {
    width: ${({ isAutoMode }) => {
    if (!isAutoMode) {
      return `${SIDE_PANEL_WIDTH_SMALL}px`;
    }

    return undefined;
  }};
  };
  
`;

export const SkeletonSummeryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SkeletonDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.TINY};
`;
