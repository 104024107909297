import React, { ChangeEvent, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import STRING_KEYS from '../../../../language/keys';
import { isValidEmail } from '../../../../utils';
import { PRIVACY_CLASS } from '../../../../consts/fullstory';
import { PasswordRules } from '../../../../components/Common/PasswordRules';
import { PerfectButtonSize } from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import { PerfectSpacer } from '../../../../components/Common/perfect-spacer/perfect-spacer.component';
import {
  EmailIcon,
  PasswordIcon,
  PerfectButton,
  PerfectInput,
  ValidationError,
  WorkEmailLoginForm,
} from './onboarding-work-email-login-form.styled';

export const OnboardingWorkEmailLoginForm = ({
  onEmailChange,
  onPasswordChange,
  onSubmit,
  isSubmitting,
  isEmailError,
  emailError,
  isPasswordError,
  passwordError,
  signUpError,
}: OnboardingWorkEmailLoginFormProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [email, setEmail] = useState<string>(queryParams.get('email') ?? '');
  const [password, setPassword] = useState<string>('');
  const [isPasswordRulesShown, setIsPasswordRulesShown] = useState<boolean>(
    false,
  );

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEmail(newValue);
    onEmailChange?.(newValue);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setPassword(newValue);
    onPasswordChange?.(newValue);
  };

  const handleSubmit:FormEventHandler<HTMLFormElement> = (event) => {
    // preventing the refresh of the browser upon form completion default behavior
    event.preventDefault();
    onSubmit?.(email, password);
  };

  return (
    <WorkEmailLoginForm onSubmit={ handleSubmit }>
      <PerfectInput
        name="email"
        autoComplete="new-password"
        placeholder={ translate(
          STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_WORK_EMAIL_PLACEHOLDER,
        ) }
        value={ email }
        $isError={ isEmailError }
        onChange={ handleEmailChange }
        StartAdornment={ <EmailIcon /> }
      />

      {isEmailError && !!emailError?.length && (
        <ValidationError>{emailError}</ValidationError>
      )}

      {isValidEmail(email) && (
        <PerfectInput
          name="password"
          type="password"
          className={ PRIVACY_CLASS.EXCLUDE }
          placeholder={ translate(
            STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_CHOOSE_YOUR_PASSWORD,
          ) }
          value={ password }
          $isError={ isPasswordError }
          onChange={ handlePasswordChange }
          onFocus={ () => {
            setIsPasswordRulesShown(true);
          } }
          onBlur={ () => {
            setIsPasswordRulesShown(false);
          } }
          StartAdornment={ <PasswordIcon /> }
        />
      )}

      {isPasswordError && !!passwordError?.length && (
        <ValidationError>{passwordError}</ValidationError>
      )}

      <PasswordRules
        isShowPasswordRules={ isPasswordRulesShown }
        passwordInput={ password }
      />

      {!!signUpError?.length && (
        <ValidationError>{signUpError}</ValidationError>
      )}

      <PerfectSpacer height={ 12 } />

      <PerfectButton
        type="submit"
        size={ PerfectButtonSize.ExtraLarge }
        isLoading={ isSubmitting }
      >
        {translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_LETS_GO_BUTTON)}
      </PerfectButton>
    </WorkEmailLoginForm>
  );
};
