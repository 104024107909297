import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useAuthActions, useLoginState, useSignUpState } from '@frontegg/react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ISocialLoginProviderConfigurationV2 } from '@frontegg/rest-api';
import { PAGE_NAMES } from '../../../../consts/pages';
import { COMPONENT_NAME, EVENT_GROUP } from '../../../../consts/analytics';
import { SIGNUP_ERROR_CODE, SIGNUP_ERROR_MESSAGES } from '../../../../consts';
import STRING_KEYS from '../../../../language/keys';
import { isValidEmail } from '../../../../utils';
import { getGoogleSocialLoginUrl, getMicrosoftSocialLoginUrl, passwordValidator } from '../../../../utils/frontegg';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { isPersonalEmailError } from '../../utils/onboarding.utils';
import { ONBOARDING_ANALYTICS_EVENTS } from '../../consts/onboarding-analytics.consts';
import LoginAndRegistrationComponent
  from '../../../../components/login-and-reqistration-container/login-and-registration.component';
import { setUserJustSignedUp } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import { SocialLoginOptions } from '../../../../components/login/login-form/social-login/social-login.component';
import { usePageFilters } from '../../../../hooks/use-page-filters.hooks';
import { useValidateUserEmailDomainQueryMutation } from '../../../../store/rtk-query/apis/user/user.toolkit-api';
import {
  OnboardingWorkEmailLoginForm,
} from '../../components/onboarding-work-email-login-form/onboarding-work-email-login.form';
import {
  AlreadyHaveAccountText,
  Background,
  DividerWithTitle,
  LoginButton,
  PageContentsWrapper,
  Wrapper,
} from './onboarding-registration.styled';

type RegistrationScreenProps = {
  onLoginComplete: () => void;
};

export const SIGN_UP_ERRORS = {
  USER_EXIST: 'User already exists',
};

const ONBOARDING_ACCOUNT_NAME = `onboarding-${v4()}`;

const QUERY_PARAM_ERROR_CODE = 'errorCode';

export const RegistrationPage = ({
  onLoginComplete,
}: RegistrationScreenProps): JSX.Element => {
  const { deleteFiltersByKey } = usePageFilters();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [localEmailError, setLocalEmailError] = useState<boolean>(false);
  const [localPasswordError, setLocalPasswordError] = useState<boolean>(false);
  const [termsOfUseModalVisible, setTermsOfUseModalVisible] = useState<boolean>(false);
  
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent, analyticsPageEvent } = useAnalyticsContext();

  const dispatch = useDispatch();

  const { signUpUser, login, resetSignUpState } = useAuthActions();
  const { loading: signUpLoading, error: signUpError = '' } = useSignUpState();
  const { loading: loginLoading } = useLoginState();

  const errorCode = (queryParams.get(QUERY_PARAM_ERROR_CODE) ||
    0) as SIGNUP_ERROR_CODE;
  const errorMessageKey =
    SIGNUP_ERROR_MESSAGES[errorCode] || STRING_KEYS.SIGNUP_ERRORS.UNDEFINED;

  useEffect(() => {
    analyticsPageEvent({
      eventName: ONBOARDING_ANALYTICS_EVENTS.SIGN_UP_PAGE_VIEWED,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    validateUserEmailDomain,
    {
      isLoading: isUserEmailDomainValidating,
      isError: isUserEmailDomainValidationFailed,
      error: userEmailDomainValidationError,
    },
  ] = useValidateUserEmailDomainQueryMutation();

  const signUpAndLogin = useCallback(
    async (email: string, password: string) => {
      await validateUserEmailDomain({ email });
      signUpUser({
        email,
        password,
        companyName: ONBOARDING_ACCOUNT_NAME,
        events: {
          signUpComplete: () => {
            dispatch(setUserJustSignedUp());

            login({
              email,
              password,
              callback: () => {
                onLoginComplete();
              },
            });
          },
        },
      });
    },
    [dispatch, login, onLoginComplete, signUpUser, validateUserEmailDomain],
  );

  const sendTrackAnalyticEvent = (eventName: string) => {
    const trackEventPayload = {
      eventName,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
      componentName: COMPONENT_NAME.SIGN_IN,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const loginTrackAnalyticEvent = () => {
    const trackEventPayload = {
      eventName: ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_LOGIN_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.SIGN_IN_ONBOARDING_PAGE,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const handleSignUpClick = async (email:string, password: string) => {
    sendTrackAnalyticEvent(
      ONBOARDING_ANALYTICS_EVENTS.ONBOARDING_LOGIN_CLICKED,
    );

    if (!isValidEmail(email)) {
      setLocalEmailError(true);

      return;
    }

    if (!passwordValidator(password)) {
      setLocalPasswordError(true);

      return;
    }

    await signUpAndLogin(email, password);
  };

  const handleGoogleLoginClick = (
    configuration: ISocialLoginProviderConfigurationV2,
  ) => {
    sendTrackAnalyticEvent(
      ONBOARDING_ANALYTICS_EVENTS.CONTINUE_WITH_GOOGLE_CLICKED,
    );
    const redirectUrl = AppRouting.buildGoogleLoginSuccessUrl(window.location);

    window.location.href = getGoogleSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  const handleMicrosoftLoginClick = async (
    configuration: ISocialLoginProviderConfigurationV2,
  ) => {
    sendTrackAnalyticEvent(
      ONBOARDING_ANALYTICS_EVENTS.CONTINUE_WITH_MICROSOFT_CLICKED,
    );
    const redirectUrl = AppRouting.buildMicrosoftLoginSuccessUrl(
      window.location,
    );

    window.location.href = await getMicrosoftSocialLoginUrl(
      configuration.clientId as string,
      redirectUrl,
    );
  };

  const handleEmailInputChange = (newValue: string) => {
    resetSignUpState();

    if (isValidEmail(newValue)) {
      setLocalEmailError(false);
    }
  };

  const handlePasswordInputChange = (newValue: string) => {
    resetSignUpState();

    if (passwordValidator(newValue)) {
      setLocalPasswordError(false);
    }
  };

  const onErrorCloseHandler = () => {
    deleteFiltersByKey(QUERY_PARAM_ERROR_CODE);
  };

  const handleCloseTermsOfUseModal = () => {
    setTermsOfUseModalVisible(false);
  };

  const getEmailError = (): string => {
    if (isUserEmailDomainValidationFailed) {
      if (userEmailDomainValidationError?.code) {
        const internalCode = parseInt(userEmailDomainValidationError?.code);
        if (isPersonalEmailError(internalCode)) {
          return translate(STRING_KEYS.ONBOARDING.PERSONAL_EMAIL_ERROR);
        }
      }

      return translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_WORK_EMAIL,
      );
    }

    if (localEmailError) {
      return translate(
        STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_EMAIL,
      );
    }

    return '';
  };

  const getSignupError = (): string => {
    switch (signUpError) {
      case SIGN_UP_ERRORS.USER_EXIST: {
        return translate(STRING_KEYS.ONBOARDING.USER_EXIST);
      }
      default: {
        return signUpError;
      }
    }
  };

  const handleLoginClick = () => {
    loginTrackAnalyticEvent();

    AppRouting.navigateToUrl(AppRouting.buildGlobalLoginUrl(window.location));
  };

  return (
    <LoginAndRegistrationComponent
      headerSticky
      error={ !!errorCode && translate(errorMessageKey) }
      onErrorCloseHandler={ onErrorCloseHandler }
      isLoading={ signUpLoading || loginLoading || isUserEmailDomainValidating }
      termsOfUseModalVisible={ termsOfUseModalVisible }
      handleCloseTermsOfUseModal={ handleCloseTermsOfUseModal }
      title={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_TITLE) }
      subTitle={ translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_SUBTITLE) }
    >
      <PageContentsWrapper>
        <SocialLoginOptions
          onGoogleLoginClick={ handleGoogleLoginClick }
          onMicrosoftLoginClick={ handleMicrosoftLoginClick }
        />

        <DividerWithTitle>
          {translate(STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_DESCRIPTION)}
        </DividerWithTitle>

        <OnboardingWorkEmailLoginForm
          onEmailChange={ handleEmailInputChange }
          onPasswordChange={ handlePasswordInputChange }
          onSubmit={ handleSignUpClick }
          isEmailError={ localEmailError || isUserEmailDomainValidationFailed }
          emailError={ getEmailError() }
          isPasswordError={ localPasswordError }
          passwordError={ translate(
            STRING_KEYS.ONBOARDING.ONBOARDING_SIGN_UP_ENTER_VALID_PASSWORD,
          ) }
          isSubmitting={
            signUpLoading || loginLoading || isUserEmailDomainValidating
          }
          signUpError={ getSignupError() }
        />

        <Wrapper>
          <AlreadyHaveAccountText>
            {translate(STRING_KEYS.ONBOARDING.ALREADY_HAVE_AN_ACCOUNT)}
          </AlreadyHaveAccountText>
          <LoginButton onClick={ handleLoginClick }>
            {translate(STRING_KEYS.ONBOARDING.LOGIN)}
          </LoginButton>
        </Wrapper>
      </PageContentsWrapper>

      <Background />
    </LoginAndRegistrationComponent>
  );
};
