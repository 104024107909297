import styled, { css } from 'styled-components';
import { Body1Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';
import Select from '../../../../components/Common/select/select.component';
import BaseInput from '../../../../components/Common/perfect-base-input/perfect-base-input.component';
import {
  PerfectPhoneInput as BasePhoneInput,
} from '../../../../components/Common/perfect-phone-input/perfect-phone-input.component';
import { Input } from '../../../../components/Common/select/select.styled';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 50px;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  row-gap: 16px;
  column-gap: 16px;
`;

const commonInputStyles = css<{ $spanBothColumns?: boolean }>`
  grid-column: ${({ $spanBothColumns }) => $spanBothColumns ? '1 / span 2' : 'auto'};
  border-radius: 12px;
  height: 56px;
  width: 100%;
  font-size: 16px;
  background-color: ${THEME.COLORS.LIGHT_GREY};
`;

export const PerfectSelect = styled(Select)<{ $spanBothColumns?: boolean }>`
  && {
    ${commonInputStyles};

    ${Input} {
      background-color: ${THEME.COLORS.LIGHT_GREY};
      ${Body1Font}
    }
  }
`;

export const PerfectInput = styled(BaseInput)<{ $spanBothColumns?: boolean }>`
  ${commonInputStyles};

  input {
    background-color: ${THEME.COLORS.LIGHT_GREY};
    ${Body1Font}

    ::placeholder {
      color: ${THEME.COLORS.PRIMARY_DARK};
    }
  }
`;

export const PerfectPhoneInput = styled(BasePhoneInput)`
  ${commonInputStyles};

  input {
    background-color: ${THEME.COLORS.LIGHT_GREY};
    ${Body1Font}

    ::placeholder {
      color: ${THEME.COLORS.PRIMARY_DARK};
    }
  }
`;

export const PerfectButton = styled(BaseButton)<{ $spanBothColumns?: boolean }>`
  grid-column: ${({ $spanBothColumns }) => $spanBothColumns ? '1 / span 2' : 'auto'};
  width: 100%;
  background-color: ${THEME.COLORS.ACCENT};
`;
