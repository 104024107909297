import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import STRING_KEYS from '../../../../../language/keys';
import { todayTomorrowOrFormatDate } from '../../../../../utils/dates';
import { useGetPositionSelector } from '../../../../../store/rtk-query/apis/position/selectors/get-position.selector';
import {
  Container,
  LeftColumn,
  Title,
  Description,
  ActionContainer,
  DescriptionWrapper,
  IconContainer,
  NeedFlexibility,
  BoldText,
  ConsiderUpgrading,
  NextDailyMatches,
} from './match-daily-limit.styled';

const NEXT_DAILY_BATCH_TIME_FORMAT = 'HH:mm';

const MatchLimitDailyBase = ({
  Background,
  Icon,
  PrimaryButton,
  SecondaryButton,
  positionId,
}: MatchDailyLimitProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: { dailyLimitSize, nextDailyPublishMatchesTime } } = useGetPositionSelector(positionId);

  const renderNextDailyBatchTimeText = () => {
    if (!nextDailyPublishMatchesTime) {
      return null;
    }

    const nextDailyPublishMatchesTimeDate = new Date(nextDailyPublishMatchesTime);

    const day = todayTomorrowOrFormatDate(nextDailyPublishMatchesTimeDate);
    const hour = format(nextDailyPublishMatchesTimeDate, NEXT_DAILY_BATCH_TIME_FORMAT);

    return (
      <NextDailyMatches>
        <Trans
          i18nKey={ STRING_KEYS.DAILY_LIMIT.NEXT_MATCHES_BATCH_TIME }
          components={ { bold: <BoldText /> } }
          values={ { day, hour, dailyLimitSize } }
        />
      </NextDailyMatches>
    );
  };

  return (
    <Container>
      { !!Background && Background }
      <IconContainer>
        { Icon }
      </IconContainer>
      <LeftColumn>
        <Title>
          { translate(STRING_KEYS.DAILY_LIMIT.TITLE) }
        </Title>
        <Description>
          <DescriptionWrapper>
            { renderNextDailyBatchTimeText() }
            <NeedFlexibility>
              { translate(STRING_KEYS.DAILY_LIMIT.NEED_MORE_FLEXIBILITY) }
            </NeedFlexibility>
            <ConsiderUpgrading>
              { translate(STRING_KEYS.DAILY_LIMIT.CONSIDER_UPGRADING) }
            </ConsiderUpgrading>
          </DescriptionWrapper>
        </Description>
        <ActionContainer>
          { PrimaryButton }
          { !!SecondaryButton && SecondaryButton }
        </ActionContainer>
      </LeftColumn>
    </Container>
  );
};

export default MatchLimitDailyBase;
