import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MinimizeChatButton } from '../minimize-chat-button/minimize-chat-button.component';
import { ChatMessage } from '../chat-message/chat-message.component';
import { sleep } from '../../../../../utils';
import { MinimizedChat } from '../minimized-chat/minimized-chat.component';
import { ChatSnackbar } from '../chat-snackbar/chat-snackbar.component';
import { THEME } from '../../../../../themes';
import {
  ConversationMessage,
} from '../../../../../store/rtk-query/apis/recruiter-agent/dto/response/conversation-message';
import { ConversationMessageType } from '../../../../../enums/conversation-message-type.enum';
import { ChatInput } from '../chat-input/chat-input.component';
import {
  useGetStreamingMessageQuery,
} from '../../../../../store/rtk-query/apis/recruiter-agent/recruiter-agent.toolkit-api';
import {
  useCreateConversationMessageMutation,
} from '../../../../../store/rtk-query/apis/recruiter-agent/hooks/create-conversation-message.mutation-hook';
import { InputError } from '../chat-input/chat-input';
import { ErrorType } from '../chat-input/chat-input.enums';
import STRING_KEYS from '../../../../../language/keys';
import { ChatBox, ChatContainer, MinimizeWrapper } from './perfect-ai-chat.styled';
import { PerfectAiChatProps } from './perfect-ai-chat';

const DEFAULT_AGENT_MESSAGE = {
  id: `ai-${Date.now()}`,
  messageType: ConversationMessageType.AgentMessage,
  message: 'Hi there! How can I assist you today?',
  isTyping: false,
  isThinking: false,
};

export const PerfectAiChat = ({
  isChatMinimized,
  onChatMinimize,
  isChatInputDisabled,
  chatSnackbarProps,
  conversation,
  onSendMessage,
  positionId,
}: PerfectAiChatProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [chatError, setChatError] = useState<InputError | undefined>(undefined);
  const [messages, setMessages] = useState<ConversationMessage[]>([DEFAULT_AGENT_MESSAGE]);
  const [shouldStream, setShouldStream] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [minimizeTime, setMinimizeTime] = useState<number | null>(null);
  const isSmallScreen = window.innerWidth < THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN;
  const { data: streamingData } = useGetStreamingMessageQuery(
    { positionId, shouldStream },
    { skip: !shouldStream },
  );
  const [createConversationMessage,
    { isSuccess: isMessageCreatedSuccess, isError: isMessageCreateError, isLoading: isMessageCreateLoading },
  ] = useCreateConversationMessageMutation();

  const resetConversationTyping = (conversationMessages: ConversationMessage[]): ConversationMessage[] => {
    return conversationMessages.map((message, index) => {
      // Only modify isTyping for agent messages
      if (message.messageType === ConversationMessageType.AgentMessage) {
        return {
          ...message,
          isTyping: index === conversationMessages.length - 1 ? message.isTyping : false,
        };
      }

      return message;
    });
  };

  // Stop streaming when complete
  useEffect(() => {
    if (streamingData?.isStreamComplete) {
      setShouldStream(false);
    }
  }, [streamingData?.isStreamComplete]);

  useEffect(() => {
    if (isMessageCreatedSuccess) {
      setShouldStream(true);
    }
  }, [isMessageCreatedSuccess]);

  const afterSendingMessageHandler = async () => {
    // Disable actions of the last AI message
    setMessages((prev) => prev.map((msg, index) => msg.messageType === ConversationMessageType.AgentMessage && index === prev.length - 1 ?
      { ...msg, actionsDisabled: true } :
      msg),
    );
  };

  useEffect(() => {
    setMessages([
      DEFAULT_AGENT_MESSAGE,
      ...resetConversationTyping(conversation),
    ]);

    // console.log('updated unique conversation', conversation);
  }, [conversation]);

  useEffect(() => {
    if (isMessageCreatedSuccess) {
      afterSendingMessageHandler();
    }
  }, [isMessageCreatedSuccess]);

  const sendMessageHandler = useCallback(async (message: string) => {
    if (message.trim() === '') return;
    createConversationMessage({ message, positionId });
    await sleep(2000);
    onSendMessage();
  }, [createConversationMessage, onSendMessage, positionId]);

  useEffect(() => {
    if (!isMessageCreateError && chatError) {
      setChatError(undefined);
    }

    if (isMessageCreateError && !chatError) {
      setChatError({
        message: translate(STRING_KEYS.MATCHES_PAGE.CHAT.INPUT.NETWORK_ERROR),
        type: ErrorType.NetworkError,
        action: sendMessageHandler,
      });
    }
  }, [chatError, isMessageCreateError, sendMessageHandler, translate]);

  const handleTypingComplete = useCallback(() => {
    setMessages((prev) => prev.map((msg) => msg.messageType === ConversationMessageType.AgentMessage ? { ...msg, isTyping: false } : msg));
  }, []);

  const actionClickHandler = async (action: string) => {
    // Send the action as a user message
    await sendMessageHandler(action);
  };

  const onMinimizeHandler = useCallback(async () => {
    setMinimizeTime(Date.now());
    onChatMinimize(true);
    setIsVisible((prev) => !prev);

    await sleep(2000);

    const aiMessageId = `ai-${Date.now()}`;
    setMessages((prev) => [...prev, {
      id: aiMessageId,
      messageType: ConversationMessageType.AgentMessage,
      isThinking: false,
      isTyping: true,
      message: 'Hi, I\'m still here if you need me!',
    }]);
  }, [onChatMinimize]);

  const onMaximizeHandler = () => {
    onChatMinimize(!isChatMinimized);
    setTimeout(() => {
      setIsVisible(!isVisible);
    }, 300);
  };

  const unreadMessages = React.useMemo(() => {
    return messages.filter(
      (message) => minimizeTime &&
        message?.createdAt &&
        new Date(message.createdAt).getTime() > minimizeTime,
    );
  }, [messages, minimizeTime]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (chatSnackbarProps.text || chatSnackbarProps.Icon) {
      setShowSnackbar(true);
    }

    if (chatSnackbarProps.text === '') {
      setShowSnackbar(false);
    }
  }, [chatSnackbarProps.text, chatSnackbarProps.Icon, chatSnackbarProps.firstAction]);

  return (
    <>
      { ((isVisible && isChatMinimized) || isSmallScreen) && (
        <MinimizedChat
          onClick={ onMaximizeHandler }
          hide={ isChatMinimized }
          latestMessage={ unreadMessages[unreadMessages.length - 1]?.message || null }
          hasUnread={ unreadMessages.length > 0 }
          isSmallScreen
        />
      ) }
      {!(isVisible && isChatMinimized) && (
        <ChatContainer isMinimized={ isChatMinimized } hide={ isVisible }>
          <MinimizeWrapper>
            <MinimizeChatButton onClick={ onMinimizeHandler } />
          </MinimizeWrapper>
          <ChatBox>
            {messages.map((message) => (
              <ChatMessage
                key={ message.messageType === ConversationMessageType.AgentMessage ? message.generationId : message.id }
                conversationMessage={ message }
                onTypingComplete={ handleTypingComplete }
                onActionClick={ actionClickHandler }
                isStreamComplete={ streamingData?.isStreamComplete }
              />
            ))}
            <div ref={ messagesEndRef } />
          </ChatBox>
          { showSnackbar && <ChatSnackbar Icon={ chatSnackbarProps.Icon } text={ chatSnackbarProps.text } firstAction={ chatSnackbarProps.firstAction } /> }
          <ChatInput
            isDisabled={ isChatInputDisabled && !isMessageCreateError }
            isLoading={ isMessageCreateLoading }
            isMessageCreated={ isMessageCreatedSuccess }
            onSubmit={ sendMessageHandler }
            error={ chatError }
          />
        </ChatContainer>
      )}
    </>
  );
};
