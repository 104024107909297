import { recruiterAgentQuery } from '../recruiter-agent.toolkit-api';
import { RetryGenerationQueryArguments } from '../dto/query-arguments/retry-generation.query-arguments';

type UseCreateConversationMessageMutationType = [
  ({ positionId }: RetryGenerationQueryArguments) => void,
  AppSelectorType<string | undefined, unknown>
];

export const useRetryGenerationMutation = (): UseCreateConversationMessageMutationType => {
  const [
    retryGenerate,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = recruiterAgentQuery.useRetryGenerationMutation();

  const meta = {
    data, isLoading, isError, error, isSuccess, resetState: reset,
  };

  return [
    retryGenerate,
    { ...meta },
  ];
};
