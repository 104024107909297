import React from 'react';
import Typed from 'react-typed';
import { AnimatedCursor } from '../animated-cursor/animated-cursor.component';
import { Container } from './typing-effect.styled';

export const TypingEffect = ({ text, onComplete }: TypingEffectProps): JSX.Element => {
  return (
    <Container>
      <Typed
        strings={ [text] }
        typeSpeed={ 10 }
        showCursor={ false }
        onComplete={ onComplete }
      />
      <AnimatedCursor />
    </Container>
  );
};
