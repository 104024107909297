/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { PatternFormat } from 'react-number-format';
import { AdornmentContainer, Input, InputContainer } from './perfect-base-input.styled';
import { PerfectPhoneInputProps } from './perfect-phone-input';
import { PerfectInputSize } from './perfect-base-input.enums';

export const PerfectPhoneInput = forwardRef((
  props: PerfectPhoneInputProps,
  ref: Ref<HTMLInputElement>,
) => {
  const {
    size = PerfectInputSize.Medium,
    fullWidth,
    disabled,
    EndAdornment,
    StartAdornment,
    placeholder,
    value,
    endAdornmentClickHandler,
    onChange,
    name,
    ...otherProps
  } = props;

  return (
    <InputContainer
      disabled={ !!disabled }
      fullWidth={ !!fullWidth }
      size={ size }
      { ...otherProps }
    >
      { !!StartAdornment && <AdornmentContainer>{ StartAdornment }</AdornmentContainer> }

      <PatternFormat
        format="+# (###) ###-####" // Default E.164 pattern
        mask="_"
        value={ value }
        onValueChange={ (values) => onChange?.(`+${values.value}`) }
        placeholder={ placeholder }
        name={ name }
        customInput={ Input }
        getInputRef={ ref }
        disabled={ disabled }
      />

      { !!EndAdornment &&  (
        <AdornmentContainer onClick={ (event) => endAdornmentClickHandler?.(event) }>
          { EndAdornment }
        </AdornmentContainer>
      )}
    </InputContainer>
  );
});

PerfectPhoneInput.displayName = 'PerfectPhoneInput';
