/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SpinnerCircle, SpinnerSvg } from './spinner.styled';

const Spinner = ({
  width, height, animationDuration = 8000, ...otherProps
}: {width:number, height:number, animationDuration: number}): JSX.Element => {
  return (
    <SpinnerSvg
      viewBox="25 25 50 50"
      $animationDuration={ animationDuration }
      width={ width }
      height={ height }
      { ...otherProps }
    >
      <SpinnerCircle cx="50" cy="50" r="20" />
    </SpinnerSvg>
  );
};

export default Spinner;
