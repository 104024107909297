import React, { useState, useEffect } from 'react';
import { VisibleWords, WordSliderProps } from './word-slider';
import {
  Container,
  TrackContainer,
  WordItem,
  WordsContainer,
  WordText,
  WordContent,
} from './word-slider.styled';

const WORD_WIDTH = 350;
const WORD_GAP = 100;
const SLOT_WIDTH = WORD_WIDTH + WORD_GAP;

export const WordSlider = ({
  words,
  defaultInterval = 2000,
}: WordSliderProps): JSX.Element => {
  const [position, setPosition] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const moveToNextWord = () => {
      if (position < words.length - 1) {
        setIsAnimating(true);
        setTimeout(() => {
          setPosition((prev) => prev + 1);
          setIsAnimating(false);
        }, 500);
      }
    };

    const currentDuration = words[position]?.duration || defaultInterval;
    const timer = setTimeout(moveToNextWord, currentDuration);

    return () => clearTimeout(timer);
  }, [words?.length, defaultInterval, position, words]);

  const getVisibleWords = (): VisibleWords[] => {
    const visibleWords = [];
    const remainingWords = words.length - position - 1;

    for (let i = -2; i <= 0; i++) {
      const index = position + i;
      if (index >= 0) {
        visibleWords.push({ word: words[index], offset: i });
      }
    }

    for (let i = 1; i <= Math.min(2, remainingWords); i++) {
      const index = position + i;
      if (index < words.length) {
        visibleWords.push({ word: words[index], offset: i });
      }
    }

    return visibleWords;
  };

  return (
    <Container>
      <TrackContainer>
        <WordsContainer>
          {getVisibleWords().map(({ word, offset }: VisibleWords) => (
            <WordItem
              key={ word.key }
              $offset={ offset }
              $isAnimating={ isAnimating }
              $width={ WORD_WIDTH }
              $slotWidth={ SLOT_WIDTH }
            >
              <WordContent>
                <WordText $isCentered={ offset === 0 }>
                  {word.text}
                </WordText>
              </WordContent>
            </WordItem>
          ))}
        </WordsContainer>
      </TrackContainer>
    </Container>
  );
};
