import {
  configureStore,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { positionQuery } from './rtk-query/apis/position/position.toolkit-api';
import { userQuery } from './rtk-query/apis/user/user.toolkit-api';
import { accountQuery } from './rtk-query/apis/account/account.toolkit-api';
import appReducer  from './slices/app-state/app-state.toolkit-slice';
import userPermissionsReducer from './slices/user-permissions-state/user-permissions-state.toolkit-slice';
import createPosition  from './slices/create-position-state/create-position-state.toolkit-slice';
import onboardingReducer  from './slices/onboarding-state/onboarding-state.toolkit-slice';
import matchReducer  from './slices/match-state/match-state.toolkit-slice';
import outreachReducer  from './slices/outreach-state/outreach-state.toolkit-slice';
import { errorMiddleware } from './middlewares/error.middleware';
import { matchQuery } from './rtk-query/apis/match/match.toolkit-api';
import { onboardingQuery } from './rtk-query/apis/onboarding/onboarding.toolkit-api';
import { companyQuery } from './rtk-query/apis/company/company.toolkit-api';
import { outreachQuery } from './rtk-query/apis/outreach/outreach.toolkit-api';
import { accountPlanQuery } from './rtk-query/apis/account-plan/account-plan.toolkit-api';
import { atsIntegrationQuery } from './rtk-query/apis/ats-integration/ats-integration.toolkit-api';
import { sequenceQuery } from './rtk-query/apis/sequence/sequence.toolkit-api';
import { paymentQuery } from './rtk-query/apis/payment/payment.toolkit-api';
import { trackingQuery } from './rtk-query/apis/tracking/tracking.toolkit-api';
import { appVersionMiddleware } from './middlewares/app-version.middleware';
import { recruiterAgentQuery } from './rtk-query/apis/recruiter-agent/recruiter-agent.toolkit-api';

export const store = configureStore({
  reducer: {
    appReducer,
    createPosition,
    matchReducer,
    onboardingReducer,
    outreachReducer,
    userPermissionsReducer,
    [onboardingQuery.reducerPath]: onboardingQuery.reducer,
    [matchQuery.reducerPath]: matchQuery.reducer,
    [companyQuery.reducerPath]: companyQuery.reducer,
    [positionQuery.reducerPath]: positionQuery.reducer,
    [userQuery.reducerPath]: userQuery.reducer,
    [accountQuery.reducerPath]: accountQuery.reducer,
    [accountPlanQuery.reducerPath]: accountPlanQuery.reducer,
    [outreachQuery.reducerPath]: outreachQuery.reducer,
    [atsIntegrationQuery.reducerPath]: atsIntegrationQuery.reducer,
    [sequenceQuery.reducerPath]: sequenceQuery.reducer,
    [paymentQuery.reducerPath]: paymentQuery.reducer,
    [trackingQuery.reducerPath]: trackingQuery.reducer,
    [recruiterAgentQuery.reducerPath]: recruiterAgentQuery.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    matchQuery.middleware,
    positionQuery.middleware,
    userQuery.middleware,
    accountQuery.middleware,
    accountPlanQuery.middleware,
    companyQuery.middleware,
    onboardingQuery.middleware,
    outreachQuery.middleware,
    atsIntegrationQuery.middleware,
    sequenceQuery.middleware,
    paymentQuery.middleware,
    trackingQuery.middleware,
    recruiterAgentQuery.middleware,
    appVersionMiddleware,
    errorMiddleware,
  ],
});

setupListeners(store.dispatch);

export type RootReducerState = ReturnType<typeof store.getState>

export default store;
