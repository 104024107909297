import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { OverlineFont } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${THEME.SPACING.TINO};
`;

export const MessagesContainer = styled.div`
  width: 640px;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  padding-top: ${THEME.SPACING.MEDIUM};
  @media (min-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MIN}px) and (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE}px){
    width: 480px;
  }
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${THEME.SPACING.BASE};
`;

export const MessageGroupDate = styled.div`
  ${OverlineFont};
  text-transform: capitalize;
  letter-spacing: 0.33px;
  font-weight: 500;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;
