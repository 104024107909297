import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { Subtitle1Font, Title1Font } from '../../../../../themes/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${THEME.SPACING.XGIANT};
`;

export const Title = styled.div`
  ${Title1Font};
  font-weight: 600;
  color: ${THEME.COLORS.CONTENT_A};
  margin-bottom: ${THEME.SPACING.BASE};
`;

export const Description = styled.p`
  ${Subtitle1Font};
  color: ${THEME.COLORS.CONTENT_A};
  opacity: ${THEME.OPACITY.MED};
  max-width: 640px;
  line-height: 1.5;
`;
