import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import STRING_KEYS from '../../../../language/keys';
import {
  OnboardingSetupProfileForm,
} from '../../components/onboarding-setup-profile-form/onboarding-setup-profile-form.component';
import {
  OnboardingSetupProfileFormState,
} from '../../components/onboarding-setup-profile-form/onboarding-setup-profile-form';
import { PAGE_TITLES } from '../../../../consts';
import {
  Subtitle, Title, TitlesWrapper, Wrapper,
} from './onboarding-setup-profile.styled';

export const OnboardingSetupProfilePage = ({ onFormSubmit }:OnboardingSetupProfilePageProps): JSX.Element => {
  const { t: translate } = useTranslation();

  const handleSubmit = async (data: OnboardingSetupProfileFormState) => {
    await onFormSubmit?.(data);
  };

  useEffect(() => {
    document.title = PAGE_TITLES.ONBOARDING_SETUP_PROFILE;
  }, []);

  return (
    <Wrapper>
      <TitlesWrapper>
        <Title>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SETUP_PROFILE_TITLE) }
        </Title>

        <Subtitle>
          { translate(STRING_KEYS.ONBOARDING.ONBOARDING_SETUP_PROFILE_SUBTITLE) }
        </Subtitle>
      </TitlesWrapper>

      <OnboardingSetupProfileForm onSubmit={ handleSubmit } />
    </Wrapper>
  );
};
