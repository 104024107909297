import styled from 'styled-components';
import { ASSETS } from '../../../../../assets';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';

export const MinimizeButton = styled.div`
  width: 24px;
  height: 24px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${THEME.RADIUS.CIRCLE};
  background-color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.FOCUS)};
  cursor: pointer;
`;

export const CloseIcon = styled(ASSETS.CLOSE)`
  width: 10px;
  height: 10px;
  color: ${THEME.COLORS.PURE_WHITE}
`;
