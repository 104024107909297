import { ASSETS } from '../assets';
import STRING_KEYS from '../language/keys';

export const PAGE_TITLES = {
  HOME: 'Home | Perfect',
  LOGIN: 'Login | Perfect',
  FORGOT_PASSWORD: 'Forgot Password | Perfect',
  INVITE: 'Invite | Perfect',
  POSITION: 'Position | Perfect',
  OUTREACH: 'Outreach | Perfect',
  CUSTOM_OUTREACH_PAGE_PREFIX: 'Outreach - ',
  ACTIVATION: 'Activation | Perfect',
  ACCEPT_INVITE: 'Accept | Perfect',
  RESET_PASSWORD: 'Reset Password | Perfect',
  NOT_FOUND: 'Not Found | Perfect',
  CUSTOM_PAGE_SUFFIX: ' | Perfect',
  ONBOARDING_SETUP_PROFILE: 'Set up your profile | Perfect',
  ONBOARDING_EMAIL_VERIFICATION: "Let's verify it's you | Perfect",
};

export const NOT_FOUND_ERROR_CODES = {
  UNDEFINED: '404',
  MISSING_SUB_DOMAIN: 'A-1401', // Unknown domain (missing sub-domain)
};

export const ALT = {
  PERFECT_LOGO: 'perfect logo',
};

export const MATCH_STATUSES = {
  TO_REVIEW: 'PENDING_REVIEW',
  ENGAGED: 'ENGAGED',
  DECLINED: 'DECLINED',
};

export const POSITION_STATUSES = {
  PUBLISHED: 'PUBLISHED',
};

export const CARD_TYPES = {
  EMPTY_ACTIVE: 'empty',
  ACTIVE: 'active',
  CLOSED: 'closed',
};

export const UserProfileMenuOptions = {
  SUPPORT: 'Support',
  LOGOUT: 'Logout',
};

export const LOADING_KEYS = {
  MY_POSITIONS: 'MY_POSITIONS',
};

export const SOCIAL_NETWORKS: Array<SocialNetwork> = [
  {
    type: 'FACEBOOK',
    titleKey: STRING_KEYS.FACEBOOK,
    icon: ASSETS.SOCIAL_FACEBOOK,
  },
  {
    type: 'GITHUB',
    titleKey: STRING_KEYS.GITHUB,
    icon: ASSETS.SOCIAL_GITHUB,
  },
  {
    type: 'LINKEDIN',
    titleKey: STRING_KEYS.LINKEDIN,
    icon: ASSETS.SOCIAL_LINKEDIN,
  },
  {
    type: 'MEDIUM',
    titleKey: STRING_KEYS.MEDIUM,
    icon: ASSETS.SOCIAL_MEDIUM,
  },
  {
    type: 'TWITTER',
    titleKey: STRING_KEYS.TWITTER,
    icon: ASSETS.SOCIAL_TWITTER,
  },
  {
    type: 'OTHER',
    titleKey: STRING_KEYS.OTHER,
    icon: ASSETS.SOCIAL_OTHER,
  },
];

export const ICONS_IDS = {
  NUMBER_CIRCLE_NINE: 'NUMBER_CIRCLE_NINE',
  TERMINAL: 'TERMINAL',
  COLLEGE_HAT: 'COLLEGE_HAT',
  FIRE: 'FIRE',
  MEDAL: 'MEDAL',
  UP_CHART: 'UP_CHART',
  SPACESHIP: 'SPACESHIP',
  ACCOUNT_CIRCLE: 'ACCOUNT_CIRCLE',
  DIAMOND: 'DIAMOND',
  ROCKET_LAUNCH: 'ROCKET_LAUNCH',
  ALARM_ON: 'ALARM_ON',
  HANDSHAKE: 'HANDSHAKE',
  SCHEDULE: 'SCHEDULE',
  APPROVAL: 'APPROVAL',
  HEADSET_MIC: 'HEADSET_MIC',
  SCHOOL: 'SCHOOL',
  AUTO_AWESOME: 'AUTO_AWESOME',
  HISTORY_EDU: 'HISTORY_EDU',
  SCIENCE: 'SCIENCE',
  AUTO_STORIES: 'AUTO_STORIES',
  HOURGLASS_BOTTOM: 'HOURGLASS_BOTTOM',
  STARS: 'STARS',
  BUG_REPORT: 'BUG_REPORT',
  LAYERS: 'LAYERS',
  TRACK_CHANGES: 'TRACK_CHANGES',
  BUSINESS: 'BUSINESS',
  LOCATION_ON: 'LOCATION_ON',
  TRENDING_UP: 'TRENDING_UP',
  CODE: 'CODE',
  MILITARY_TECH: 'MILITARY_TECH',
  WHATSHOT: 'WHATSHOT',
  DEVELOPER_BOARD: 'DEVELOPER_BOARD',
  MY_LOCATION: 'MY_LOCATION',
  CAMPAIGN: 'CAMPAIGN',
  CENTER_FOCUS: 'CENTER_FOCUS',
  CLOUD: 'CLOUD',
  ATTACH_MONEY: 'ATTACH_MONEY',
  FILTER_DRAMA: 'FILTER_DRAMA',
  GRASS: 'GRASS',
  INSIGHTS: 'INSIGHTS',
  LANGUAGE: 'LANGUAGE',
  MONETIZATION: 'MONETIZATION',
  PUBLIC: 'PUBLIC',
  RECOMMEND: 'RECOMMEND',
  SPORTS_HOCKEY: 'SPORTS_HOCKEY',
  SPORTS_VOLLEYBALL: 'SPORTS_VOLLEYBALL',
  THUMB_UP: 'THUMB_UP',
  VOLUNTEER_ACTIVISM: 'VOLUNTEER_ACTIVISM',
  WYSIWYG: 'WYSIWYG',
  HIKING: 'HIKING',
  SPORTS_CRICKET: 'SPORTS_CRICKET',
  VERIFIED: 'VERIFIED',
};

export const ICONS_MAPPING_LIST = [
  {
    iconId: ICONS_IDS.NUMBER_CIRCLE_NINE,
    icon: ASSETS.NUMBER_CIRCLE_NINE,
  },
  {
    iconId: ICONS_IDS.TERMINAL,
    icon: ASSETS.TERMINAL,
  },
  {
    iconId: ICONS_IDS.COLLEGE_HAT,
    icon: ASSETS.COLLEGE_HAT,
  },
  {
    iconId: ICONS_IDS.FIRE,
    icon: ASSETS.FIRE,
  },
  {
    iconId: ICONS_IDS.MEDAL,
    icon: ASSETS.MEDAL,
  },
  {
    iconId: ICONS_IDS.UP_CHART,
    icon: ASSETS.UP_CHART,
  },
  {
    iconId: ICONS_IDS.SPACESHIP,
    icon: ASSETS.SPACESHIP_ICON,
  },
  {
    iconId: ICONS_IDS.ACCOUNT_CIRCLE,
    icon: ASSETS.ACCOUNT_CIRCLE,
  },
  {
    iconId: ICONS_IDS.DIAMOND,
    icon: ASSETS.DIAMOND,
  },
  {
    iconId: ICONS_IDS.ROCKET_LAUNCH,
    icon: ASSETS.ROCKET_LAUNCH,
  },
  {
    iconId: ICONS_IDS.ALARM_ON,
    icon: ASSETS.ALARM_ON,
  },
  {
    iconId: ICONS_IDS.HANDSHAKE,
    icon: ASSETS.HANDSHAKE,
  },
  {
    iconId: ICONS_IDS.SCHEDULE,
    icon: ASSETS.SCHEDULE,
  },
  {
    iconId: ICONS_IDS.APPROVAL,
    icon: ASSETS.APPROVAL,
  },
  {
    iconId: ICONS_IDS.HEADSET_MIC,
    icon: ASSETS.HEADSET_MIC,
  },
  {
    iconId: ICONS_IDS.SCHOOL,
    icon: ASSETS.SCHOOL,
  },
  {
    iconId: ICONS_IDS.AUTO_AWESOME,
    icon: ASSETS.AUTO_AWESOME,
  },
  {
    iconId: ICONS_IDS.HISTORY_EDU,
    icon: ASSETS.HISTORY_EDU,
  },
  {
    iconId: ICONS_IDS.SCIENCE,
    icon: ASSETS.SCIENCE,
  },
  {
    iconId: ICONS_IDS.AUTO_STORIES,
    icon: ASSETS.AUTO_STORIES,
  },
  {
    iconId: ICONS_IDS.HOURGLASS_BOTTOM,
    icon: ASSETS.HOURGLASS_BOTTOM,
  },
  {
    iconId: ICONS_IDS.STARS,
    icon: ASSETS.STARS,
  },
  {
    iconId: ICONS_IDS.BUG_REPORT,
    icon: ASSETS.BUG_REPORT,
  },
  {
    iconId: ICONS_IDS.LAYERS,
    icon: ASSETS.LAYERS,
  },
  {
    iconId: ICONS_IDS.TRACK_CHANGES,
    icon: ASSETS.TRACK_CHANGES,
  },
  {
    iconId: ICONS_IDS.BUSINESS,
    icon: ASSETS.BUSINESS,
  },
  {
    iconId: ICONS_IDS.LOCATION_ON,
    icon: ASSETS.LOCATION_ON,
  },
  {
    iconId: ICONS_IDS.TRENDING_UP,
    icon: ASSETS.TRENDING_UP,
  },
  {
    iconId: ICONS_IDS.CODE,
    icon: ASSETS.CODE,
  },
  {
    iconId: ICONS_IDS.MILITARY_TECH,
    icon: ASSETS.MILITARY_TECH,
  },
  {
    iconId: ICONS_IDS.WHATSHOT,
    icon: ASSETS.WHATSHOT,
  },
  {
    iconId: ICONS_IDS.DEVELOPER_BOARD,
    icon: ASSETS.DEVELOPER_BOARD,
  },
  {
    iconId: ICONS_IDS.MY_LOCATION,
    icon: ASSETS.MY_LOCATION,
  },

  {
    iconId: ICONS_IDS.CAMPAIGN,
    icon: ASSETS.CAMPAIGN,
  },
  {
    iconId: ICONS_IDS.CENTER_FOCUS,
    icon: ASSETS.CENTER_FOCUS,
  },
  {
    iconId: ICONS_IDS.CLOUD,
    icon: ASSETS.CLOUD,
  },
  {
    iconId: ICONS_IDS.ATTACH_MONEY,
    icon: ASSETS.ATTACH_MONEY,
  },
  {
    iconId: ICONS_IDS.FILTER_DRAMA,
    icon: ASSETS.FILTER_DRAMA,
  },
  {
    iconId: ICONS_IDS.GRASS,
    icon: ASSETS.GRASS,
  },
  {
    iconId: ICONS_IDS.INSIGHTS,
    icon: ASSETS.INSIGHTS,
  },
  {
    iconId: ICONS_IDS.LANGUAGE,
    icon: ASSETS.LANGUAGE,
  },
  {
    iconId: ICONS_IDS.MONETIZATION,
    icon: ASSETS.MONETIZATION,
  },
  {
    iconId: ICONS_IDS.PUBLIC,
    icon: ASSETS.PUBLIC,
  },
  {
    iconId: ICONS_IDS.RECOMMEND,
    icon: ASSETS.RECOMMEND,
  },
  {
    iconId: ICONS_IDS.SPORTS_HOCKEY,
    icon: ASSETS.SPORTS_HOCKEY,
  },
  {
    iconId: ICONS_IDS.SPORTS_VOLLEYBALL,
    icon: ASSETS.SPORTS_VOLLEYBALL,
  },
  {
    iconId: ICONS_IDS.THUMB_UP,
    icon: ASSETS.THUMB_UP,
  },
  {
    iconId: ICONS_IDS.VOLUNTEER_ACTIVISM,
    icon: ASSETS.VOLUNTEER_ACTIVISM,
  },
  {
    iconId: ICONS_IDS.WYSIWYG,
    icon: ASSETS.WYSIWYG,
  },
  {
    iconId: ICONS_IDS.HIKING,
    icon: ASSETS.HIKING,
  },
  {
    iconId: ICONS_IDS.SPORTS_CRICKET,
    icon: ASSETS.SPORTS_CRICKET,
  },
  {
    iconId: ICONS_IDS.VERIFIED,
    icon: ASSETS.VERIFIED,
  },
];

export const INSIGHT_TYPE = {
  FULL: 'FULL',
  HEADLINE_ONLY: 'HEADLINE_ONLY',
  ELEMENT: 'ELEMENT',
  IMAGES: 'IMAGES',
  SPIDER_GRAPH: 'SPIDER_GRAPH',
};

export enum ORDER {
  ASCENDING,
  DESCENDING
}

export const SKILL_NAME: { [key: number]: string } = {
  1: STRING_KEYS.NOVICE,
  2: STRING_KEYS.BEGINNER,
  3: STRING_KEYS.COMPETENT,
  4: STRING_KEYS.PROFICIENT,
  5: STRING_KEYS.EXPERT,
};

// TODO [refactor]: move the correct place
export const POSITION_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const ENGLISH_VOWEL_LETTERS = ['A', 'E', 'I', 'O', 'U'];

export enum TALENT_NOT_INTERESTED_REASON {
  NOT_LOOKING_FOR_NEW_OPPORTUNITIES = 'NOT_LOOKING_FOR_NEW_OPPORTUNITIES',
  OPEN_TO_OTHER_OPPORTUNITIES = 'OPEN_TO_OTHER_OPPORTUNITIES',
  OTHER = 'OTHER',
}

export enum PLAN {
  PRO = 'PRO',
  BASIC = 'BASIC',
  TRIAL = 'TRIAL',
}

export enum POSITION_CLOSING_REASONS {
  POSITION_CANCELED = 'POSITION_CANCELED',
  POSITION_ALREADY_FULFILLED = 'POSITION_ALREADY_FULFILLED',
  OTHER = 'OTHER'
}

export enum TENURE_TITLE_STATUS {
  APPROACHING_TENURE = 'APPROACHING_TENURE',
  EXCEEDING_TENURE = 'EXCEEDING_TENURE',
}

// https://www.notion.so/perfect-match/App-Error-Codes-e88f98d8634d4c83a68f7ad0a24624cd
export enum SIGNUP_ERROR_CODE {
  SOCIAL_LOGIN_FAILED = 'A-1501',
  WORK_EMAIL = 'A-1502',
}

export enum SUCCESS_CODE {
  PASSWORD_CHANGED_SUCCESSFULLY = 'S-1001',
  FORGOT_EMAIL_SENT = 'S-1002',
}

export const SIGNUP_ERROR_MESSAGES = {
  [SIGNUP_ERROR_CODE.SOCIAL_LOGIN_FAILED]: STRING_KEYS.SIGNUP_ERRORS.GOOGLE_LOGIN_FAILED,
  [SIGNUP_ERROR_CODE.WORK_EMAIL]: STRING_KEYS.SIGNUP_ERRORS.WORK_EMAIL,
};

export const SOCIAL_TYPES = {
  LINKEDIN: 'LINKEDIN',
};

export const SUCCESS_MESSAGES: {[successCode: string]: { text: string, description?: string }} = {
  [SUCCESS_CODE.PASSWORD_CHANGED_SUCCESSFULLY]: {
    text: STRING_KEYS.PASSWORD_UPDATED_MESSAGE,
  },
  [SUCCESS_CODE.FORGOT_EMAIL_SENT]: {
    text: STRING_KEYS.FORGOT_PASSWORD_EMAIL_SENT_MESSAGE,
    description: STRING_KEYS.CHECK_YOUR_EMAIL,
  },
};

export enum PositionFeedbackGrade {
  BAD = 10,
  SO_SO = 20,
  GOOD = 30,
  AWESOME = 40,
}
