import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { Body1Font, Body2Font } from '../../../../themes/fonts';
import { DividerSection } from '../../../../components/Common/Divider/styled';
import TalentAtsIndication from '../talent-ats-indication/talent-ats-indication.component';

export const MATCH_PAGE_CONTENT_WIDTH = 1200;
const CARD_PADDING = THEME.SPACING.LOOSE;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: all 300ms ease-in-out;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${CARD_PADDING};
  background-color: ${THEME.COLORS.PURE_WHITE};
  width: 1034px;
  min-height: 512px;

  @media (min-width: ${THEME.BREAKPOINTS.WIDTH.TABLET}px) {
    width: 100%;
  }
`;

export const MatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  position: relative;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 112px;
  height: 112px;
  justify-content: center;
  align-items: center;
  margin-right: ${THEME.SPACING.LOOSE};
  position: relative;
`;

export const TalentDescription = styled.div`
  ${Body2Font};
  margin-top: ${THEME.SPACING.TINY};

  & > strong {
    font-weight: 600;
  }
`;

export const Body = styled.div`
  display: flex;
  margin-top: ${THEME.SPACING.MEDIUM};
  flex-grow: 1;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  flex-basis: 50%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.BASE};
  padding-right: ${THEME.SPACING.LOOSE};

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_BASE}px) {
    padding-right: 0;
  }
`;

export const RightContainer = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${THEME.SPACING.TINY};
  padding-left: ${THEME.SPACING.LOOSE};
`;

export const Divider = styled(DividerSection)`
  width: calc(100% + ${CARD_PADDING} + ${CARD_PADDING});
  height: 2px;
  align-self: center;
  margin-top: ${THEME.SPACING.BASE};
  background-color: ${THEME.COLORS.LIGHT_GREY};
  opacity: ${THEME.OPACITY.HIGHEST};
`;

export const NavigationIndicationWrapper = styled.div`
  display: flex;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: ${MATCH_PAGE_CONTENT_WIDTH}px;
  justify-content: flex-start;

  @media (max-width: ${THEME.BREAKPOINTS.WIDTH.LAPTOP_MAIN}px) {
    width: 100%;
  }
`;

export const StyledTalentAtsIndication = styled(TalentAtsIndication)`
  ${Body1Font};
  width: calc(100% + ${CARD_PADDING} + ${CARD_PADDING});
  padding: 0 ${CARD_PADDING};
`;
