import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
`;

export const BlinkingDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  background-color: #333;
  border-radius: 50%;
  animation: ${blink} 1s infinite;
`;
