import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { useGetMyUserQuery } from '../../../../../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import {
  ArrowDoodleIcon,
  BoldText,
  Container,
  Content,
  Header,
  Suggestion,
  PromptsTitle,
  SubTitle,
  Title1,
  Title2,
} from './matches-empty-state.styled';

export const MatchesEmptyState = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const {
    data: {
      firstName,
    },
  } = useGetMyUserQuery();

  return (
    <Container>
      <Header>
        <Title1>{ translate(STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.HI_USER, { firstName }) }</Title1>
        <Title2>{ translate(STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.THE_FUTURE_OF_HIRING) }</Title2>
        <SubTitle>{ translate(STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.SUBTITLE) }</SubTitle>
      </Header>

      <Content>
        <PromptsTitle>{ translate(STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.THINGS_YOU_CAN_ASK) }</PromptsTitle>
        <Suggestion>
          <Trans
            i18nKey={ STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.SUGGESTION1 }
            components={ { bold: <BoldText /> } }
          />
        </Suggestion>
        <Suggestion>
          <Trans
            i18nKey={ STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.SUGGESTION2 }
            components={ { bold: <BoldText /> } }
          />
        </Suggestion>
        <Suggestion>
          <Trans
            i18nKey={ STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.SUGGESTION3 }
            components={ { bold: <BoldText /> } }
          />
        </Suggestion>
        <Suggestion>
          <Trans
            i18nKey={ STRING_KEYS.MATCHES_PAGE.EMPTY_STATE.SUGGESTION4 }
            components={ { bold: <BoldText /> } }
          />
        </Suggestion>
      </Content>
      <ArrowDoodleIcon />
    </Container>
  );
};
