import log from 'loglevel';
import { RecruiterAgentInfoResponse } from '../dto/response/recruiter-agent-info.response';

const logger = log.getLogger('AGENT_STATE_CHANGED_WEBSOCKET_LISTENERS');

export const agentStatusChangedCacheWebsocketListeners = (
  websocketDto: RecruiterAgentInfoResponse,
  updateCachedData: (args: (agentInfo: RecruiterAgentInfoResponse) => void) => void,
): void => {
  const { generationStatus, matchingStatus } = websocketDto;
  updateCachedData((agentInfo: RecruiterAgentInfoResponse) => {
    logger.log('Agent info updated', agentInfo);
    agentInfo.matchingStatus = matchingStatus;
    agentInfo.generationStatus = generationStatus;
  });
};
