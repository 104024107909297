import React from 'react';
import { ConversationMessageType } from '../../../../../enums/conversation-message-type.enum';
import { ChatAiMessage } from './chat-ai-message/chat-ai-message.component';
import { ChatUserMessage } from './chat-user-message/chat-user-message.component';
import { ChatMessageProps } from './chat-message';

export const ChatMessage = ({
  conversationMessage,
  onTypingComplete,
  onActionClick,
  isStreamComplete,
}: ChatMessageProps): JSX.Element => {
  const isAiMessage = conversationMessage.messageType === ConversationMessageType.AgentMessage;

  const renderedMessage = isAiMessage ? (
    <ChatAiMessage
      conversationMessage={ conversationMessage }
      onActionClick={ onActionClick }
      onTypingComplete={ onTypingComplete }
      isStreamComplete={ isStreamComplete }
    />
  ) :
    <ChatUserMessage conversationMessage={ conversationMessage } />;

  return (
    <>
      { renderedMessage }
    </>
  );
};
