import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAnalyticsContext } from '../../../../../contexts/analytics/useAnalyticsContext';
import { AppRouting } from '../../../../../services/appRoutingResolver';
import MatchLimitDailyBase from '../match-daily-limit-base/match-daily-limit.component';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../../consts/analytics';
import STRING_KEYS from '../../../../../language/keys';
import { PAGE_NAMES } from '../../../../../consts/pages';
import { ASSETS } from '../../../../../assets';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import UpgradeToScaleDialog
  from '../../../../../components/dialogs/info-dialogs/upgrade-to-scale-dialog/upgrade-to-scale-dialog.component';
import {
  FilledButton,
  GenAiIcon,
  GhostButton,
  Wrapper,
} from './match-daily-limit-pro.styled';

const MatchLimitDailyPro = ():JSX.Element | null => {
  const { positionId } = useParams<MatchUrlParams>();
  const browserHistory = useHistory();
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const [isMaximumPositionsLimitReachedDialogOpen, setIsMaximumPositionsLimitReachedDialogOpen] = useState(false);

  const onViewUpgradeOptionsButtonClick = () => {
    setIsMaximumPositionsLimitReachedDialogOpen(true);
  };

  const onReachoutToMatchesButtonClick = () => {
    const eventData = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_REACH_YOUR_MATCHES,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PRODUCT_AREA.MATCH_PAGE,
      productArea: PAGE_NAMES.MATCH_PAGE,
      pagePositionId: positionId,
    };

    analyticsTrackEvent(eventData);
    browserHistory.push(
      AppRouting.buildPositionConversationsPageUrl(positionId),
    );
  };

  const ViewUpgradeOptionsButton: JSX.Element = (
    <FilledButton
      size={ PerfectButtonSize.Large }
      onClick={ onViewUpgradeOptionsButtonClick }
      hoverVariant={ PerfectButtonHoverVariants.Grow }
    >
      { translate(STRING_KEYS.DAILY_LIMIT.PRO_USER.PRIMARY_BUTTON) }
    </FilledButton>
  );

  const ReachoutMatchesButton: JSX.Element = (
    <GhostButton
      size={ PerfectButtonSize.Large }
      onClick={ onReachoutToMatchesButtonClick }
      hoverVariant={ PerfectButtonHoverVariants.Grow }
    >
      <Wrapper>
        <GenAiIcon />
        <>{ translate(STRING_KEYS.DAILY_LIMIT.PRO_USER.SECONDARY_BUTTON) }</>
      </Wrapper>
    </GhostButton>
  );

  return (
    <>
      <MatchLimitDailyBase
        positionId={ positionId }
        Icon={ <ASSETS.SLOTH_YOGA_2 /> }
        PrimaryButton={ ViewUpgradeOptionsButton }
        SecondaryButton={ ReachoutMatchesButton }
      />
      <UpgradeToScaleDialog
        isDialogOpen={ isMaximumPositionsLimitReachedDialogOpen }
        closeButtonHandler={ () => setIsMaximumPositionsLimitReachedDialogOpen(false) }
      />
    </>
  );
};

export default MatchLimitDailyPro;
