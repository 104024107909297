import styled from 'styled-components';
import { Title1Font } from '../../../../themes/fonts';
import { THEME } from '../../../../themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 50px;
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h1`
  ${Title1Font};
  font-weight: 300;
  color: ${THEME.COLORS.CONTENT_A};
`;

export const Subtitle = styled.h2`
  ${Title1Font};
  font-weight: 700;
  color: ${THEME.COLORS.CONTENT_A};
`;
