import { recruiterAgentQuery } from '../recruiter-agent.toolkit-api';
import { CreateMessageResponse } from '../dto/response/create-message.response';
import { CreateMessageQueryArguments } from '../dto/query-arguments/create-message.query-arguments';

type UseCreateConversationMessageMutationType = [
  ({ positionId, message }: CreateMessageQueryArguments) => void,
  AppSelectorType<CreateMessageResponse | undefined, unknown>
];

export const useCreateConversationMessageMutation = (): UseCreateConversationMessageMutationType => {
  const [
    createConversationMessage,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = recruiterAgentQuery.useCreateMessageMutation();

  const meta = {
    isLoading, isError, error, isSuccess, data, resetState: reset,
  };

  return [
    createConversationMessage,
    { ...meta },
  ];
};
