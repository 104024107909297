import log from 'loglevel';
import { useQuery, UseQueryResult } from 'react-query';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { UseMergeLinkResponse } from '@mergeapi/react-merge-link/src/types';
import { useEffect } from 'react';
import { getMatch } from '../api/match';
import { getPositionAnalytics } from '../api/positions';
import { PositionAnalytics } from '../models/dto/response/position/position-analytics-response.dto';
import { useGetMyAccountSelector } from '../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { MatchResponse } from '../store/rtk-query/apis/match/dto/response/match.response';
import { atsIntegrationQuery } from '../store/rtk-query/apis/ats-integration/ats-integration.toolkit-api';

const logger = log.getLogger('HOOKS_QUERIES');

/**
 * Define Query for loading specific match data
 * @param positionId
 * @param matchId
 * @returns Api MatchResponse
 * @throws Error
 */
// TODO DIN [refactor]: delete after stop using it
export const useMatchQuery = (
  positionId: string | null,
  matchId: string | null,
): UseQueryResult<MatchResponse> => {
  return useQuery(['match', positionId, matchId], () => {
    if (!positionId || !matchId) {
      logger.debug(
        'Cannot load match data because one or more of the parameters are undefined',
        {
          positionId,
          matchId,
        },
      );

      throw new Error('Missing positionId / matchId in query');
    }

    logger.debug('loading match data', {
      positionId,
      matchId,
    });

    return getMatch(positionId, matchId);
  }, {
    enabled: !!positionId && !!matchId,
    retry: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });
};

export const useMergeLinkUi =  (twoWaySyncApproved: boolean, canSendRequest: boolean, onExit?: () => void): UseMergeLinkResponse => {
  const { data, isError: isGetMergeTokenError } = atsIntegrationQuery.useGetMergeFrontendLinkTokenQuery(undefined, { skip: !canSendRequest });
  const { data: { id: accountId } } = useGetMyAccountSelector();
  const [createAtsIntegration, { isError: isCreateAtsIntegrationError }] = atsIntegrationQuery.useCreateAtsIntegrationMutation();

  useEffect(() => {
    if (isGetMergeTokenError) {
      logger.error('error in get merge frontend link token', { accountId });
    }
  }, [accountId, isGetMergeTokenError]);

  useEffect(() => {
    if (isCreateAtsIntegrationError) {
      logger.error('error in create ats integration', { accountId });
    }
  }, [accountId, isCreateAtsIntegrationError]);

  return useMergeLink({
    linkToken: data?.linkToken,
    onSuccess: async (publicToken: string) => {
      await createAtsIntegration({ accountToken: publicToken, accountId, twoWaySyncApproved });
    },
    onExit,
  });
};

export const useGetPositionAnalytics = (positionId: string): UseQueryResult<PositionAnalytics> => {
  return useQuery(['positionAnalytics', positionId], async () => {
    const positionAnalyticsResponse = await getPositionAnalytics(positionId);

    return positionAnalyticsResponse.data;
  }, {
    onSuccess: () => {
      logger.debug('successfully loaded position analytics', { positionId });
    },
    onError: (error) => {
      logger.error('Failed to load position analytics', error, { positionId });
    },
  });
};
