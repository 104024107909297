export enum RecruiterAgentGenerationStatus {
  GENERATING = 'Generating',
  ERRORED = 'Errored',
  IDLE = 'Idle',
}

export enum RecruiterAgentMatchingStatus {
  MATCHING = 'Matching',
  ERRORED = 'Errored',
  DAILY_LIMIT = 'DailyLimit',
  IDLE = 'Idle',
}
